import styles from './AnimatedPath.css'

export function AnimatedPathAverage({ isActive }) {
  const path = 'M 0 2 L 0.652 2.023 L 1.304 2.062 L 1.956 2.117 L 2.608 2.188 L 3.260 2.273 L 3.913 2.373 L 4.565 2.486 L 5.217 2.612 L 5.869 2.749 L 6.521 2.899 L 7.174 3.059 L 7.826 3.230 L 8.478 3.410 L 9.130 3.598 L 9.782 3.795 L 10.4 4.000'
  const furthestPoint = extractFurthestPointFromPath(path)

  return (
    <path
      d={path}
      strokeWidth={0.15}
      style={{ strokeDasharray: Math.ceil(furthestPoint * 2) / 2, strokeDashoffset: Math.ceil(furthestPoint * 2) / 2 - 2 }}
      className={cx(styles.componentAverage, isActive && styles.isActive)}
    />
  )
}

export function AnimatedPathFiveYearMark({ isActive, height }) {
  return (
    <path
      d={`M 10.3 0 v ${height}`}
      strokeWidth={0.075}
      strokeDashoffset={0}
      strokeDasharray={0.25}
      className={cx(cx(styles.componentFiveYearMark, isActive && styles.isActive))}
    />
  )
}

export function AnimatedPathMarginOfError({ isActive, isDelayed }) {
  return (
    <g className={cx(styles.componentMarginOfError, isActive && styles.isActive, isDelayed && styles.isDelayed)}>
      <path
        d='M 0 2 L 0.652 2.006 L 1.304 2.015 L 1.956 2.029 L 2.608 2.047 L 3.260 2.068 L 3.913 2.093 L 4.565 2.121 L 5.217 2.153 L 5.869 2.187 L 6.521 2.225 L 7.174 2.265 L 7.826 2.307 L 8.478 2.352 L 9.130 2.400 L 9.782 2.449 L 10.3 2.5'
        strokeWidth={0.05}
        strokeDashoffset={0}
        strokeDasharray={0.5}
        className={styles.marginOfErrorPath}
      />
      <path
        d='M 0 2 L 0.652 2.039 L 1.304 2.108 L 1.956 2.205 L 2.608 2.329 L 3.260 2.478 L 3.913 2.652 L 4.565 2.850 L 5.217 3.070 L 5.869 3.312 L 6.521 3.573 L 7.174 3.854 L 7.826 4.152 L 8.478 4.467 L 9.130 4.797 L 9.782 5.142 L 10.3 5.5'
        strokeWidth={0.05}
        strokeDashoffset={0}
        strokeDasharray={0.5}
        className={styles.marginOfErrorPath}
      />
      <path
        d='M 10.3 2.5 L 9.782 2.449 L 9.130 2.400 L 8.478 2.352 L 7.826 2.307 L 7.174 2.265 L 6.521 2.225 L 5.869 2.187 L 5.217 2.153 L 4.565 2.121 L 3.913 2.093 L 3.260 2.068 L 2.608 2.047 L 1.956 2.029 L 1.304 2.015 L 0.652 2.006 L 0 2 L 0.652 2.039 L 1.304 2.108 L 1.956 2.205 L 2.608 2.329 L 3.260 2.478 L 3.913 2.652 L 4.565 2.850 L 5.217 3.070 L 5.869 3.312 L 6.521 3.573 L 7.174 3.854 L 7.826 4.152 L 8.478 4.467 L 9.130 4.797 L 9.782 5.142 L 10.3 5.5 z'
        strokeWidth={0.05}
        strokeDashoffset={0}
        strokeDasharray={0.5}
        className={styles.marginOfErrorFill}
      />
    </g>
  )
}

export function AnimatedPathTreatment({ isActive, isComplete }) {
  const path = 'M 0 2 L 0.652 2.014 L 1.304 2.039 L 1.956 2.073 L 2.608 2.117 L 3.26 2.171'
  const furthestPoint = extractFurthestPointFromPath(path)

  return (
    <>
      <g>
        <path
          d={path}
          strokeWidth={0.15}
          style={{ strokeDasharray: Math.ceil(furthestPoint * 2) / 2, strokeDashoffset: Math.ceil(furthestPoint * 2) / 2 }}
          className={cx(styles.treatmentPath, isActive && styles.isActive)}
        />
        <path
          mask='url(#gradientMask)'
          d='M 3.260 2.171 L 3.913 2.233 L 4.565 2.304 L 5.217 2.382 L 5.869 2.468 L 6.521 2.562 L 7.174 2.662 L 7.826 2.769 L 8.478 2.881 L 9.130 2.999 L 9.782 3.122 L 10.434 3.25'
          strokeWidth={0.15}
          strokeDashoffset={0.25}
          strokeDasharray={0.25}
          className={styles.treatmentPath}
        />
      </g>
      <GradientWipe isActive={isComplete} />
    </>
  )
}

function GradientWipe({ isActive }) {
  return (
    <mask id='gradientMask'>
      <rect x={0} y={0} width={15} height={10} fill='url(#gradient)' />
      <defs>
        <linearGradient id='gradient'>
          <stop className={cx(styles.gradientStop, isActive && styles.isActive)} offset='0%' />
          <stop className={cx(styles.gradientStop, isActive && styles.isActive)} offset='75%' />
        </linearGradient>
      </defs>
    </mask>
  )
}

function extractFurthestPointFromPath(path) {
  return parseFloat(
    path
      .split(' ')
      .filter(segment => !isNaN(segment) && segment.trim() !== '')
      .slice(-2, -1)[0]
  )
}
