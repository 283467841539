import styles from './Form.css'

export function Form({ children, onSubmit }) {
  return <form onSubmit={handleSubmit} className={styles.component}>{children}</form>

  function handleSubmit(e) {
    e.preventDefault()
    onSubmit()
  }
}

export function FormGroup({ children }) {
  return <div className={styles.componentGroup}>{children}</div>
}
