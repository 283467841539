import Menu from './Menu'
import styles from './AppLayout.css'
import { Footer } from './Footer'

export default function AppLayout({ children }) {
  return (
    <main className={styles.component}>
      <Menu />
      <div role="main" className={styles.childrenContainer}>
        {children}
      </div>
      <Footer />
    </main>
  )
}
