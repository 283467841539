import { useI18n } from '/machinery/useI18n'

import { models } from '/pages/MedicatieTool'
import { FieldGroup, FieldRadioGroup, FieldRadio, FieldNumber, FieldSelect } from '/features/pageOnly/predictionTool/Field'

import styles from './MedicatieCalculatorForm.css'

export function MedicatieCalculatorForm({ onPatientInformationChange, patientInformation }) {
  const i18n = useI18n()
  const form = i18n('form')

  const { sex, age, mmseAtBaseline, csfPtau, csfAb42, medicineEffect, mmseOfInterest, model, apoeDose } = patientInformation
  const fieldInfo = models[model || 'default'].fieldInfo
  const modelOptions = Object.entries(form.model.options).map(([value, label]) => ({ value, label }))

  return (
    <section>
      <div className={styles.component}>
        <FieldGroup>
          <FieldRadioGroup label={form.sex.legend}>
            <FieldRadio
              name='sex'
              id='male'
              label={form.sex.male}
              onChange={_ => handleChange({ sex: 'male' })}
              checked={sex === 'male'}
            />
            <FieldRadio
              name='sex'
              id='female'
              label={form.sex.female}
              onChange={_ => handleChange({ sex: 'female' })}
              checked={sex === 'female'}
            />
          </FieldRadioGroup>
          <FieldNumber
            label={form.age.label}
            id='age'
            name='age'
            value={age}
            onChange={x => handleChange({ age: x })}
            min={fieldInfo.age[0]}
            max={fieldInfo.age[1]}
          />
        </FieldGroup>

        <FieldGroup>
          <FieldSelect
            value={model}
            label={form.model.label}
            placeholder={form.model.placeholder}
            id='model'
            onChange={x => handleChange({ model: x.target.value })}
            options={modelOptions}
          />
          <FieldNumber
            label={form.mmseAtBaseline.label}
            id='mmseAtBaseline'
            name='mmseAtBaseline'
            value={mmseAtBaseline}
            onChange={x => handleChange({ mmseAtBaseline: x })}
            min={fieldInfo.mmseAtBaseline[0]}
            max={fieldInfo.mmseAtBaseline[1]}
          />
        </FieldGroup>

        <FieldGroup title='Biomarkers'>
          {model === 'ad' && (
            <FieldNumber
              label={form.apoeDose.label}
              id='apoeDose'
              name='apoeDose'
              value={apoeDose}
              onChange={x => handleChange({ apoeDose: x })}
              min={fieldInfo.apoeDose[0]}
              max={fieldInfo.apoeDose[1]}
            />
          )}
          <FieldNumber
            label={form.csfPtau.label}
            id='csfPtau'
            name='csfPtau'
            value={csfPtau}
            onChange={x => handleChange({ csfPtau: x })}
            min={fieldInfo.csfPtau[0]}
            max={fieldInfo.csfPtau[1]}
          />
          <FieldNumber
            label={form.csfAb42.label}
            id='csfAb42'
            name='csfAb42'
            value={csfAb42}
            onChange={x => handleChange({ csfAb42: x })}
            min={fieldInfo.csfAb42[0]}
            max={fieldInfo.csfAb42[1]}
          />
        </FieldGroup>

        <FieldGroup title={form.prediction} tooltip={form.predictionTooltip}>
          <FieldNumber
            label={form.medicineEffect.label}
            tooltip={form.medicineEffect.tooltip}
            id='medicineEffect'
            name='medicineEffect'
            value={medicineEffect}
            onChange={x => handleChange({ medicineEffect: x })}
            min={fieldInfo.medicineEffect[0]}
            max={fieldInfo.medicineEffect[1]}
          />
          <FieldNumber
            label={form.mmseOfInterest.label}
            id='mmseOfInterest'
            name='mmseOfInterest'
            value={mmseOfInterest}
            onChange={x => handleChange({ mmseOfInterest: x })}
            min={fieldInfo.mmseOfInterest[0]}
            max={fieldInfo.mmseOfInterest[1]}
          />
        </FieldGroup>
      </div>
    </section>
  )

  function handleChange(x) {
    onPatientInformationChange(y => ({ ...y, ...x }))
  }
}
