import { useI18n } from '/machinery/useI18n'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './VisualizationItem.css'

import humanIcon from '/images/icons/human.raw.svg'

function VisualizationItem({ amountThatDevelopsAlzheimer, title, children }) {
  return (
    <div className={styles.component}>
      <h4 className={styles.title}>{title}</h4>
      {children}
      <Legend {...{ amountThatDevelopsAlzheimer, title }} />
    </div>
  )
}

export function HumanIcons({ amountThatDevelopsAlzheimer, title, layoutClassName = undefined }) {
  return (
    <VisualizationItem {...{ amountThatDevelopsAlzheimer, title }}>
      <div className={styles.icons}>
        {Array(Math.round(amountThatDevelopsAlzheimer)).fill(null).map(( _, i ) => (
          <div key={`icondark${i}`} className={styles.humanIconDarkContainer}>
            <Icon icon={humanIcon} layoutClassName={styles.iconLayout} />
          </div>
        ))}
        {Array(amountThatDevelopsAlzheimer < 100 ? 100 - Math.round(amountThatDevelopsAlzheimer) : 0).fill(null).map(( _, i ) => (
          <div key={`iconlight${i}`} className={styles.humanIconLightContainer}>
            <Icon icon={humanIcon} layoutClassName={styles.iconLayout} />
          </div>
        ))}
      </div>
    </VisualizationItem>
  )
}

export function BarChart({ amountThatDevelopsAlzheimer, title }) {
  return (
    <VisualizationItem {...{ amountThatDevelopsAlzheimer, title }}>
      <div className={styles.bar}>
        <div className={styles.portion} style={{ height: `${(amountThatDevelopsAlzheimer)}%` }}>
          <div className={cx(styles.percentage, styles.percentageFilled)}>{Math.round(amountThatDevelopsAlzheimer)}%</div>
        </div>

        <div className={styles.portion} style={{ height: `${(100 - Math.round(amountThatDevelopsAlzheimer))}%` }}>
          <div className={cx(styles.percentage, styles.percentageEmpty)}>{100 - Math.round(amountThatDevelopsAlzheimer)}%</div>
        </div>
      </div>
    </VisualizationItem>
  )
}

function Legend({ amountThatDevelopsAlzheimer, title }) {
  return (
    <ul className={styles.legend}>
      <LegendItem number={amountThatDevelopsAlzheimer} years={title} developsAlzheimer />
      <LegendItem number={100 - amountThatDevelopsAlzheimer} years={title}  />
    </ul>
  )

  function LegendItem({ number, years, developsAlzheimer = undefined }) {
    const i18n = useI18n()

    return (
      <li className={styles.legendRow}>
        <div className={styles.legendColorBox} />
        <div className={styles.legendText}>{i18n('resultaat').situationVisualization.visualization.legend(Math.round(number), years, developsAlzheimer)}</div>
      </li>
    )
  }
}
