import { useI18n } from '/machinery/useI18n'

import { InfographicContainer } from '/features/pageOnly/predictionTool/InfographicContainer'
import { FieldCheckbox, FieldRadio, FieldNumber, safeGetRange, FieldGroup, FieldInlineGroup } from '/features/pageOnly/predictionTool/Field'
import { ContentToggler } from '/features/buildingBlocks/ContentToggler'
import { Tooltip } from '/features/buildingBlocks/Tooltip'

import styles from './AanvullendeGegevens.css'

const currentPage = 'aanvullendegegevens'

export function AanvullendeGegevens({
  value, onChange,
  fieldInfo,
  basisInRange,
  basisResults,
  aanvullendeResults,
}) {
  const i18n = useI18n()
  const { form, withBiomarkers, withoutBiomarkers } = i18n(currentPage)
  const isMri = value.testSoort.includes('mri')
  const isEiwit = value.testSoort.includes('eiwit')
  const isPet = value.testSoort.includes('pet')

  return (
    <section className={styles.component}>
      <div className={styles.patientInput}>
        <FieldGroup legend={form.legend}>
          <FieldCheckbox
            name='testSoort'
            id='mri'
            label={form.mriType.label}
            onChange={onChangeTestSoort('mri')}
            checked={value.testSoort.includes('mri')}
          />
          <FieldCheckbox
            name='testSoort'
            id='eiwit'
            label={form.eiwitMethode.label}
            disabled={value.testSoort.includes('pet')}
            onChange={onChangeTestSoort('eiwit')}
            checked={value.testSoort.includes('eiwit')}
          />
          <FieldCheckbox
            name='testSoort'
            id='pet'
            label={form.petMethode.label}
            disabled={value.testSoort.includes('eiwit')}
            onChange={onChangeTestSoort('pet', { onSelect: all(
              ensure('mriType', 'volumetrisch'),
              ensure('hcvMethod', 'freesurfer'),
            ) })}
            checked={value.testSoort.includes('pet')}
          />
        </FieldGroup>
        {isPet &&
          <div className={styles.warning}>
            {form.petMethode.warning}
          </div>
        }
        {basisInRange &&
          <>
            {isMri &&
              <FieldGroup legend={form.mriType.legend}>
                <SelectMriType disableVisueel={isPet} {...{ value, onChange, form }} />
              </FieldGroup>
            }
            {isMri && value.mriType === 'volumetrisch' && (
              <FieldGroup legend={form.volumetrischMethode.legend}>
                <SelectMriVolumetrischMethode disableFslFirst={isPet} {...{ value, onChange, form }} />
              </FieldGroup>
            )}
            {isMri && value.mriType === 'visueel' && (
              <FieldGroup>
                <FieldInlineGroup>
                  <MtaInput {...{ value, onChange, fieldInfo, form }} />
                </FieldInlineGroup>
              </FieldGroup>
            )}
            {isMri && value.mriType === 'volumetrisch' && (
              <FieldGroup>
                <HcvInput {...{ value, onChange, fieldInfo, form }} />
              </FieldGroup>
            )}
            {isEiwit && (
              <>
                <FieldGroup legend={form.eiwitMethode.legend}>
                  <SelectEiwitMethode {...{ value, onChange, form }} />
                </FieldGroup>
                <FieldGroup>
                  <FieldInlineGroup>
                    <AbetaInput {...{ value, onChange, fieldInfo, form }} />
                    <PTauInput {...{ value, onChange, fieldInfo, form }} />
                  </FieldInlineGroup>
                </FieldGroup>
              </>
            )}
            {isPet && (
              <FieldGroup legend={form.petMethode.legend}>
                <SelectPET {...{ value, onChange, form }} />
              </FieldGroup>
            )}
          </>
        }
      </div>

      <div className={styles.labelTooltipContainer}>
        {withBiomarkers.text}
        <Tooltip tip={withBiomarkers.tooltip} buttonLink={`/${i18n.language}${withBiomarkers.tooltip.button.link}`} />
      </div>

      <InfographicContainer results={aanvullendeResults} />

      <ContentToggler label={
        <div className={styles.labelTooltipContainer}>
          {withoutBiomarkers.text}
          <Tooltip tip={withoutBiomarkers.tooltip} buttonLink={`/${i18n.language}${withBiomarkers.tooltip.button.link}`} />
        </div>
      }>

        <InfographicContainer results={basisResults} />
      </ContentToggler>

    </section>
  )

  function onChangeTestSoort(value, { onSelect = x => x } = {}) {
    return checked => onChange(
      ({ testSoort, ...rest }) => ({
        ...(checked ? onSelect(rest) : rest),
        testSoort: checked ? testSoort.concat(value) : testSoort.filter(x => x !== value)
      })
    )
  }
}

function onChangeRadio(onChange, field, value) {
  return checked => onChange(
    ({ [field]: original, ...rest }) => ({ ...rest, [field]: checked ? value : original })
  )
}

function all(...fs) {
  return x => fs.reduce((x, f) => f(x), x)
}

function ensure(field, value) {
  return ({ [field]: current, ...rest }) => ({ [field]: value, ...rest })
}

function AbetaInput({ value, onChange, fieldInfo, form }) {
  const [min, max] = safeGetRange(fieldInfo.csfMethods, value.csfMethod, 'abeta')

  return (
    <FieldNumber
      name='abeta'
      id='abeta'
      label={form.abeta.label}
      value={value.abeta}
      onChange={x => onChange({ ...value, abeta: x })}
      {...{ min, max }}
    />
  )
}

function PTauInput({ value, onChange, fieldInfo, form }) {
  const [min, max] = safeGetRange(fieldInfo.csfMethods, value.csfMethod, 'pTau')

  return (
    <FieldNumber
      name='pTau'
      id='pTau'
      label={form.pTau.label}
      value={value.pTau}
      onChange={x => onChange({ ...value, pTau: x })}
      {...{ min, max }}
    />
  )
}

function HcvInput({ value, onChange, fieldInfo, form }) {
  const [min, max] = safeGetRange(fieldInfo.hcvMethods, value.hcvMethod, 'hcv')

  return (
    <FieldNumber
      name='hcv'
      id='hcv'
      label={form.hcv.label}
      labelAddition={form.hcv.labelAddition}
      value={value.hcv}
      onChange={x => onChange({ ...value, hcv: x })}
      step={0.1}
      disabled={!min && !max}
      {...{ min, max }}
    />
  )
}

function SelectMriType({ value, onChange, form, disableVisueel }) {
  return (
    <>
      <FieldRadio
        name='mriType'
        id='visueel'
        label={form.mriType.visueel}
        onChange={onChangeRadio(onChange, 'mriType', 'visueel')}
        checked={value.mriType === 'visueel'}
        disabled={disableVisueel}
      />
      <FieldRadio
        name='mriType'
        id='volumetrisch'
        label={form.mriType.volumetrisch}
        onChange={onChangeRadio(onChange, 'mriType', 'volumetrisch')}
        checked={value.mriType === 'volumetrisch'}
      />
    </>
  )
}

function MtaInput({ value, onChange, fieldInfo, form }) {
  const [min, max] = fieldInfo.mta

  return (
    <>
      <FieldNumber
        name='mtaLeft'
        id='mtaLeft'
        label={form.mta.labelLeft}
        value={value.mtaLeft}
        onChange={x => onChange({ ...value, mtaLeft: x })}
        {...{ min, max }}
      />
      <FieldNumber
        name='mtaRight'
        id='mtaRight'
        label={form.mta.labelRight}
        value={value.mtaRight}
        onChange={x => onChange({ ...value, mtaRight: x })}
        {...{ min, max }}
      />
    </>
  )
}

function SelectMriVolumetrischMethode({ value, onChange, form, disableFslFirst }) {
  return (
    <>
      <FieldRadio
        name='hcvMethod'
        label={form.volumetrischMethode.fslFirst}
        id='fslFirst'
        onChange={onChangeRadio(onChange, 'hcvMethod', 'fslFirst')}
        checked={value.hcvMethod === 'fslFirst'}
        disabled={disableFslFirst}
      />
      <FieldRadio
        name='hcvMethod'
        label={form.volumetrischMethode.freesurfer}
        id='freesurfer'
        onChange={onChangeRadio(onChange, 'hcvMethod', 'freesurfer')}
        checked={value.hcvMethod === 'freesurfer'}
      />
    </>
  )
}

function SelectEiwitMethode({ value, onChange, form }) {
  return (
    <>
      <FieldRadio
        name='csfMethod'
        id='elecsys'
        label={form.eiwitMethode.elecsys}
        onChange={onChangeRadio(onChange, 'csfMethod', 'elecsys')}
        checked={value.csfMethod === 'elecsys'}
      />
      <FieldRadio
        name='csfMethod'
        id='innotest'
        label={form.eiwitMethode.innotest}
        onChange={onChangeRadio(onChange, 'csfMethod', 'innotest')}
        checked={value.csfMethod === 'innotest'}
      />
      <FieldRadio
        name='csfMethod'
        id='luminex'
        label={form.eiwitMethode.luminex}
        onChange={onChangeRadio(onChange, 'csfMethod', 'luminex')}
        checked={value.csfMethod === 'luminex'}
      />
    </>
  )
}

function SelectPET({ value, onChange, form }) {
  return (
    <>
      <FieldRadio
        name='pet'
        id='pet_positive'
        label={form.petMethode.positive}
        onChange={onChangeRadio(onChange, 'pet', 'positive')}
        checked={value.pet === 'positive'}
      />
      <FieldRadio
        name='pet'
        id='pet_negative'
        label={form.petMethode.negative}
        onChange={onChangeRadio(onChange, 'pet', 'negative')}
        checked={value.pet === 'negative'}
      />
    </>
  )
}
