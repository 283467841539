import { useI18n } from '/machinery/useI18n'

import { getPointAdjustedForZoom } from '/features/pageOnly/medicationTool/machinery/getPointAdjustedForZoom'
import { GraphViewbox } from '/features/pageOnly/medicationTool/GraphViewbox'
import { AxisX } from '/features/pageOnly/medicationTool/Axis'
import { Legend, LegendItem } from '/features/pageOnly/medicationTool/Legend'

import styles from './StackedBarGraph.css'

export function StackedBarGraph({
  a,
  b,
  showData,
  showTreatmentEffects,
  xLabel,
  xStep = 1,
  xZoom = 0.25
}) {
  const i18n = useI18n()
  const graphsContent = i18n('graphsContent')

  const maxValue = b ?? 7
  const xMax = Math.round(maxValue * 1.15)
  const width = xMax / xZoom
  const height = 5

  const pathWithoutMeds = createSvgPathSFromPoints(0, a, { xZoom, height })
  const pathWithMeds = createSvgPathSFromPoints(a, (b - a), { xZoom, height })

  return (
    <div className={styles.component}>
      <div>
        <GraphViewbox viewBox={`-0.5 -1.5 ${width + 0.75} ${height + 5}`} {...{ width, height }}>
          <AxisX {...{ width, height, xLabel, xMax, xStep, xZoom }} />
          {showData && <PathWithoutMeds path={pathWithoutMeds} />}
          {showTreatmentEffects && <PathWithMeds path={pathWithMeds} />}
        </GraphViewbox>
      </div>

      <Legend>
        <LegendItem
          label={graphsContent.stackedBarGraph.legend.withoutTreatment}
          path='M 2 0 h 2 q 2 0 2 2 v 2 q 0 2 -2 2 h -2 q -2 0 -2 -2 v -2 q 0 -2 2 -2 z'
          pathStyle={styles.legendItemWithoutMeds}
        />
        {showTreatmentEffects && (
          <LegendItem
            label={graphsContent.stackedBarGraph.legend.withTreatment}
            path='M 2 0 h 2 q 2 0 2 2 v 2 q 0 2 -2 2 h -2 q -2 0 -2 -2 v -2 q 0 -2 2 -2 z'
            pathStyle={styles.legendItemWithMeds}
          />
        )}
      </Legend>
    </div>
  )
}

function PathWithoutMeds({ path }) {
  return <PathBase className={styles.componentPathWithoutMeds} {...{ path }} />
}

function PathWithMeds({ path }) {
  return <PathBase className={styles.componentPathWithMeds} {...{ path }} />
}

function PathBase({ path, className }) {
  return (
    <path
      d={path}
      strokeWidth={0.3}
      strokeDashoffset={0}
      strokeDasharray={0.5}
      {...{ className }}
    />
  )
}

function createSvgPathSFromPoints(startingPoint, length, { xZoom, height }) {
  return `M ${getPointAdjustedForZoom(xZoom, startingPoint)} 0 h ${getPointAdjustedForZoom(xZoom, length)} v ${height} h -${getPointAdjustedForZoom(xZoom, length)} z`
}
