import { Link } from '@reach/router'

import styles from './MenuItem.css'

export function MenuItem({ link, title }) {
  return <Link to={link} getProps={getCurrentMenuItemClassName}>{title}</Link>
}

export function MenuItemWithOnClick({ link, title, onClick = undefined }) {
  return <Link onClick={_ => onClick()} to={link} getProps={getCurrentMenuItemClassName}>{title}</Link>
}

export function MenuItemTool({ title, onClick, isActive, disabled, layoutClassName = undefined }) {
  return (
    <button type='button' className={cx(styles.componentTool, isActive && styles.isActive, layoutClassName)} {...{ onClick, disabled }}>
      {title}
    </button>
  )
}

function getCurrentMenuItemClassName({ isCurrent }) {
  return isCurrent ? { className: `${styles.component} ${styles.isCurrent}` } : { className: styles.component }
}
