import { Tooltip } from '/features/buildingBlocks/Tooltip'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Field.css'

import chevronIcon from '/images/icons/chevronRoundedUp.raw.svg'

export function FieldGroup({ children, title = undefined, legend = undefined, tooltip = undefined }) {
  return (
    <div role='group' className={styles.componentGroup}>
      <div className={styles.titleContainer}>
        {title && <h6 className={styles.groupTitle}>{title}</h6>}
        {tooltip && <Tooltip tip={tooltip} />}
      </div>

      {legend && <p className={styles.legend}>{legend}</p>}

      <div className={styles.groupContainer}>
        {children}
      </div>
    </div>
  )
}

export function FieldRadioGroup({ label = undefined, children }) {
  return (
    <div role='group' className={styles.componentRadioGroup}>
      {label && <h6 className={styles.label}>{label}</h6>}
      <div className={styles.radioContainer}>
        {children}
      </div>
    </div>
  )
}

export function FieldInlineGroup({ children }) {
  return <div className={styles.componentInlineGroup}>{children}</div>
}

export function FieldNumber({
  name,
  id,
  value,
  onChange,
  min,
  max,
  step = 1,
  label = undefined,
  labelAddition = undefined,
  tooltip = undefined,
  disabled = false
}) {
  const actualLabel = (label && min !== undefined && max !== undefined) ? <>{label}&nbsp;({min} - {max})</> : label

  return (
    <FieldBase label={actualLabel} {...{ id, labelAddition, tooltip }}>
      <input
        type='number'
        placeholder={`${min} - ${max}`}
        onChange={e => onChange(numberOrEmptyString(e.target.value))}
        className={`${styles.componentNumber}`}
        {...{ name, id, min, max, value, step, disabled }}
      />
    </FieldBase>
  )

  function numberOrEmptyString(x) {
    const parsed = parseFloat(x)
    return Number.isNaN(parsed) ? '' : parsed
  }
}

export function FieldCheckbox({ label, name, id, onChange, checked, disabled = false }) {
  return (
    <FieldInline {...{ id, label }}>
      <input
        type='checkbox'
        onChange={e => onChange(e.target.checked)}
        className={styles.componentCheckbox}
        {...{ name, id, checked, disabled }}
      />
    </FieldInline>
  )
}

export function FieldRadio({ label, name, id, onChange, checked, disabled = false }) {
  return (
    <FieldInline {...{ id, label }}>
      <input
        type='radio'
        onChange={e => onChange(e.target.checked)}
        className={styles.componentRadio}
        {...{ name, id, checked, disabled }}
      />
    </FieldInline>
  )
}

export function FieldSelect({ id, label, value, onChange, options, placeholder }) {
  const noSelection = !options.find(x => x.value === value)?.label

  return (
    <FieldBase {...{ id, label }}>
      <div className={styles.componentSelect}>
        <select defaultValue='default' className={styles.select} {...{ onChange }}>
          <option disabled value='default'>{placeholder}</option>
          {options.map(({ value: optionValue, label }, i) => (
            <option key={i} value={optionValue}>{label}</option>
          ))}
        </select>
        <p className={cx(styles.placeholderText, noSelection && styles.noSelection)}>{options.find(x => x.value === value)?.label || placeholder}</p>
        <span className={styles.iconContainer}>
          <Icon icon={chevronIcon} layoutClassName={styles.iconLayout} />
        </span>
      </div>
    </FieldBase>
  )
}

function FieldBase({ id, label, children, labelAddition = undefined, tooltip = undefined }) {
  return (
    <div className={styles.componentBase}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          <span>{label}</span>
          {tooltip && <Tooltip tip={tooltip} layoutClassName={styles.tooltipLayout} />}
          {labelAddition && <span className={styles.labelAddition}>{labelAddition}</span>}
          {(labelAddition && !tooltip) && <span className={styles.labelAddition}>{labelAddition}</span>}
        </label>
      )}
      <div className={styles.fieldContainer}>
        {children}
      </div>
    </div>
  )
}

function FieldInline({ id, label, children }) {
  return (
    <span className={styles.componentInline}>
      {children}
      <label className={styles.inlineLabel} htmlFor={id}>{label}</label>
    </span>
  )
}

export function safeGetRange(fieldInfo, container, field) {
  return (fieldInfo[container] || {})[field] || ['', '']
}
