import { useFloating, autoUpdate, FloatingPortal, FloatingArrow as FloatingArrowBase, arrow, useTransitionStyles } from '@floating-ui/react'

import styles from './Tooltip.css'

export function Tooltip({ isActive, x, y, label, placement, isDelayed = false }) {
  const arrowRef = React.useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    open: isActive,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      arrow({ element: arrowRef }),
    ],
  })

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: 500,
    initial: ({ side }) =>  ({
      opacity: 0,
      transform: {
        left: 'translateX(-20px)',
        right: 'translateX(20px)',
        top: 'translateY(-20px)',
        bottom: 'translateY(20px)'
      }[side]
    }),
    open: { opacity: 1, transform: 'translateX(0) translateY(0)' },
    close: ({ side }) => ({
      opacity: 0,
      transform: {
        left: 'translateX(-20px)',
        right: 'translateX(20px)',
        top: 'translateY(-20px)',
        bottom: 'translateY(20px)'
      }[side]
    })
  })

  return (
    <>
      <foreignObject width='auto' height='auto' {...{ x, y }}>
        <div ref={refs.setPositionReference} />
      </foreignObject>

      <FloatingPortal>
        {isMounted && (
          <div ref={refs.setFloating} style={floatingStyles}>
            <div style={transitionStyles} className={cx(styles.tooltip, (isActive && isDelayed) && styles.isDelayed)}>
              <p className={styles.label}>{label}</p>
              <FloatingArrowBase ref={arrowRef} width={20} height={10} tipRadius={2} className={styles.floatingArrow} {...{ context }} />
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  )
}
