import { useI18n } from '/machinery/useI18n'

import { ContentEditable } from '/features/buildingBlocks/ContentEditable'
import { NonInteractiveResults } from '/features/buildingBlocks/NonInteractiveResults'
import { SituationVisualization } from '/features/buildingBlocks/SituationVisualization'

import styles from './Result.css'

const currentPage = 'resultaat'

export function Result({ gender, results, aanvullendeGegevens }) {
  const i18n = useI18n()
  const { contentEditable } = i18n(currentPage)
  const amount1Year = results['1year']
  const amount3Years = results['3year']
  const amount5Years = results['5year']

  return (
    <article className={styles.component}>
      <NonInteractiveResults {...{ currentPage, aanvullendeGegevens }} />
      <ContentEditable
        {...{ currentPage }}
        text={contentEditable.text(gender, Math.round(amount1Year), Math.round(amount3Years), Math.round(amount5Years))}
        title={contentEditable.title}
      />

      <SituationVisualization {...{ currentPage, gender, amount1Year, amount3Years, amount5Years }} />
    </article>
  )
}
