import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { useI18n } from '/machinery/useI18n'

import { MenuItemWithOnClick, MenuItemTool } from '/features/pageOnly/MenuItem'

import styles from './MobileMenu.css'

export function MobileMenu({ menuItems, formMenuItems = undefined, layoutClassName = undefined }) {
  const [isActive, setIsActive] = React.useState(false)

  return (
    <nav className={cx(styles.component, layoutClassName)}>
      <MobileMenuButton onClick={handleClick} layoutClassName={styles.buttonLayout} {...{ isActive }} />
      <TransitionGroup component={React.Fragment}>
        {isActive &&
          <CSSTransition
            timeout={parseInt('100ms', 10)}
            classNames={{
              enter: styles.listEnter,
              enterDone: styles.listEnterActive,
              exit: styles.listExit,
              exitActive: styles.listExitActive
            }}
          >
            <List onClick={handleClick} layoutClassName={styles.listLayout} {...{ menuItems, formMenuItems }} />
          </CSSTransition>
        }
      </TransitionGroup>
    </nav>
  )

  function handleClick() {
    setIsActive(!isActive)
  }
}

function MobileMenuButton({ onClick, isActive, layoutClassName = undefined }) {
  const i18n = useI18n()

  return (
    <button className={cx(styles.componentButton, isActive && styles.isActive, layoutClassName)} {...{ onClick }}>
      <div className={styles.buttonIcon}>
        <div className={styles.buttonIconBar} />
      </div>
      <span className={styles.buttonLabel}>{i18n('pageNavigation').buttonText}</span>
    </button>
  )
}

function List({ onClick, menuItems, formMenuItems, layoutClassName }) {
  return (
    <div className={cx(styles.componentList, layoutClassName)}>
      {menuItems?.map(({ link, title }, i) => <MenuItemWithOnClick key={i} {...{ link, title, onClick }} />)}
      {formMenuItems?.map(({ title, onClick, isActive, disabled }, i) => (
        <MenuItemTool key={i} {...{ title, onClick, isActive, disabled }} />
      ))}
    </div>
  )
}
