import { OutcomeBlock, OutcomeBlockWithProtein } from '/features/buildingBlocks/OutcomeBlock'
import { useI18n } from '/machinery/useI18n'
import styles from './NonInteractiveResults.css'

const mriResultLookup = {
  noShrink: 0,
  unclear: 1,
  shrink: 2,
}

const punctureResultLookup = {
  noProtein: 0,
  unclear: 1,
  protein: 2,
}

const petResultLookup = {
  notPresent: 0,
  present: 1,
}

export function NonInteractiveResults({ currentPage, aanvullendeGegevens }) {
  const i18n = useI18n()
  const { ruggenprik, mri, pet, situation } = i18n(currentPage)

  const mriOutcome = getMriOutcome(aanvullendeGegevens)
  const punctureOutcome = getPunctureOutcome(aanvullendeGegevens)
  const petOutcome = getPetOutcome(aanvullendeGegevens)

  const hasHadAdditionalTests = Boolean(mriOutcome || punctureOutcome || petOutcome)

  return (
    <section className={styles.component}>
      <h2>{situation.title}</h2>
      <div className={styles.additionalTestText}>
        {situation.additionalTest[hasHadAdditionalTests ? 'yes' : 'no']}
      </div>
      {mriOutcome &&
        <ResultBlock title={mri.title} content={mri.content} image={mri.image}>
          <OutcomeBlock
            items={mri.items}
            selectedOutcome={mriResultLookup[mriOutcome]}
          />
        </ResultBlock>
      }
      {punctureOutcome &&
        <ResultBlock title={ruggenprik.title} content={ruggenprik.content}>
          <OutcomeBlockWithProtein
            items={ruggenprik.items}
            protein={[
              { name: 'Amyloid', value: aanvullendeGegevens.abeta},
              { name: 'Tau', value: aanvullendeGegevens.pTau }
            ]}
            selectedOutcome={punctureResultLookup[punctureOutcome]}
          />
        </ResultBlock>
      }
      {petOutcome &&
        <ResultBlock title={pet.title} content={pet.content}>
          <OutcomeBlock
            items={pet.items}
            selectedOutcome={petResultLookup[petOutcome]}
          />
        </ResultBlock>
      }
    </section>
  )
}

function ResultBlock({ title, content, children, image = undefined }) {
  return (
    <div className={styles.resultBlock}>
      <h2>{title}</h2>
      {content}
      {image && <img className={styles.image} src={image.src} alt={image.alt} />}
      {children}
    </div>
  )
}

function getPunctureOutcome({ testSoort, csfMethod, abeta, pTau }) {
  if (!testSoort.includes('eiwit') || !csfMethod || !abeta || !pTau) return null

  return (
    csfMethod === 'elecsys' ? (
      (abeta > 1371 && pTau < 14) ? 'noProtein' :
      (abeta < 921 && pTau > 31) ? 'protein' :
      'unclear'
    ) :
    csfMethod === 'innotest' ? (
      (abeta > 930 && pTau < 41) ? 'noProtein' :
      (abeta < 690 && pTau > 80) ? 'protein' :
      'unclear'
    ) :
    csfMethod === 'luminex' ? (
      (abeta > 210 && pTau < 20) ? 'noProtein' :
      (abeta < 164 && pTau > 47) ? 'protein' :
      'unclear'
    ) :
    null
  )
}

function getMriOutcome({ testSoort, mriType, hcvMethod, hcv, mtaLeft, mtaRight }) {
  if (!testSoort.includes('mri') || !mriType) return null

  return (
    mriType === 'visueel' ? (
      Number(mtaLeft) !== mtaLeft || Number(mtaRight) !== mtaRight ? null :
      average(mtaLeft, mtaRight) < 1 ? 'noShrink' :
      average(mtaLeft, mtaRight) > 1.5 ? 'shrink' :
      'unclear'
    ) :
    mriType === 'volumetrisch' ? (
      !hcvMethod || !hcv ? null :
      hcv > 7.8 ? 'noShrink' :
      hcv < 5.6 ? 'shrink' :
      'unclear'
    ) :
    null
  )

  function average(...inputs) {
    return inputs.reduce((result, x) => result + x, 0) / inputs.length
  }
}

function getPetOutcome({ testSoort, pet }) {
  if (!testSoort.includes('pet')) return null
  return pet === 'positive' ? 'present' : 'notPresent'
}
