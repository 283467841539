import { useQuery } from '@tanstack/react-query'

import { useI18n } from '/machinery/useI18n'
import { useDebouncedValue } from '/machinery/useDebouncedValue'

import { MedicatieCalculatorForm } from '/features/pageOnly/medicationTool/MedicatieCalculatorForm'
import { LineGraph } from '/features/pageOnly/medicationTool/LineGraph'
import { StackedBarGraph } from '/features/pageOnly/medicationTool/StackedBarGraph'
import { ButtonPrimary, ButtonTertiary } from '/features/buildingBlocks/Button'
import { Content } from '/features/buildingBlocks/Content'
import { AnimatedGraph } from '/features/pageOnly/medicationTool/animatedGraph/AnimatedGraph'
import { TabMenuSecondary } from '/features/buildingBlocks/TabMenu'
import { Heading } from '/features/buildingBlocks/Heading'
import { Paragraph } from '/features/buildingBlocks/Paragraph'
import { AlertBox } from '/features/buildingBlocks/AlertBox'

import styles from './MedicatieToolSections.css'

export function MedicatieToolSections({ section, onSectionChange, onPatientInformationChange, patientInformation, user, validationErrors, loggedIn }) {
  const formIsInvalid = validationErrors && validationErrors.length > 0

  const previousDataRef = React.useRef(null)
  const debouncedPatientInformation = useDebouncedValue(patientInformation, 300)
  const { csfAb42, csfPtau, age, sex, mmseAtBaseline, medicineEffect, mmseOfInterest, model, apoeDose } = debouncedPatientInformation

  const { data } = useQuery({
    queryKey: [age, mmseAtBaseline, csfPtau, csfAb42, medicineEffect, mmseOfInterest, sex, model, apoeDose, formIsInvalid],
    retry: false,
    retryOnMount: false,
    placeholderData: previousDataRef.current,
    queryFn: () => fetchGraphData({ age, sex, mmseAtBaseline, csfPtau, csfAb42, medicineEffect, mmseOfInterest, model, apoeDose, formIsInvalid })
  })

  const [showTreatmentEffects, setShowTreatmentEffects] = React.useState(false)

  if (section === 'basis') return (
    <SectionBasis
      onPatientInformationChange={handlePatientInformationChange}
      onShowTreatmentEffectsChange={handleShowTreatmentChange}
      {...{ data, medicineEffect, mmseOfInterest, formIsInvalid, patientInformation, onSectionChange, showTreatmentEffects, validationErrors }}
    />
  )

  if (section === 'resultaat') return <SectionResults {...{ data, model, mmseAtBaseline, mmseOfInterest, sex, medicineEffect, onSectionChange, showTreatmentEffects }} />

  function handleShowTreatmentChange() {
    setShowTreatmentEffects(!showTreatmentEffects)
  }

  function handlePatientInformationChange(x) {
    onPatientInformationChange(x)
  }

  async function fetchGraphData({ age, sex, mmseAtBaseline, csfPtau, csfAb42, medicineEffect, mmseOfInterest, model, apoeDose, formIsInvalid }) {
    if (formIsInvalid) return {}

    try {
      const token = await user.getIdToken()
      const response = await fetch('/api/v1/medicatietool', {
        method: 'POST',
        body: JSON.stringify({ inputParams: { age, mmseAtBaseline, csfPtau, csfAb42, medicineEffect, mmseOfInterest, sex, apoeDose }, model }),
        headers: {
          'X-Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })

      if (![200, 401].includes(response.status)) {
        const responseText = await response.text()
        throw new Error(`Response received with status: ${response.status}\nText: ${responseText}`)
      }

      const responseJson = await response.json()

      previousDataRef.current = responseJson
      return responseJson
    } catch (e) {
      reportError(e)
      return { requestErrors: ['Something went wrong, please try again or contact the system administrator'] }
    }
  }
}

function SectionBasis({ data, medicineEffect, mmseOfInterest, formIsInvalid, patientInformation, onSectionChange, onPatientInformationChange, showTreatmentEffects, onShowTreatmentEffectsChange, validationErrors }) {
  const i18n = useI18n()
  const pageNavigation = i18n('pageNavigation')
  const form = i18n('form')

  return (
    <div className={styles.componentSectionBasis}>
      <MedicatieCalculatorForm {...{ patientInformation, onPatientInformationChange }} />

      {(formIsInvalid) && (
        <AlertBox canBeDismissed>
          <Heading h={3} text={form.validationTitle} />
          <ul className={styles.unorderedList}>
            {validationErrors?.map(({ field, error } ) => <li key={field}>{form[field].label}</li>)}
            {data?.requestErrors && <li key='requestError'>{data?.requestErrors}</li>}
          </ul>
        </AlertBox>
      )}

      {data?.requestErrors && <b>{data?.requestErrors}</b>}

      <GraphsWithToggle
        onClick={onShowTreatmentEffectsChange}
        disabled={formIsInvalid}
        layoutClassName={styles.graphsAndToggleLayout}
        {...{ data, medicineEffect, mmseOfInterest, showTreatmentEffects }}
      />

      <div className={styles.actionsContainer}>
        <ButtonPrimary onClick={_ => onSectionChange('resultaat')} disabled={formIsInvalid}>
          {pageNavigation.buttonToExplanationPage}
        </ButtonPrimary>
      </div>
    </div>
  )
}

function GraphsWithToggle({ data, onClick, showTreatmentEffects, medicineEffect, mmseOfInterest, disabled, layoutClassName = undefined }) {
  const i18n = useI18n()
  const graphsContent = i18n('graphsContent')

  const { curves, untilValues } = data || {}

  return (
    <div className={cx(styles.componentGraphsWithToggle, layoutClassName)}>
      <Toggle
        isActive={showTreatmentEffects}
        name='showGraphs'
        label={graphsContent.effectsToggle}
        layoutClassName={styles.toggleLayout}
        {...{ onClick, disabled }}
      />

      {!disabled && (
        <div className={styles.graphsContentContainer}>
          <strong>{graphsContent.lineGraph.title}</strong>

          <LineGraph
            showData={Boolean(curves)}
            xLabel={graphsContent.lineGraph.xLabel}
            yLabel={graphsContent.lineGraph.yLabel}
            {...{ curves, showTreatmentEffects }}
          />

          <p>{graphsContent.lineGraph.description}</p>
          {(showTreatmentEffects && medicineEffect) && (
            <p>{graphsContent.lineGraph.treatmentPathDescription.replace('{PERCENTAGE}', medicineEffect)}</p>
          )}

          <strong>{graphsContent.stackedBarGraph.title}</strong>

          <StackedBarGraph
            showData={Boolean(untilValues)}
            a={untilValues?.withoutMedication}
            b={untilValues?.withMedication}
            xLabel={graphsContent.stackedBarGraph.xLabel}
            {...{ showTreatmentEffects }}
          />

          {mmseOfInterest && <p>{graphsContent.stackedBarGraph.description.replace('{MMSESCORE}', mmseOfInterest)}</p>}

          {(showTreatmentEffects && medicineEffect) && (
            <p>{graphsContent.stackedBarGraph.treatmentPathDescription.replace('{PERCENTAGE}', medicineEffect)}</p>
          )}
        </div>
      )}
    </div>
  )
}

function Toggle({ name, label, isActive, onClick, disabled, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentToggle, disabled && styles.disabled, layoutClassName)}>
      <input
        type='checkbox'
        id='toggle'
        value={name}
        className={styles.input}
        {...{ onClick, disabled }}
      />
      <label htmlFor='toggle'>{label}</label>
      <ToggleIcon layoutClassName={styles.toggleIconLayout} {...{ isActive }} />
    </div>
  )
}

function ToggleIcon({ isActive, layoutClassName = undefined }) {
  return (
    <span className={cx(styles.componentToggleIcon, isActive && styles.isActive, layoutClassName)} />
  )
}

function SectionResults({ data, model, mmseAtBaseline, mmseOfInterest, sex, medicineEffect, onSectionChange, showTreatmentEffects }) {
  const i18n = useI18n()
  const graphsContent = i18n('graphsContent')
  const results = i18n('results')

  var resultContent = []
  resultContent.push(results.situation.title)
  resultContent.push(model === 'ad' ? results.situation.content[0] : results.situation.content[1])
  resultContent.push(results.score.title)
  const scoreContentType = results.score.content[0]
  scoreContentType.text = scoreContentType.text.replace('{SCORE}', mmseAtBaseline)
  resultContent.push(scoreContentType)
  resultContent.push(results.progression.title)
  const progressionContentType = results.progression.content[0]
  progressionContentType.text = progressionContentType.text.replace('{GENDER}', sex === 'male' ? results.genders.male : results.genders.female)
  progressionContentType.text = progressionContentType.text.replace('{MODEL}', model === 'ad' ? results.models.dementia : results.models.mci)
  resultContent.push(progressionContentType)
  resultContent.push(results.progression.content[1])

  const disclaimerContent = []
  disclaimerContent.push(results.treatmentEffect.title)
  const treatmentEffectContentType = results.treatmentEffect.content[0]
  treatmentEffectContentType.text = treatmentEffectContentType.text.replace('{PERCENTAGE}', medicineEffect).replace('{PERCENTAGE}', medicineEffect)
  disclaimerContent.push(treatmentEffectContentType)
  disclaimerContent.push(results.treatmentEffect.content[1])

  const tabs = React.useMemo(() => results.situation.tabs, [results])
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]?.id)

  return (
    <div className={styles.componentSectionResults}>
      <Content content={resultContent} />

      {(showTreatmentEffects && Boolean(disclaimerContent.length)) && (
        <AlertBox>
          <Content content={disclaimerContent} />
        </AlertBox>
      )}

      <Heading h={2} text={results.animatedGraph.title} layoutClassName={styles.animatedGraphTitleLayout} />
      <AnimatedGraph maxAnimationState={showTreatmentEffects ? 7 : 4} />

      <Heading h={2} text={results.predictionGraphs.title} layoutClassName={styles.predictionGraphsTitleLayout} />
      <TabMenuSecondary onSelectedTabChange={handleTabChange} selectedTab={tabs.find(x => x.id === selectedTab)} {...{ tabs }} />

      {selectedTab === 'line-graph' && data?.curves && (
        <>
          <LineGraph
            showData
            curves={data?.curves}
            xLabel={graphsContent.lineGraph.xLabel}
            yLabel={graphsContent.lineGraph.yLabel}
            {...{ showTreatmentEffects }}
          />
          <Paragraph text={results.predictionGraphs.lineGraph.description} />
          {showTreatmentEffects && <Paragraph text={results.predictionGraphs.lineGraph.treatmentPathDescription} />}
        </>
      )}

      {selectedTab === 'time-to-time-graph' && data?.untilValues && (
        <>
          <StackedBarGraph
            showData
            a={data?.untilValues?.withoutMedication}
            b={data?.untilValues?.withMedication}
            xLabel={graphsContent.stackedBarGraph.xLabel}
            {...{ showTreatmentEffects }}
          />
          <Paragraph text={results.predictionGraphs.stackedBarGraph.description.replace('{SCORE}', mmseOfInterest)} />
          {showTreatmentEffects && <Paragraph text={results.predictionGraphs.stackedBarGraph.treatmentPathDescription.replace('{SCORE}', mmseOfInterest).replace('{PERCENTAGE}', medicineEffect)} />}
        </>
      )}

      <div className={styles.actionsContainer}>
        <ButtonTertiary onClick={_ => onSectionChange('basis')}>
          {results.buttons.reset}
        </ButtonTertiary>

        <ButtonPrimary onClick={_ => window.print()}>
          {results.buttons.print}
        </ButtonPrimary>
      </div>
    </div>
  )

  function handleTabChange(x) {
    setSelectedTab(x.id)
  }
}
