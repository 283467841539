import { useFirebase, useFirebaseUser } from '/machinery/firebase'
import { useIsMountedRef } from '/machinery/useIsMountedRef'
import { parseQueryString } from '/machinery/parseQueryString'
import { getAuth, setPersistence, browserSessionPersistence, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { reportError } from '/machinery/reportError'

const knownErrorCodes = ['auth/invalid-action-code', 'auth/expired-action-code']

export function useLoginWithLink({ location, loginData: { hasAccess = false, signInUrl } = {} }) {
  const app = useFirebase()
  const user = useFirebaseUser({ reportError })
  const isMountedRef = useIsMountedRef()
  const [error, setError] = React.useState(null)
  const onLoginFailed = React.useCallback(e => { if (isMountedRef.current) setError(e) }, [])

  const userChecked = user !== undefined
  const isLoggedIn = user && !user.isAnonymous
  const { search } = location

  React.useEffect(
    () => {
      if (!app || !userChecked || isLoggedIn) return

      const auth = getAuth(app)
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const { email } = parseQueryString(search)
        signInWithEmailLinkFirebase(auth, email, onLoginFailed)
      } else if (hasAccess) {
        // redirect to firebase
        window.location.href = signInUrl
      } else {
        setError('invalid')
      }
    },
    [app, userChecked, isLoggedIn, search, onLoginFailed, hasAccess, signInUrl]
  )

  return { error, isLoggedIn }
}

async function signInWithEmailLinkFirebase(auth, email, onLoginFailed) {
  try {
    await setPersistence(auth, browserSessionPersistence)
    await signInWithEmailLink(auth, email, window.location.href)
  } catch (e) {
    const result = e.message === 'invalid' || (e.code && knownErrorCodes.includes(e.code)) ? 'invalid' : 'unknown'
    onLoginFailed(result)
    if (result === 'unknown') reportError(e)
  }
}
