import { useI18n } from '/machinery/useI18n'

import { ButtonIcon } from '/features/buildingBlocks/ButtonIcon'

import pencilIcon from '/images/icons/pencil.raw.svg'

import styles from './ContentEditable.css'

export function ContentEditable({ currentPage, title, text }) {
  const i18n = useI18n()

  const [editable, setEditable] = React.useState(false)
  const [value, setValue] = React.useState('')

  React.useEffect(() => {
    setValue(text)
  }, [text])

  return (
    <div className={styles.component}>
      <h2>{title}</h2>

      {editable && (
        <textarea
          onChange={e => setValue(e.target.value)}
          className={cx(styles.content, styles.editable, editable)}
          {...{ value }}
        />
      )}

      <pre className={cx(styles.content, editable && styles.hideAndShowOnPrint)}>{value}</pre>

      <ButtonIcon
        label={i18n(currentPage).contentEditable.buttonText}
        icon={pencilIcon}
        onClick={handleClick}
        layoutClassName={styles.buttonLayout}
      />
    </div>
  )

  function handleClick() {
    setEditable(!editable)
  }
}
