export function validate(values, validations) {
  return Object.entries(validations).reduce(
    (result, [field, validators]) => {
      const value = values[field]
      const fieldResult = validators.reduce(
        (result, x) => result || x(field, value, values),
        false
      )
      return result.concat(fieldResult)
    },
    []
  ).filter(Boolean)
}

export function propOf(o) {
  return (field, value) => {
    const valid = o.hasOwnProperty(value)
    return !valid && { field, error: 'invalid' }
  }
}

/** @param {(number|function)[]} o*/
export function inRange([min, max]) {
  return (field, value, values) => {
    const minValue = typeof min === 'function' ? min(values) : min
    const maxValue = typeof max === 'function' ? max(values) : max
    const valid = value >= minValue && value <= maxValue
    return !valid && { field, error: 'out-of-range', outOfRange: [minValue, maxValue] }
  }
}

export function required() {
  return (field, value) => {
    const valid = value !== undefined
    return !valid && { field, error: 'missing' }
  }
}

export function isNumber() {
  return (field, value) => {
    const valid = Number(value) === value
    return !valid && { field, error: 'invalid' }
  }
}
