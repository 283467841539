import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Controls.css'

import chevronIcon from '/images/icons/chevronRounded.raw.svg'

export function Controls({ onAnimationStateChange, currentState, minAnimationState, maxAnimationState }) {
  return (
    <div className={styles.component}>
      <ButtonBackward layoutClassName={styles.buttonLayout} {...{ onAnimationStateChange, currentState, minAnimationState }} />
      <Indicators {...{ currentState, maxAnimationState }} />
      <ButtonForward layoutClassName={styles.buttonLayout} {...{ onAnimationStateChange, currentState, maxAnimationState }} />
    </div>
  )
}

function Indicators({ currentState, maxAnimationState }) {
  return (
    <li className={styles.componentIndicators}>
      {Array.from({ length: maxAnimationState }).map((_, i) => {
        const isActive = i < currentState
        return <ul key={i} className={cx(styles.indicator, isActive && styles.isActive)} />
      })}
    </li>
  )
}

function ButtonBackward({ onAnimationStateChange, currentState, minAnimationState, layoutClassName = undefined }) {
  return (
    <ButtonBase
      onClick={handleClick}
      disabled={currentState <= minAnimationState}
      dataX='click-animated-graph-backward'
      className={cx(styles.componentButtonBackward, layoutClassName)}
    />
  )

  function handleClick() {
    onAnimationStateChange(-1)
  }
}

function ButtonForward({ onAnimationStateChange, currentState, maxAnimationState, layoutClassName = undefined }) {
  return (
    <ButtonBase
      onClick={handleClick}
      disabled={currentState >= maxAnimationState}
      dataX='click-animated-graph-forward'
      className={cx(styles.componentButtonForward, layoutClassName)}
    />
  )

  function handleClick() {
    onAnimationStateChange(1)
  }
}

function ButtonBase({ onClick, dataX, disabled, className }) {
  return (
    <button type='button' data-x={dataX} className={cx(styles.componentButtonBase, className)} {...{ onClick, disabled }}>
      <div className={styles.iconContainer}>
        <Icon icon={chevronIcon} layoutClassName={styles.iconLayout} />
      </div>
    </button>
  )
}
