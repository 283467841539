import { useI18n } from '/machinery/useI18n'
import { Content } from '/features/buildingBlocks/Content'

export function ContentPage({ i18nPageTitle }) {
  const i18n = useI18n()
  const { content } = i18n(i18nPageTitle)

  return (
    <section>
      <Content {...{ content }} />
    </section>
  )
}
