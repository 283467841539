import { navigate } from '@reach/router'

import { reportError } from '/machinery/reportError'
import { useFirebaseUser } from '/machinery/firebase'
import { useI18n } from '/machinery/useI18n'

import { PageLayoutTools } from '/features/pageOnly/PageLayout'
import { Paragraph } from '/features/buildingBlocks/Paragraph'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { Calculator } from '/features/pageOnly/predictionTool/Calculator'
import { Content } from '/features/buildingBlocks/Content'

import styles from './PredictieTool.css'

export function PredictieTool({ fieldInfo, loginError }) {
  const user = useFirebaseUser({ reportError })

  const i18n = useI18n()
  const { pages, buttonToOtherTool, requestAccess, signup } = i18n('pageNavigation')
  const content = i18n('content')

  const [currentSection, setCurrentSection] = React.useState('welkom')
  const [basisResults, setBasisResults] = React.useState(null)

  const currentPageTitle = pages.find(x => x.section === currentSection).title
  const loggedIn = user && !user.isAnonymous

  const formMenuItems = pages.map(({ section, title }) => ({
    title,
    onClick() { setCurrentSection(section) },
    isActive: section === currentSection,
    disabled: !(section === 'welkom' || (section === 'basis' && loggedIn) || !!basisResults),
  }))

  React.useEffect(
    () => { window.scrollTo(0, 0) },
    [currentSection]
  )

  return (
    <PageLayoutTools tag={i18n('tag')} {...{ formMenuItems, currentPageTitle, buttonToOtherTool }}>
      {currentSection === 'welkom'
        ? (
          <div className={styles.contentContainer}>
            {loggedIn ? (
              <Content {...{ content }} />
            ) : (
              <div className={styles.contentContainer}>
                <Paragraph text={requestAccess} />
                <ButtonPrimary onClick={() => navigate('tools')} layoutClassName={styles.buttonLayout}>{signup}</ButtonPrimary>
              </div>
            )}
          </div>
        )
        : <Calculator section={currentSection} onSectionChange={handleSectionChange} onBasisResultsChange={handleBasisResultsChange} {...{ fieldInfo, basisResults }} />
      }
    </PageLayoutTools>
  )

  function handleSectionChange(x) {
    setCurrentSection(x)
  }

  function handleBasisResultsChange(x) {
    setBasisResults(x)
  }
}
