import { contentType } from '/features/buildingBlocks/Content'

export const requestAccessElements = {
  welkom: {
    content: [
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: '<b>Dementierisico bij MCI:</b><br>U berekent eenvoudig het dementierisico van uw MCI patiënt, met behulp van uitslagen van MRI en eiwitmarkers in het hersenvocht en amyloïd PET. De tool helpt u de uitslagen te interpreteren en de uitslagpagina ondersteunt uw uitleg in het gesprek met de patiënt.',
          en: '<b>Dementia risk for those with MCI:</b><br>You can easily calculate the dementia risk for your MCI patient using cerebrospinal fluid biomarkers, along with MRI and amyloid PET scan results. The tool helps you interpret the results and the results page  offers support for your discussions with the patient.',
          se: '',
          de: ''
        }
      },
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: '<b>Ziekteverloop en behandeleffect:</b><br>Met deze tool berekent u de verwachte cognitieve achteruitgang van uw patiënt na het stellen van de diagnose MCI of milde dementie door de ziekte van Alzheimer. Twee verschillende visuele weergaven maken de persoonlijke voorspelling van het ziekteverloop inzichtelijk en makkelijk uit te leggen aan de patiënt. Een lijngrafiek toont u de verwachte achteruitgang in cognitie (MMSE scores) over een periode van 5 jaar na diagnose. Ook kunt u de tijd berekenen tot een door u gekozen MMSE eindpunt (Tijd-tot-grafiek). In beide visuele weergaven plot u vervolgens eenvoudig het mogelijke effect van behandeling (zoals ziekte-remmende medicatie).',
          en: '<b>Disease progression and effect of treatment:</b><br>With this tool you can calculate the expected cognitive decline of your patient with MCI or mild dementia caused by Alzheimer\'s disease. Two different visualisations help make a personal prognosis of disease progression easy to grasp and explain to the patient. A line chart shows you the expected decline in cognition (MMSE) over a period of 5 years following diagnosis. You can also calculate the time to your own chosen MMSE endpoint (Time-to chart). With both visualisations you can then easily plot the potential effect of a treatment that aims to slow the disease (e.g. disease-modifying medication).',
          se: '',
          de: ''
        }
      },
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: 'De rekentools zijn momenteel beschikbaar voor inzage voor zorgprofessionals. Wilt u de tools inzien? Vraag dan via onderstaand formulier toegang tot de ADappt rekentools aan. U ontvangt dan een unieke link waarmee u toegang tot de rekentools krijgt.',
          en: 'Right now, the calculation tools are only available for viewing by healthcare providers and other professionals. Would you like to see these tools? Make a request for access to the ADappt calculation tools using the button below. You will receive a unique link giving you access to the calculation tools.',
          se: '',
          de: ''
        }
      },
    ],
    errors: {
      unknown: {
        nl: 'Er is iets mis gegaan, probeer het later nog eens.',
        en: 'Something went wrong, please try again later.',
        se: 'Något gick fel, försök igen senare.',
        de: 'Es ist ein Problem aufgetreten; bitte versuchen Sie es später noch einmal.'
      },
      invalid: {
        nl: 'Ongeldige of verlopen toegangslink, vraag een nieuwe toegangslink aan.',
        en: 'Invalid or expired access link, please request a new access link.',
        se: 'Ogiltig eller utgången åtkomstlänk, begär en ny åtkomstlänk.',
        de: 'Ungültiger oder abgelaufener Zugangslink; bitte fordern Sie einenneuen Zugangslink an.'
      },
    },
    form: {
      serverError: {
        nl: 'Er is iets mis gegaan, probeer het later opnieuw',
        en: 'Something went wrong, please try again later',
        se: 'Något gick fel, försök igen senare',
        de: 'Es ist ein Problem aufgetreten; bitte versuchen Sie es später noch einmal.'
      },
      name: {
        label: {
          nl: 'Naam',
          en: 'Name',
          se: 'Namn',
          de: 'Name'
        },
        placeholder: {
          nl: 'Jouw naam hier',
          en: 'Your name',
          se: 'Ditt namn här',
          de: 'Hier den Namen ausfüllen'
        }
      },
      email: {
        label: {
          nl: 'E-mail',
          en: 'Email',
          se: 'E-post',
          de: 'E-Mail'
        },
        placeholder: {
          nl: 'Jouw e-mailadres',
          en: 'Your email adress',
          se: 'Din e-postadress',
          de: 'Ihre E-Mail-Adresse'
        },
        validation: {
          nl: 'Voer alstublieft een geldig e-mailadres in',
          en: 'Please provide a valid email address',
          se: 'Ange en giltig e-postadress',
          de: 'Bitte geben Sie eine gültige E-Mail-Adresse an'
        },
      },
      organization: {
        label: {
          nl: 'Organisatie',
          en: 'Organization',
          se: 'Organisation',
          de: 'Organisation'
        },
        placeholder: {
          nl: 'Jouw organisatie',
          en: 'Your organization',
          se: 'Din organisation',
          de: 'Ihre Organisation'
        },
      },
      position: {
        label: {
          nl: 'Positie',
          en: 'Position',
          se: 'Befattning',
          de: 'Stellung'
        },
        placeholder: {
          nl: 'Jouw positie',
          en: 'Your position',
          se: 'Din befattning',
          de: 'Ihre Stellung'
        },
      },
      button: {
        text: {
          nl: 'Versturen',
          en: 'Send',
          se: 'Skicka',
          de: 'Abschicken'
        },
      },
    },
    disclaimer: {
      shortCopy: {
        nl: (
          <>
            <p><b>DISCLAIMER</b><br />Amsterdam UMC kan geen enkele verantwoordelijkheid en/of aansprakelijkheid aanvaarden voor schade ten gevolge van... <br /> <u>Lees meer</u></p>
          </>
        ),
        en: (
          <>
            <p><b>DISCLAIMER</b><br />Amsterdam UMC accepts no responsibility and/or liability for damages arising from... <br /> <u> Read more </u></p>
          </>
        ),
        se: (
          <>
            <p><b>ANSVARSFRISKRIVNING</b><br />Amsterdam UMC frånsäger sig allt ansvar och/eller skadeståndsansvar för skada som uppstår till följd av... <br /> <u>Läs mer</u></p>
          </>
        ),
        de: (
          <>
            <p><b>HAFTUNGSAUSSCHLUSS</b><br />Das Amsterdam UMC kann keinerlei Verantwortung und/oder Haftung für Schäden übernehmen, die darauf zurückzuführen sind, dass... <br /> <u>Weiterlesen</u></p>
          </>
        ),
      },
      longCopy: {
        nl: (
          <>
            <ol>
              <li>het gebruik van de informatie op haar website/webapplicatie, dan wel</li>
              <li>onjuistheden en/of onvolledigheden in en/of het niet actueel zijn van de informatie op haar website/webapplicatie, dan wel</li>
              <li>de (tijdelijke) onmogelijkheid om (de informatie op) de website/webapplicatie te raadplegen.</li>
            </ol>
            <p>Tevens aanvaardt Amsterdam UMC geen enkele verantwoordelijkheid en/of aansprakelijkheid voor schade die wordt geleden als gevolg van het direct of indirect gebruik van gegevens, adviezen of ideeën, alles in de ruimste zin des woords, die zijn verstrekt via haar website/webapplicatie. De (informatie op de) website/webapplicatie is uitsluitend bedoeld voor wetenschappelijke onderzoeksdoeleinden en nadrukkelijk niet ter vervanging van professioneel en persoonlijk medisch advies. Binnen de ADappt applicatie wordt geen data opgeslagen. Persoonlijke gegevens van patiënten worden niet bewaard.</p>
            <p>De webapplicatie ADappt voldoet niet aan de essentiële eisen die van toepassing zijn op medische hulpmiddelen volgens Bijlage I van de RICHTLIJN 93/42/EEG VAN DE RAAD van 14 juni 1993 betreffende medische hulpmiddelen. Het is u uitdrukkelijk niet toegestaan de webapplicatie in te zetten voor klinisch gebruik, noch voor gebruik binnen klinisch onderzoek. De webapplicatie is momenteel alleen beschikbaar voor inzage.</p>
            <p>Ieder ander gebruik, waaronder mede wordt verstaan openbaarmaking en verveelvoudiging, is - zonder de voorafgaande schriftelijke toestemming van Amsterdam UMC - niet toegestaan.</p>
          </>
        ),
        en: (
          <>
            <ol>
              <li>use of the information provided on its website or by its web application, or</li>
              <li>inaccuracies in and/or omissions from and/or supersession of the information provided on its website or by its web application, or</li>
              <li>the temporary or permanent non-availability of its website/web application or the associated information.</li>
            </ol>
            <p>Amsterdam UMC accepts no responsibility and/or liability for damages incurred as a result of the direct or indirect use of data, advice or ideas (all in the broadest sense) provided via its website/web application. The website/web application and the associated information are intended exclusively for scientific research purposes and are expressly not intended to replace personal, professional medical advice. The ADappt tool application does not store any of the entered (patient) data. No personal data regarding patients is retained.</p>
            <p>The ADappt web application does not meet the essential requirements for medical devices set out in Annex I to Council Directive 93/42/EEC of 14 June 1993 concerning medical devices. Hence, use of the web application for clinical practice or clinical research is explicitly prohibited. The web application is made available exclusively for perusal.</p>
            <p>Use (including publication and reproduction) for any other purpose is not permitted without the prior written consent of Amsterdam UMC.</p>
          </>
        ),
        se: (
          <>
            <ol>
              <li>användningen av informationen på Amsterdam UMC:s webbplats/webbapplikation, eller</li>
              <li>felaktigheter och/eller ofullständigheter och/eller inaktuell information på Amsterdam UMC:s webbplats/webbapplikation, eller</li>
              <li>att (informationen på) webbplatsen/webbapplikationen (tillfälligt) inte kan nås.</li>
            </ol>
            <p>Amsterdam UMC tar inte heller något ansvar och/eller skadeståndsansvar för skada som vållas till följd av direkt eller indirekt användning av uppgifter, råd eller idéer, allt i ordets vidaste bemärkelse, som tillhandahålls via Amsterdam UMC:s webbplats/webbapplikation. (Informationen på) webbplatsen/webbapplikationen är uteslutande avsedd för vetenskaplig forskning och kan uttryckligen inte ersätta professionell och personlig medicinsk rådgivning. Inga data sparas i ADappt-applikationen. Patienternas personuppgifter sparas inte.</p>
            <p>Webbapplikationen ADappt uppfyller inte de grundläggande kraven som gäller för medicintekniska produkter enligt bilaga I i RÅDETS DIREKTIV 93/42/EEG av den 14 juni 1993 om medicintekniska produkter. Det är uttryckligen inte tillåtet att använda webbapplikationen för kliniskt bruk, eller för kliniska studier. För tillfället är webbapplikationen endast tillgänglig för att titta på.</p>
            <p>All annan användning, bland annat offentliggörande och mångfaldigande, är inte tillåten utan föregående skriftligt tillstånd från Amsterdam UMC.</p>
          </>
        ),
        de: (
          <>
            <ol>
              <li>der Nutzer von den auf seiner Website beziehungsweise in seiner Webanwendung bereitgestellten Informationen Gebrauch macht, oder</li>
              <li>die auf seiner Website beziehungsweise in seiner Webanwendung bereitgestellten Informationen fehlerhaft und/oder unvollständig und/oder nicht aktuell sind, oder</li>
              <li>(vorübergehend) kein Zugriff auf die Website oder die Webanwendung beziehungsweise auf die dort bereitgestellten Informationen möglich ist.</li>
            </ol>
            <p>Darüber hinaus übernimmt das Amsterdam UMC keinerlei Verantwortung und/oder Haftung für Schäden, die durch die unmittelbare oder mittelbare Verwendung von - im weitesten Sinne des Wortes - Daten, Empfehlungen oder Ideen entstehen, die über seine Website beziehungsweise Webanwendung zur Verfügung gestellt worden sind. Die Website oder Webanwendung beziehungsweise die dort bereitgestellten Informationen sind ausschließlich für die wissenschaftliche Forschung bestimmt und dienen ausdrücklich nicht dazu, eine fachliche und persönliche medizinische Beratung zu ersetzen. In der ADappt-Anwendung werden keine Daten gespeichert. Persönliche Daten von Patienten werden nicht aufbewahrt.</p>
            <p>Die Webanwendung ADappt erfüllt nicht die gemäß Anhang I der Richtlinie 93/42/EWG des Rates vom 14. Juni 1993 über Medizinprodukte geltenden grundlegenden Anforderungen für medizinische Produkte. Es ist Ihnen ausdrücklich nicht gestattet, die Webanwendung für klinische Zwecke oder für die Anwendung in der klinischen Forschung zu verwenden. Die Webanwendung ist derzeit lediglich zur Ansicht verfügbar.</p>
            <p>Jede andere Nutzung, die Veröffentlichung und Vervielfältigung inbegriffen, ist nur mit der vorherigen schriftlichen Erlaubnis des Amsterdam UMC gestattet.</p>
          </>
        ),
      },
    },
  }
}
