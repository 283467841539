import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ContentToggler.css'

import chevronIcon from '/images/icons/chevron.raw.svg'

export function ContentToggler({ label, children }) {
  const [isExpanded, setIsExpanded] = React.useState(false)

  return (
    <div className={styles.component}>
      <div className={styles.contentContainer}>
        <span className={styles.label}>{label}</span>

        <button type='button' onClick={handleClick} className={styles.button}>
          <div className={cx(styles.iconContainer, isExpanded && styles.isExpanded)}>
            <Icon icon={chevronIcon} layoutClassName={styles.iconLayout} />
          </div>
        </button>
      </div>

      <TransitionGroup component={React.Fragment}>
        {isExpanded && (
          <CSSTransition
            timeout={parseInt(styles.transitionSpeed, 10)}
            classNames={{
              enter: styles.toggleContentEnter,
              enterDone: styles.toggleContentEnterDone,
              exit: styles.toggleContentExit,
              exitActive: styles.toggleContentExitActive,
            }}
          >
            <div className={styles.toggleContent}>
              {children}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  )

  function handleClick() {
    setIsExpanded(!isExpanded)
  }
}
