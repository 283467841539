export function parseQueryString(search) {
  const clean = search.startsWith('?') ? search.slice(1) : search
  const parts = clean.split('&')
  return parts.reduce(
    (result, part) => {
      const [key, value] = part.split('=')
      return {
        ...result,
        [key]: decodeURIComponent(value)
      }
    },
    {}
  )
}
