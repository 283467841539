import styles from './Icon.css'

export function Icon({ icon, layoutClassName = undefined }) {
  return (
    <span
      role='presentation'
      dangerouslySetInnerHTML={{ __html: icon }}
      className={cx(styles.component, layoutClassName)}
    />
  )
}
