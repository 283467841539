const i18nContext = React.createContext(null)

export function useI18n() {
  const context = React.useContext(i18nContext)
  if (!context) throw new Error('please provide an i18n context')
  return context
}

export function Provider({ value, section, language, children }) {
  const i18n = React.useMemo(
    () => {
      i18n.language = language
      return i18n
      function i18n(key) {
        const result = value[section][key]
        return normalize(language, result)
      }
    },
    [value, section, language]
  )
  return (
    <i18nContext.Provider value={i18n}>
      {children}
    </i18nContext.Provider>
  )
}

function normalize(language, value) {
  return (
    Array.isArray(value) ? value.map(x => normalize(language, x)) :
    typeof value === 'function' ? value :
    typeof value === 'object' && value !== null ? normalizeObject(language, value) :
    value
  )
}

function normalizeObject(language, o) {
  const result = o[language]

  return (
    result !== undefined ? result :
    isReactElement(o) ? o :
    mapValues(o, x => normalize(language, x))
  )
}

function mapValues(o, f) {
  return (
    Object.entries(o).reduce(
      (result, [k, v]) => ({ ...result, [k]: f(v) }),
      {}
    )
  )
}

function isReactElement(o) {
  const reactId = o['$$typeof']
  return reactId === Symbol.for('react.element') || reactId === 0xeac7
}
