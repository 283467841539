import styles from './OutcomeBlock.css'

export function OutcomeBlock({ items, selectedOutcome, extraDetails = undefined }) {
  return (
    <div className={cx(
      styles.container, {
        [styles.positive]: selectedOutcome === 0,
        [`${styles.negative}`]: selectedOutcome === (items.length - 1),
      })}>
      <div className={styles.detailComponent}>

        {extraDetails}

        <p className={styles.detailInfo}>{items[selectedOutcome].description}</p>
      </div>

      <div className={styles.outcomeComponent}>
        {items.map((x, i) => (
          <div className={cx(styles.outcomeBox, { [styles.selected]: selectedOutcome === i })} key={x.title}>
            {x.title}
          </div>
        ))}
      </div>
    </div>
  )
}

export function OutcomeBlockWithProtein({ items, selectedOutcome, protein }) {
  return (
    <OutcomeBlock {...{ items, selectedOutcome }} extraDetails={
      protein.map((x, i) =>
        <div className={styles.proteinBlock} key={i}>
          <span className={styles.proteinTotal}>{x.value}</span>
          <span className={styles.proteinType}>{x.name}</span>
        </div>
      )
    } />
  )
}
