// page banners
import alzheimerCentrum from '/images/Alzheimercentrum-04-zeker.jpg?max-width=978'
import previewAlzheimercentrum from '/images/Preview-Alzheimercentrum-_10-zeker.jpg?max-width=978'
import samenBeslissen from '/images/AlgemeenGebruik_samenBeslissen.jpg?max-width=978'
import predictietool from '/images/AlgemeenGebruik_predictietool.jpg?max-width=978'

// content pages
import neuropsychologischOnderzoek from '/images/A3.Alzheimercentrum-_23-Neuropsychologisch-onderzoek.jpg?max-width=470'
import amyloidPet from '/images/A3.Alzheimercentrum-_31-AmyloidPET.jpg?max-width=470'
import mri from '/images/A3.Alzheimercentrum-_41-MRI.jpg?max-width=470'
import csf from '/images/A3.Alzheimercentrum-06-CSF.jpg?max-width=470'
import lichamelijkOnderzoek from '/images/A3.Alzheimercentrum-_16-lichamelijk-onderzoek.jpg?max-width=470'

import tabletMock1 from '/images/A4.Tablet1.png?max-width=560'
import tabletMock2 from '/images/A4.Tablet2.png?max-width=560'

// logos
import vilansLogo from '/images/logo-vilans.png'

//uitslag
import gezondeHersenen from '/images/B6.VoorbeeldMRI.jpg?max-width=260'

export const images = {
  neuropsychologischOnderzoek,
  amyloidPet,
  mri,
  csf,
  lichamelijkOnderzoek,
  tabletMock1,
  tabletMock2,
  alzheimerCentrum,
  previewAlzheimercentrum,
  samenBeslissen,
  predictietool,
  vilansLogo,
  gezondeHersenen,
}
