import styles from './Button.css'

/**
  * @param {{
* type?: 'button' | 'submit' | 'reset',
* children,
* onClick?,
* disabled?: boolean,
* layoutClassName?: string
* }} props
*/

export function ButtonPrimary({ children, onClick = undefined, disabled = false, type = 'button', layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentPrimary, layoutClassName)} {...{ children, onClick, disabled, type }} />
}

export function ButtonPrimaryOutline({ children, onClick, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentPrimaryOutline, layoutClassName)} {...{ children, onClick }} />
}

export function ButtonSecondary({ children, onClick, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentSecondary, layoutClassName)} {...{ children, onClick }} />
}

export function ButtonSecondaryOutline({ children, onClick, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentSecondaryOutline, layoutClassName)} {...{ children, onClick }} />
}

export function ButtonTertiary({ children, onClick }) {
  return <ButtonBase className={styles.componentTertiary} {...{ children, onClick }} />
}

export function ButtonLinkPrimary({ children, href, target = '_self', layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkPrimary, layoutClassName)} {...{ children, href, target }} />
}

/**
  * @param {{
  * type?: 'button' | 'submit' | 'reset',
  * children,
  * onClick,
  * disabled?: boolean,
  * className?: string
  * }} props
*/

function ButtonBase({ type = 'button', children, onClick, className, disabled = undefined }) {
  return (
    <button className={cx(styles.componentBase, className)} {...{ onClick, disabled, type }}>
      {children}
    </button>
  )
}


function ButtonLinkBase({ children, className, href, target }) {
  return (
    <a className={cx(styles.componentLinkBase, className)} {...{ href, target }}>
      {children}
    </a>
  )
}
