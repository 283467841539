import { useI18n } from '/machinery/useI18n'

import { CircularChart } from '/features/buildingBlocks/CircularChart'

import styles from './InfographicContainer.css'

export function InfographicContainer({ results }) {
  const i18n = useI18n()
  const circularCharts = i18n('circularCharts')
  const safeResults = results || { '1year': null, '3year': null, '5year': null }

  const allDisabled = !Object.values(safeResults).some(Boolean)

  return (
    <div className={styles.containerInfographic}>
      <CircularChart
        item={circularCharts['1year'].label}
        percentage={safeResults['1year']}
        color={allDisabled || safeResults['1year'] ? 'primaryColor' : 'disabledColor'}
      />
      <CircularChart
        item={circularCharts['3year'].label}
        percentage={safeResults['3year']}
        color={allDisabled || safeResults['3year'] ? 'secondaryColor' : 'disabledColor'}
      />
      <CircularChart
        item={circularCharts['5year'].label}
        percentage={safeResults['5year']}
        color={allDisabled || safeResults['5year'] ? 'tertiaryColor' : 'disabledColor'}
      />
    </div>
  )
}
