import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDatabase, ref, push } from 'firebase/database'

import { useFirebase } from '/machinery/firebase'
import { useI18n } from '/machinery/useI18n'
import { useIsMountedRef } from '/machinery/useIsMountedRef'

import { Content } from '/features/buildingBlocks/Content'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { Form, FormGroup } from '/features/buildingBlocks/Form'
import { FormFieldText, FormFieldError, isValidEmail } from '/features/buildingBlocks/FormField'
import { PrivacyStatement } from '/features/buildingBlocks/PrivacyStatement'
import Loader from '/features/buildingBlocks/Loader'

import styles from './Welkom.css'

const currentPage = 'welkom'

export function Welkom({ loggedIn, loginError, introduction }) {
  const i18n = useI18n()
  const { content, disclaimer, formSent: formSentCopy, errors } = i18n(currentPage)
  const privacyStatement = i18n('privacyStatement')

  const [sent, setSent] = React.useState(false)
  const [showStatement, setShowStatement] = React.useState(false)

  return (
    <section className={styles.component}>
      <div className={styles.introduction}>{introduction}</div>

      <Content {...{ content }} />

      {sent && formSentCopy}
      {!loggedIn && !sent && (
        <>
          {loginError && <FormFieldError text={errors[loginError]} />}
          <RequestAccessForm onSent={() => setSent(true)} />
        </>
      )}

      <div>
        <PrivacyStatement onClick={() => setShowStatement(x => !x)} {...{ showStatement, privacyStatement }} />
        <ShowMoreContent shortCopy={disclaimer.shortCopy} longCopy={disclaimer.longCopy} />
      </div>
    </section>
  )
}

function ShowMoreContent({ shortCopy, longCopy }) {
  const [showContent, setShowContent] = React.useState(false)

  return (
    <div className={styles.componentShowMoreContent}>
      <button type='button' onClick={handleClick} className={styles.showMoreButton}>
        {shortCopy}
      </button>

      <TransitionGroup component={React.Fragment}>
        {showContent && (
          <CSSTransition
            timeout={parseInt(styles.transitionSpeed, 10)}
            classNames={{
              enter: styles.showMoreEnter,
              enterDone: styles.showMoreEnterDone,
              exit: styles.showMoreExit,
              exitActive: styles.showMoreExitActive,
            }}
          >
            <div className={styles.showMoreContent}>
              {longCopy}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  )

  function handleClick() {
    setShowContent(!showContent)
  }
}

function RequestAccessForm({ onSent }) {
  const i18n = useI18n()
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [position, setPosition] = React.useState('')
  const [organization, setOrganization] = React.useState('')

  const [serverError, setServerError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const firebaseApp = useFirebase()
  const isMountedRef = useIsMountedRef()

  const copy = i18n(currentPage).form

  const errors = {
    ...validateEmail({ email }),
    global: serverError
  }
  const hasErrors = !!Object.values(errors).filter(Boolean).length
  const disabled = loading || !name || !email || !organization || !position || hasErrors

  return (
    <Form {...{ onSubmit }}>
      <FormFieldText
        required
        name={copy.name.label}
        onChange={setName}
        value={name}
        placeholder={copy.name.placeholder}
      />

      <FormGroup>
        <FormFieldText
          required
          name={copy.position.label}
          onChange={setPosition}
          value={position}
          placeholder={copy.position.placeholder}
        />
        <FormFieldText
          required
          name={copy.organization.label}
          onChange={setOrganization}
          value={organization}
          placeholder={copy.organization.placeholder}
        />
      </FormGroup>

      <FormFieldText
        required
        name={copy.email.label}
        onChange={setEmail}
        value={email}
        error={errors.email}
        placeholder={copy.email.placeholder}
      />

      {errors.global && <FormFieldError text={errors.global} />}

      <ButtonPrimary type='submit' {...{ disabled }}>
        {loading ? <Loader /> : copy.button.text}
      </ButtonPrimary>
    </Form>
  )

  async function onSubmit() {
    try {
      setLoading(true)

      await signInAnonymously(getAuth(firebaseApp))

      const queueRef = ref(getDatabase(firebaseApp), 'services/request-access-service-queue')
      await push(queueRef, { name, email, organization, position, language: i18n.language })

      if (isMountedRef.current) onSent()
    } catch (e) {
      if (isMountedRef.current) setServerError(copy.serverError)

      if (process.env.NODE_ENV !== 'production') console.error(e)
      window.Rollbar.error(e)
    } finally {
      if (isMountedRef.current) setLoading(false)
    }
  }

  function validateEmail({ email }) {
    return {
      ...(email === '' || isValidEmail(email) ? {} : { email: copy.email.validation })
    }
  }
}
