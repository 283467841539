import { useI18n } from '/machinery/useI18n'
import styles from './NotFound.css'

export function NotFound(props) {
  const i18n = useI18n()
  const { content } = i18n('notFound')

  return (
    <section className={styles.component}>
      {content}
    </section>
  )
}
