import { useLoginWithLink } from './useLoginWithLink'

import styles from './Login.css'

export function Login({ location, onLoginSuccess, onLoginError, loginData }) {
  const { error, isLoggedIn } = useLoginWithLink({ location, loginData })

  React.useEffect(
    () => {
      if (isLoggedIn) onLoginSuccess()
    },
    [isLoggedIn, onLoginSuccess]
  )

  React.useEffect(
    () => {
      if (error) onLoginError(error)
    },
    [error, onLoginError]
  )

  return (
    <div className={styles.component}>
      <Loader layoutClassName={styles.loaderLayout} />
    </div>
  )
}

function Loader({ layoutClassName = undefined }) {
  return (
    <svg viewBox='0 0 36 36' className={cx(styles.componentLoader, layoutClassName)}>
      <path className={styles.circleBg}
        d='M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831'
      />

      <path className={styles.circle}
        strokeDasharray='100 100'
        d='M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831'
      />

      <text x='18' y='20.35' className={styles.text}>
        Loading
      </text>
    </svg>
  )
}
