import { useI18n } from '/machinery/useI18n'

import { getPointAdjustedForZoom } from '/features/pageOnly/medicationTool/machinery/getPointAdjustedForZoom'
import { GraphViewbox } from '/features/pageOnly/medicationTool/GraphViewbox'
import { AxisXY } from '/features/pageOnly/medicationTool/Axis'
import { Legend, LegendItem } from '/features/pageOnly/medicationTool/Legend'

import styles from './LineGraph.css'

export function LineGraph({
  xMax = 5,
  xStep = 1,
  xZoom = 0.25,
  yMax = 30,
  yStep = 10,
  yZoom = 2,
  xLabel,
  yLabel,
  curves,
  showData,
  showTreatmentEffects
}) {
  const i18n = useI18n()
  const graphsContent = i18n('graphsContent')

  const treatmentPoints = curves?.withMedication
  const avaragePoints = curves?.withoutMedication.variance05
  const upperBound = curves?.withoutMedication.variance025
  const lowerBound = curves?.withoutMedication.variance075

  const treatmentPath = avaragePoints?.length
    ? createSvgPathFromPoints({ dataPoints: treatmentPoints, xZoom, xMax, yZoom, yMax })
    : ''
  const avaragePath = avaragePoints?.length
    ? createSvgPathFromPoints({ dataPoints: avaragePoints, xZoom, xMax, yZoom, yMax })
    : ''

  const width = xMax / xZoom
  const height = yMax / yZoom

  return (
    <div className={styles.component}>
      <div>
        <GraphViewbox viewBox={`-2.5 -1.5 ${width + 3} ${height + 5}`} {...{ width, height, yZoom }}>
          <AxisXY {...{ width, height, xLabel, xMax, xStep, xZoom, yLabel, yMax, yStep, yZoom }} />
          {showData && (
            <>
              <PathMarginOfError {...{ upperBound, lowerBound, xZoom, xMax, yZoom, yMax }} />
              <PathAverage path={avaragePath} />
              {showTreatmentEffects && <PathTreatment path={treatmentPath} />}
            </>
          )}
        </GraphViewbox>
      </div>

      <Legend>
        <LegendItem
          label={graphsContent.lineGraph.legend.average}
          path='M 0 3 h 6'
          strokeWidth={1}
          strokeDasharray={0}
          pathStyle={styles.legendItemAverage}
        />
        <LegendItem
          label={graphsContent.lineGraph.legend.uncertainty}
          tooltip={graphsContent.lineGraph.legend.uncertaintyTooltip}
          path='M 2 0 h 2 q 2 0 2 2 v 2 q 0 2 -2 2 h -2 q -2 0 -2 -2 v -2 q 0 -2 2 -2 z'
          strokeWidth={0.5}
          strokeDasharray={1.33}
          pathStyle={styles.legendItemMarginOfError}
        />
        {showTreatmentEffects && (
          <LegendItem
            label={graphsContent.lineGraph.legend.treatment}
            path='M 0 3 h 6'
            strokeWidth={1}
            strokeDasharray={1}
            pathStyle={styles.legendItemTreatment}
          />
        )}
      </Legend>
    </div>
  )
}

function PathTreatment({ path }) {
  return <PathBase strokeDasharray={0.5} className={styles.componentPathTreatment} {...{ path }} />
}

function PathAverage({ path }) {
  return <PathBase strokeDasharray={0} className={styles.componentPathAverage} {...{ path }} />
}

function PathBase({ path, strokeDasharray, className }) {
  return (
    <path
      d={path}
      strokeWidth={0.2}
      strokeDashoffset={0}
      className={cx(styles.componentPathBase, className)}
      {...{ strokeDasharray }}
    />
  )
}

function PathMarginOfError({ upperBound, lowerBound, xZoom, xMax, yZoom, yMax }) {
  const pathUpper = createSvgPathFromPoints({ dataPoints: upperBound, xZoom, xMax, yZoom, yMax })
  const pathLower = createSvgPathFromPoints({ dataPoints: lowerBound, xZoom, xMax, yZoom, yMax })
  const pathFill = createMarginOfErrorSvgPathFromPoints({ upperBound, lowerBound, xZoom, xMax, yZoom, yMax })

  return (
    <g>
      <path
        d={pathUpper}
        strokeWidth={0.05}
        strokeDashoffset={0}
        strokeDasharray={0.5}
        className={styles.marginOfErrorPath}
      />
      <path
        d={pathLower}
        strokeWidth={0.05}
        strokeDashoffset={0}
        strokeDasharray={0.5}
        className={styles.marginOfErrorPath}
      />
      <path
        d={pathFill}
        strokeWidth={0.05}
        strokeDashoffset={0}
        strokeDasharray={0.5}
        className={styles.marginOfErrorFill}
      />
    </g>
  )
}

function createSvgPathFromPoints({ dataPoints, xZoom, xMax, yZoom, yMax }) {
  if (!dataPoints) return ''

  const filteredDataPoints = dataPoints.filter(point => point.x <= xMax && point.y >= 0)

  const startingPoint = `M ${getPointAdjustedForZoom(xZoom, filteredDataPoints[0].x)} ${getPointAdjustedForZoom(yZoom, yMax - filteredDataPoints[0].y)}`

  const path = filteredDataPoints.slice(1).map(point => {
    return `L ${getPointAdjustedForZoom(xZoom, point.x)} ${getPointAdjustedForZoom(yZoom, yMax - point.y)}`
  })

  return [startingPoint, ...path].join(' ')
}

function createMarginOfErrorSvgPathFromPoints({ upperBound, lowerBound, xZoom, xMax, yZoom, yMax }) {
  const filteredUpperbound = upperBound.map(({ x, y }) => ({
    x: x > xMax ? xMax : x,
    y: y < 0 ? 0 : y > yMax ? yMax : y
  }))

  const filteredLowerbound = lowerBound.map(({ x, y }) => ({
    x: x > xMax ? xMax : x,
    y: y < 0 ? 0 : y > yMax ? yMax : y
  }))

  const filteredUpperBoundReverse = filteredUpperbound.toReversed()
  const startingPoint = `M ${getPointAdjustedForZoom(xZoom, filteredUpperBoundReverse[0].x)} ${getPointAdjustedForZoom(yZoom, yMax - filteredUpperBoundReverse[0].y)}`

  const upperPath = filteredUpperBoundReverse.slice(1).map(point => {
    return `L ${getPointAdjustedForZoom(xZoom, point.x)} ${getPointAdjustedForZoom(yZoom, yMax - point.y)}`
  })

  const lowerPath = filteredLowerbound.map(point => {
    return `L ${getPointAdjustedForZoom(xZoom, point.x)} ${getPointAdjustedForZoom(yZoom, yMax - point.y)}`
  })

  return [startingPoint, ...upperPath, ...lowerPath, 'z'].join(' ')
}
