import { ButtonTertiary } from  '/features/buildingBlocks/Button'

import styles from './PrivacyStatement.css'

export function PrivacyStatement({ onClick, privacyStatement, showStatement }) {
  return (
    <section className={styles.privacyStatement}>
      <ButtonTertiary {...{ onClick }}>
        {privacyStatement.buttonText}
      </ButtonTertiary>

      {showStatement && <div className={styles.privacyStatementContent}>{privacyStatement.statement}</div>}
    </section>
  )
}
