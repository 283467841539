import { inRange, isNumber, required, validate } from '/api/validation.js'

export const mciFieldInfo = {
  age: [45, 82],
  mmseAtBaseline: [7, 30],
  csfPtau: [3, 120],
  csfAb42: [145, 1585],
  medicineEffect: [0, 50],
  mmseOfInterest: [7, 30]
}

export const adFieldInfo = {
  ...mciFieldInfo,
  apoeDose: [0, 2]
}

export function validateAd(inputParams) {
  const errors = validate(inputParams, adValidations)
  return errors
}


export function validateMci(inputParams) {
  const errors = validate(inputParams, mciValidations)
  return errors
}

const mciValidations = {
  csfAb42: [required(), isNumber(), inRange(mciFieldInfo.csfAb42)],
  csfPtau: [required(), isNumber(), inRange(mciFieldInfo.csfPtau)],
  sex: [required()],
  age: [required(), isNumber(), inRange(mciFieldInfo.age)],
  mmseAtBaseline: [required(), isNumber(), inRange(mciFieldInfo.mmseAtBaseline)],
  medicineEffect: [required(), isNumber(), inRange(mciFieldInfo.medicineEffect)],
  mmseOfInterest: [required(), isNumber(), inRange([mciFieldInfo.mmseOfInterest[0], ({ mmseAtBaseline }) => mmseAtBaseline ])],
}

const adValidations = {
  ...mciValidations,
  apoeDose: [required(), isNumber(), inRange(adFieldInfo.apoeDose)]
}
