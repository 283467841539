import styles from './ImageWithText.css'

export function ImageWithText({ text, src, alt = undefined }) {
  return (
    <div className={styles.component}>
      <p dangerouslySetInnerHTML={{ __html: text }} />
      <div className={styles.imageWrapper}>
        <img className={styles.image} {...{ src, alt }} />
      </div>
    </div>
  )
}
