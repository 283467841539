import { useI18n } from '/machinery/useI18n'

import { GraphViewbox } from '/features/pageOnly/medicationTool/GraphViewbox'
import { AxisJustLabels } from '/features/pageOnly/medicationTool/Axis'
import { AnimatedPathAverage, AnimatedPathFiveYearMark, AnimatedPathMarginOfError, AnimatedPathTreatment } from './AnimatedPath'
import { Tooltip } from '/features/pageOnly/medicationTool/animatedGraph/Tooltip'
import { Controls } from '/features/pageOnly/medicationTool/animatedGraph/Controls'

import styles from './AnimatedGraph.css'

const width = 15
const height = 10

const minAnimationState = 1
// const maxAnimationState = 7

export function AnimatedGraph({ maxAnimationState }) {
  const i18n = useI18n()
  const text = i18n('animatedGraph')

  const [animationState, setAnimationState] = React.useState({ currentState: 1, previousState: 1 })
  const { currentState, previousState } = animationState

  return (
    <div className={styles.component}>
      <GraphViewbox viewBox={`-1.5 0 ${width + 1.75} ${height + 1.5}`} {...{ width, height }}>
        <AxisJustLabels yLabel={text.yLabel} xLabel={text.xLabel} {...{ width, height }} />

        <AnimatedPathMarginOfError isActive={currentState === 3} isDelayed={previousState < 3} />
        <AnimatedPathFiveYearMark isActive={currentState >= 2} {...{ height }} />
        <AnimatedPathAverage isActive={currentState >= 2} />
        <AnimatedPathTreatment isActive={currentState >= 5} isComplete={currentState >= 6} />

        <Tooltip isActive={currentState === 1} x={2.5} y={2.1} placement='right' label={text.tooltips.step1} />

        <Tooltip isActive={currentState === 2} x={10.3} y={4.4} placement='bottom' label={text.tooltips.step2} isDelayed={previousState < 2} />

        <Tooltip isActive={currentState === 3} x={10.3} y={2.1} placement='top' label={text.tooltips.step3} isDelayed={previousState < 3} />
        <Tooltip isActive={currentState === 3} x={10.3} y={5.9} placement='bottom' label={text.tooltips.step3_2} isDelayed={previousState < 3} />

        <Tooltip isActive={currentState === 4} x={10.3} y={4.4} placement='bottom' label={text.tooltips.step4} isDelayed={previousState < 4} />

        <Tooltip isActive={currentState === 5} x={3.26} y={2.7} placement='bottom' label={text.tooltips.step5} isDelayed={previousState < 5} />

        <Tooltip isActive={currentState === 6} x={10.4} y={2.65} placement='top' label={text.tooltips.step6} />

        <Tooltip isActive={currentState === 7} x={10.8} y={3.25} placement='right' label={text.tooltips.step7} />
      </GraphViewbox>

      <Controls onAnimationStateChange={handleAnimationStateChange} {...{ currentState, minAnimationState, maxAnimationState }} />
    </div>
  )

  function handleAnimationStateChange(x) {
    setAnimationState(prevState => ({
      currentState: Math.min(maxAnimationState, Math.max(minAnimationState, prevState.currentState + x)),
      previousState: prevState.currentState
    }))
  }
}
