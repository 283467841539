import { Text } from '/features/buildingBlocks/Text'
import { Paragraph } from '/features/buildingBlocks/Paragraph'
import { Heading } from '/features/buildingBlocks/Heading'
import { Video } from '/features/buildingBlocks/Video'
import { Image } from '/features/buildingBlocks/Image'
import { ImageWithText } from '/features/buildingBlocks/ImageWithText'
import { Accordion } from '/features/buildingBlocks/Accordion'
import { OrderedAccordion } from '/features/buildingBlocks/OrderedAccordion'
import { ButtonLink } from '/features/buildingBlocks/ButtonLink'
import { ProsAndCons } from '/features/buildingBlocks/ProsAndCons'
import { BulletList } from '/features/buildingBlocks/BulletList'
import { OrderedList } from '/features/buildingBlocks/OrderedList'
import { AnchorLinks } from '/features/buildingBlocks/AnchorLinks'
import { Section } from '/features/buildingBlocks/Section'
import { Team } from '/features/buildingBlocks/Team'

import styles from './Content.css'

export function Content({ content }) {
  return (
    <div className={styles.component}>
      {content?.map((x, i) => {
        switch (x.type) {
          case 'text': return <Text key={i} text={x.text} />
          case 'paragraph': return <Paragraph key={i} text={x.text} />
          case 'heading': return <Heading key={i} h={x.h} text={x.text} layoutClassName={styles.headingLayout} />
          case 'video': return <Video key={i} src={x.src} />
          case 'image': return <Image key={i} src={x.src} width={x.width} />
          case 'imagewithtext': return <ImageWithText key={i} text={x.text} src={x.src} />
          case 'accordion': return <Accordion key={i} items={x.items} />
          case 'orderedaccordion': return <OrderedAccordion key={i} items={x.items} />
          case 'buttonlink': return <ButtonLink key={i} label={x.label} href={x.href} targetBlank={x.targetBlank} />
          case 'prosandcons': return <ProsAndCons key={i} pros={x.pros} cons={x.cons} />
          case 'bulletlist': return <BulletList key={i} content={x.content} title={x.title} />
          case 'orderedlist': return <OrderedList key={i} content={x.content} />
          case 'anchorlinks': return <AnchorLinks key={i} title={x.title} items={x.items} />
          case 'section': return <Section key={i} id={x.id} content={x.content} />
          case 'team': return <Team key={i} />
          default: {
            console.log(new Error(`Unknown content type '${x.type}'`))
            return null
          }
        }
      })}
    </div>
  )
}

export const contentType = {
  TEXT: 'text',
  PARAGRAPH: 'paragraph',
  HEADING: 'heading',
  VIDEO: 'video',
  IMAGE: 'image',
  IMAGEWITHTEXT: 'imagewithtext',
  ACCORDION: 'accordion',
  ORDEREDACCORDION: 'orderedaccordion',
  BUTTONLINK: 'buttonlink',
  PROSANDCONS: 'prosandcons',
  BULLETLIST: 'bulletlist',
  ORDEREDLIST: 'orderedlist',
  ANCHORLINKS: 'anchorlinks',
  SECTION: 'section',
  TEAM: 'team',
}
