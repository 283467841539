import { useI18n } from '/machinery/useI18n'

import { Heading } from '/features/buildingBlocks/Heading'

import styles from './Team.css'

export function Team() {
  const i18n = useI18n()
  const { title, contactTitle, team } = i18n('team')

  return (
    <section className={styles.component}>
      <Heading h={2} text={title} />

      <ul className={styles.cardsGrid}>
        {team.map(({ name, src, profession }, i) => <Card key={i} {...{ name, src, profession }} />)}
      </ul>

      <div>
        <b>{contactTitle}:</b>
        <p>T: <a className={styles.link} href="tel:020-4440685">020-4440685</a></p>
        <p>E: <a className={styles.link} href="mailto:h.hendriksen@amsterdamumc.nl">h.hendriksen@amsterdamumc.nl</a></p>
      </div>
    </section>
  )
}

function Card({ name, src, profession }) {
  return (
    <li className={styles.componentCard}>
      <div className={styles.imageContainer}>
        <img className={styles.image} alt={name} {...{ src }} />
      </div>

      <h3 className={styles.name}>{name}</h3>

      <p className={styles.cardDetail}>{profession}</p>
    </li>
  )
}
