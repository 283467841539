import { serverTimestamp } from 'firebase/database'
import { initializeApp, getApp  } from 'firebase/app'
import { getAuth, onIdTokenChanged } from 'firebase/auth'

const FirebaseContext = React.createContext(null)

export const TIMESTAMP = serverTimestamp()

/** @returns { import('firebase/app').FirebaseApp } */
export function useFirebase() {
  return React.useContext(FirebaseContext)
}

/** @returns { import('firebase/auth').User } */
export function useFirebaseUser({ reportError }) {
  const app = useFirebase()
  const [user, setUser] = React.useState(undefined)

  React.useEffect(
    () => {
      if (!app) return
      const unsubscribe = onIdTokenChanged(getAuth(app), x => setUser(x), reportError)
      return unsubscribe
    },
    [app, reportError]
  )

  return user
}

export function FirebaseProvider({ name, config, children }) {
  const [firebaseApp, setFirebaseApp] = React.useState(null)
  React.useEffect(() => {
    let firebaseApp

    try {
      firebaseApp = getApp(name)
    } catch (err) {
      firebaseApp = initializeApp(config, name)
    }

    setFirebaseApp(firebaseApp)
  }, [name, config])

  return (
    <FirebaseContext.Provider value={firebaseApp}>
      {children}
    </FirebaseContext.Provider>
  )
}
