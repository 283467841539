import { Content } from '/features/buildingBlocks/Content'

import styles from './OrderedList.css'

export function OrderedList({ content }) {
  return (
    <ol className={styles.component}>
      {content.map((contentItem, i) => (
        <li key={i}>
          <Content content={[contentItem]} />
        </li>
      ))}
    </ol>
  )
}
