import styles from './AnchorLinks.css'

export function AnchorLinks({ title, items }) {
  return (
    <div className={styles.component}>
      <span className={styles.title}>{title}</span>
      <div className={styles.content}>
        {items.map((item, i) => (
          <Pill key={i} {...{ item }} />
        ))}
      </div>
    </div>
  )
}

function Pill({ item }) {
  const { label, anchor } = item

  return <a className={styles.componentPill} href={`#${anchor}`}>{label}</a>
}
