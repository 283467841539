import { Match, Router, ServerLocation } from '@reach/router'
import { reportError } from '/machinery/reportError'

import { i18n } from '/i18n'
import { Provider as I18nProvider, useI18n } from '/machinery/useI18n'
import { FirebaseProvider, useFirebaseUser } from '/machinery/firebase'
import { ScrollToTopOnLocationChange } from '/machinery/ScrollToTopOnLocationChange'

import { PredictieTool as ToolPage } from '/pages/PredictieTool'
import { MedicatieTool as MedicatieToolPage } from '/pages/MedicatieTool'
import { NotFound } from '/pages/NotFound'
import { Contact } from '/pages/Contact'
import { PageLayoutInformation, PageLayoutTools } from '/features/pageOnly/PageLayout'
import { Login } from '/login/Login'
import { ContentPage } from '/pages/ContentPage'
import { Welkom } from './features/pageOnly/predictionTool/Welkom'

import AppLayout from '/features/pageOnly/AppLayout'

export default function App({ config, calculatorFieldInfo, url, loginData }) {
  const [loginError, setLoginError] = React.useState(null)

  return (
    <FirebaseProvider name='adappt' config={config.firebaseConfig}>
      <ProvideLocationIfNotMounted {...{ url }}>
        <ScrollToTopOnLocationChange />
        <Router primary={false}>
          <SectionI18NProvider path=':language'>
            <LoginWithCallbacks path='login' onLoginCompleteUrl='../tools' onLoginError={setLoginError} {...{ loginData }} />
            <AppLayout path='/'>
              <Informatie path='informatie/*' />
              <Tools path='tools' {...{ loginError }} />
              <PredictieTool path='predictie_tool/*' fieldInfo={calculatorFieldInfo} {...{ loginError }} />
              <MedicatieTool path='medicatie_tool/*' {...{ loginError }} />
            </AppLayout>
          </SectionI18NProvider>
        </Router>
      </ProvideLocationIfNotMounted>
    </FirebaseProvider>
  )
}

function Tools({ loginError }) {
  const i18n = useI18n()
  const user = useFirebaseUser({ reportError })
  const loggedIn = user && !user.isAnonymous

  return (
    <PageLayoutTools currentPageTitle={i18n('content').title} menuItems={i18n('pageNavigation').pages}>
      <Welkom introduction={i18n('content').introduction} {...{ loggedIn, loginError }} />
    </PageLayoutTools>
  )
}

function PredictieTool({ fieldInfo, loginError }) {
  return (
    <Router primary={false}>
      <ToolPage path="/" {...{ fieldInfo, loginError }} />
      <NotFound default />
    </Router>
  )
}

function MedicatieTool({ fieldInfo, loginError }) {
  return (
    <Router primary={false}>
      <MedicatieToolPage path="/" {...{ fieldInfo, loginError }} />
      <NotFound default />
    </Router>
  )
}

function Informatie() {
  return (
    <PageLayoutInformation>
      <Router primary={false}>
        <Empty path='/' />
        <ContentPage path="/topiclijst" i18nPageTitle="topiclijst" />
        <ContentPage path="/diagnostischetesten" i18nPageTitle="diagnostischeTesten" />
        <ContentPage path="/behandeling" i18nPageTitle="behandeling" />
        <ContentPage path="/diagnostiek" i18nPageTitle="diagnostiek" />
        <ContentPage path="/kansberekenen" i18nPageTitle="kansBerekenen" />
        <ContentPage path="/veelgesteldevragen" i18nPageTitle="veelgesteldeVragen" />
        <ContentPage path="/meedoenaanonderzoek" i18nPageTitle="meedoenAanOnderzoek" />
        <ContentPage path="/overons" i18nPageTitle="overOns" />
        <Contact path="/contact" />
        <NotFound default />
      </Router>
    </PageLayoutInformation>
  )
}

function ProvideLocationIfNotMounted({ url, children }) {
  return (typeof window === 'undefined')
    ? <ServerLocation {...{ url }}>{children}</ServerLocation>
    : children
}

function SectionI18NProvider({ language, children }) {
  return (
    <Match path=":section/*">
      {props =>
        <I18nProvider {...{ language }} value={i18n} section={props.match.section}>
          {children}
        </I18nProvider>
      }
    </Match>
  )
}

function LoginWithCallbacks({ location, navigate, loginData, onLoginError, onLoginCompleteUrl }) {
  const onLoginComplete = React.useCallback(
    () => { navigate(onLoginCompleteUrl) },
    [navigate, onLoginCompleteUrl]
  )

  const onLoginSuccess = onLoginComplete
  const handleLoginError = React.useCallback(
    e => { onLoginError(e); onLoginComplete() },
    [onLoginError, onLoginComplete]
  )

  return <Login onLoginError={handleLoginError} {...{ location, loginData, onLoginSuccess }} />
}

function Empty() {
  return <></>
}
