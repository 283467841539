import { getDatabase, ref, push } from 'firebase/database'
import { getAuth, signInAnonymously } from 'firebase/auth'

import { useI18n } from '/machinery/useI18n'

import { useFirebase } from '/machinery/firebase'
import { useIsMountedRef } from '/machinery/useIsMountedRef'

import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { FormGroup, Form } from '/features/buildingBlocks/Form'
import { FormFieldText, FormFieldTextArea, FormFieldError, isValidEmail } from '/features/buildingBlocks/FormField'
import { PrivacyStatement } from '/features/buildingBlocks/PrivacyStatement'
import Loader from '/features/buildingBlocks/Loader'

import styles from './Contact.css'

export function Contact() {
  const i18n = useI18n()
  const { form, formSent } = i18n('contact')
  const privacyStatement = i18n('privacyStatement')

  const [ showStatement, setShowStatement ] = React.useState(false)
  const [sent, setSent] = React.useState(false)

  return (
    <section>
      {sent
        ? <div className={styles.thankYou}>{formSent}</div>
        : <ContactForm copy={form} onSent={_ => setSent(true)} />
      }
      <PrivacyStatement
        onClick={() => setShowStatement(x => !x)}
        {...{ showStatement, privacyStatement }}
      />
    </section>
  )
}

function ContactForm({ copy, onSent }) {
  const [email, setEmail] = React.useState('')
  const [serverError, setServerError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [name, setName] = React.useState('')
  const firebaseApp = useFirebase()
  const isMountedRef = useIsMountedRef()

  const errors = {
    ...validateEmail({ email }),
    global: serverError
  }
  const hasErrors = !!Object.values(errors).filter(Boolean).length
  const disabled = loading || !name || !email || !message || hasErrors

  return (
    <Form {...{ onSubmit }}>
      <FormGroup>
        <FormFieldText
          required
          name={copy.name.label}
          onChange={setName}
          value={name}
          placeholder={copy.name.placeholder}
        />

        <FormFieldText
          required
          name={copy.email.label}
          onChange={setEmail}
          value={email}
          error={errors.email}
          placeholder={copy.email.placeholder}
        />
      </FormGroup>

      <FormFieldTextArea
        required
        name={copy.message.label}
        value={message}
        onChange={setMessage}
        placeholder={copy.message.placeholder}
      />

      {errors.global && <FormFieldError text={errors.global} />}

      <ButtonPrimary type='submit' {...{ disabled }}>
        {loading ? <Loader /> : copy.button.text}
      </ButtonPrimary>
    </Form>
  )

  async function onSubmit() {
    try {
      setLoading(true)

      const auth = getAuth(firebaseApp)
      if (!auth.currentUser) await signInAnonymously(auth)

      const queueRef = ref(getDatabase(firebaseApp), 'services/contact-service-queue')
      await push(queueRef, { name, email, message })

      if (isMountedRef.current) onSent()
    } catch (e) {
      if (isMountedRef.current) setServerError(copy.serverError)

      if (process.env.NODE_ENV !== 'production') console.error(e)
      window.Rollbar.error(e)
    } finally {
      if (isMountedRef.current) setLoading(false)
    }
  }

  function validateEmail({ email }) {
    return {
      ...(email === '' || isValidEmail(email) ? {} : { email: copy.email.validation })
    }
  }
}
