import { navigate } from '@reach/router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Icon } from '/features/buildingBlocks/Icon'
import { ButtonPrimaryOutline } from '/features/buildingBlocks/Button'

import styles from './Tooltip.css'

import infoIcon from '/images/icons/infoTooltip.raw.svg'
import markIcon from '/images/icons/mark.raw.svg'

export function Tooltip({ tip, buttonLink = undefined, layoutClassName = undefined }) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div tabIndex={0} onClick={(e) =>  e.stopPropagation()} className={cx(styles.component, layoutClassName)}>
      <button type='button' onClick={handleClick} className={styles.button}>
        <Icon icon={infoIcon} layoutClassName={styles.iconLayout} />
      </button>
      <TooltipInfo onClose={() => setIsOpen(false)} {...{ tip, isOpen, buttonLink }} />
    </div>
  )

  function handleClick() {
    setIsOpen(!isOpen)
  }
}

function TooltipInfo({ tip, onClose, isOpen, buttonLink }) {
  React.useEffect(() => {
    const ESC = 27

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }

    function handleKeyPress(event) {
      if (event.keyCode === ESC) onClose()
    }
  }, [onClose])

  return (
    <TransitionGroup component={React.Fragment}>
      {isOpen && (
        <CSSTransition
          timeout={parseInt(styles.transitionSpeed, 10)}
          classNames={{
            enter: styles.componentInfoEnter,
            enterDone: styles.componentInfoEnterDone,
            exit: styles.componentInfoExit,
            exitActive: styles.componentInfoExitActive,
          }}
        >
          <div className={styles.infoContainer}>
            <button type='button' onClick={onClose} className={styles.closeButton}>
              <Icon icon={markIcon} layoutClassName={styles.iconLayout} />
            </button>

            <p className={styles.infoText}>{tip.text}</p>

            {tip.button && buttonLink && (
              <ButtonPrimaryOutline onClick={() => navigate(`${buttonLink}`)} layoutClassName={styles.buttonLayout}>
                {tip.button.text}
              </ButtonPrimaryOutline>
            )}
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
