import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ButtonIcon.css'

export function ButtonIcon({ label, icon, onClick, disabled = false, layoutClassName = undefined }) {
  return (
    <button type='button' className={cx(styles.component, layoutClassName)} {...{ onClick, disabled }}>
      <span>{label}</span>
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
    </button>
  )
}
