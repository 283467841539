import { images } from '/images'
import chartIcon from '/images/icons/chart.raw.svg'
import humanIcon from '/images/icons/human.raw.svg'
import { Icon } from '/features/buildingBlocks/Icon'

export const tool = {
  pageNavigation: {
    buttonText: {
      nl: 'Menu',
      en: 'Menu',
      se: 'Meny',
      de: 'Menü'
    },
    buttonToOtherTool: {
      title: {
        nl: 'Ga naar het tools overzicht',
        en: 'Go to tools overview',
      },
      link: 'tools',
    },
    requestAccess: {
      nl: 'Vraag toegang aan voordat je verder kunt gaan',
      en: 'Request access before you can proceed',
      se: '',
      de: ''
    },
    signup: {
      nl: 'Meld aan',
      en: 'Sign up',
    },
    pages: [
      {
        title: {
          nl: 'Over de tool',
          en: 'About the tool',
          se: 'Om prognosverktyget',
          de: 'Über das Risikovorhersage-Tool'
        },
        section: 'welkom',
      },
      {
        title: {
          nl: 'Basisgegevens invoeren',
          en: 'Entering basic patient data',
          se: 'Föra in bakgrundsuppgifter',
          de: 'Stammdaten eingeben'
        },
        section: 'basis',
      },
      {
        title: {
          nl: 'Biomarkers toevoegen',
          en: 'Adding biomarker results',
          se: 'Lägga till biomarkörer',
          de: 'Biomarker hinzufügen'
        },
        section: 'aanvullend',
      },
      {
        title: {
          nl: 'Uitslagpagina patiënt',
          en: 'Patient result page',
          se: 'Resultatsida patient',
          de: 'Befundseite Patient'
        },
        section: 'resultaat',
      }
    ],
  },
  resultaat: {
    title: {
      nl: 'Uitslag patiënt',
      en: 'Patient result page',
      se: 'Resultat patient',
      de: 'Ergebnis Patient'
    },
    situation: {
      title: {
        nl: 'Uw situatie',
        en: 'Your situation',
        se: 'Din situation',
        de: 'Ihre Situation'
      },
      additionalTest: {
        yes: {
          nl: (
            <>
              <p>Bij u is sprake van milde cognitieve klachten. Deze diagnose 'MCI' betekent ook dat u een verhoogde kans heeft om in de toekomst dementie te ontwikkelen.</p>
              <p>Bij u zijn aanvullende diagnostische tests uitgevoerd. Deze tests tonen aan of er in uw hersenen al sprake is van Alzheimer-schade. Het is mogelijk om Alzheimer-schade te hebben, zonder dat er sprake is van dementie. Maar deze schade geeft wel een verhoogde kans op dementie in de komende jaren.</p>
            </>
          ),
          en: (
            <>
              <p>You have been diagnosed with mild cognitive impairment (MCI). That means you are at increased risk of developing dementia in the future.</p>
              <p>You have had some additional diagnostic tests. The results show whether your brain already has suffered damage from Alzheimer’s disease. However, it is possible to have Alzheimer's disease damage without having dementia. Nevertheless, such damage does increase the risk of developing dementia in the years ahead.</p>
            </>
          ),
          se: (
            <>
              <p>Du har milda kognitiva besvär. Denna diagnos kallas MCI och innebär att du har en förhöjd risk för att utveckla demens i framtiden.</p>
              <p>Du har genomgått kompletterande diagnostiska test. Dessa test visar om det finns tecken på Alzheimers sjukdom i din hjärna. Det är möjligt att ha tecken på Alzheimers sjukdom utan att demens har uppstått. Dessa tecken innebär dock en förhöjd risk för demens under kommande år.</p>
            </>
          ),
          de: (
            <>
              <p>Bei Ihnen liegt eine leichte kognitive Störung (MCI) vor. Diese Diagnose bedeutet auch, dass bei Ihnen eine erhöhte Wahrscheinlichkeit besteht, dass sich in der Zukunft eine Demenz entwickelt.</p>
              <p>Es wurden zusätzliche diagnostische Tests bei Ihnen durchgeführt. Mit diesen Untersuchungen wird festgestellt, ob Ihr Gehirn bereits durch die Alzheimer-Krankheit geschädigt ist. Es kann sein, dass eine Schädigung durch die Alzheimer-Krankheit vorliegt, ohne dass derzeit eine Demenz besteht. Die Schädigung durch die Alzheimer-Krankheit erhöht jedoch die Wahrscheinlichkeit, dass sich in den kommenden Jahren eine Demenz entwickelt.</p>
            </>
          )
        },
        no: {
          nl: <><p>Bij u zijn geen aanvullende diagnostische tests uitgevoerd.</p></>,
          en: <><p>You have had no additional diagnostic tests.</p></>,
          se: <><p>Du har inte genomgått kompletterande diagnostiska test.</p></>,
          de: <><p>Es wurden keine zusätzlichen diagnostischen Tests mit Ihnen durchgeführt.</p></>,
        }
      },
    },
    ruggenprik: {
      title: {
        nl: 'Ruggenprik',
        en: 'Lumbar puncture',
        se: 'Ryggvätskeprov',
        de: 'Lumbalpunktion'
      },
      content: {
        nl: (
          <>
            <p>Met de ruggenprik (lumbaalpunctie) tonen we aan of er aanwijzingen zijn voor de aanwezigheid van de Alzheimer-eiwitten in de hersenen.</p>
            <p>Er zijn 2 soorten eiwitten: amyloid en p-tau.</p>
          </>
        ),
        en: (
          <>
            <p>A lumbar puncture (spinal tap) lets us find out whether Alzheimer proteins are present in the brain.</p>
            <p>We test for two protein types: amyloid and p-tau.</p>
          </>
        ),
        se: (
          <>
            <p>Med ryggvätskeprovet (lumbalpunktion) kan vi se om det finns tecken på förekomst av Alzheimerproteiner i hjärnan.</p>
            <p>Det finns två typer av proteiner: amyloid och p-tau.</p>
          </>
        ),
        de: (
          <>
            <p>Mit der Lumbalpunktion wird festgestellt, ob die Alzheimer-Eiweiße im Gehirn vorhanden sind.</p>
            <p>Es gibt zwei Arten von Eiweißen: Amyloid und p-Tau.</p>
          </>
        )
      },
      items: [
        {
          title: {
            nl: 'Duidelijk geen Alzheimer-eiwitten aanwezig',
            en: 'Definitely no Alzheimer proteins present',
            se: 'Definitivt ingen förekomst av Alzheimerproteiner',
            de: 'Eindeutig keine Alzheimer-Eiweiße vorhanden.'
          },
          description: {
            nl: 'Hieruit maken we op dat er geen Alzheimer-eiwitten aanwezig zijn in de hersenen. Dit betekent dat u een lage kans heeft om de komende jaren dementie te ontwikkelen.',
            en: 'The test results show that there are definitely no Alzheimer proteins in your brain. That means that you are at low risk of developing dementia in the next few years.',
            se: 'Resultatet visar att det inte finns några Alzheimerproteiner i hjärnan. Det innebär att sannolikheten är låg att du utvecklar demens under de kommande åren.',
            de: 'Daraus schließen wir, dass keine Alzheimer-Eiweiße im Gehirn vorhanden sind. Das bedeutet, dass bei Ihnen die Wahrscheinlichkeit gering ist, in den kommenden Jahren an Demenz zu erkranken.'
          },
        },
        {
          title: {
            nl: 'Niet duidelijk of er Alzheimer-eiwitten aanwezig zijn',
            en: 'Unclear whether Alzheimer proteins are present',
            se: 'Inte tydligt om det förekommer Alzheimerproteiner',
            de: 'Nicht eindeutig feststellbar, ob Alzheimer-Eiweiße vorhanden sind.'
          },
          description: {
            nl: 'Er is geen overtuigende aanwezigheid van Alzheimer-eiwitten. De kans om de komende jaren dementie te ontwikkelen verandert door deze informatie nauwelijks.',
            en: 'There is no strong evidence that Alzheimer proteins are present in your brain. That observation has no significant implications for your risk of developing dementia in the next few years.',
            se: 'Det går inte tydligt att se om det förekommer Alzheimerproteiner. Sannolikheten för att utveckla demens under de kommande åren förändras knappt av denna information.',
            de: 'Ob Alzheimer-Eiweiße vorhanden sind, ist nicht eindeutig festzustellen. Diese Informationen haben kaum Einfluss auf die Frage, mit welcher Wahrscheinlichkeit sich in den kommenden Jahren eine Demenz entwickelt.'
          },
        },
        {
          title: {
            nl: 'Duidelijk wel Alzheimer-eiwitten aanwezig',
            en: 'Alzheimer proteins definitely present',
            se: 'Tydlig förekomst av Alzheimerproteiner',
            de: 'Alzheimer-Eiweiße sind eindeutig vorhanden.'
          },
          description: {
            nl: 'Hieruit maken we op dat er Alzheimer-eiwitten aanwezig zijn in de hersenen. Dit betekent dat u een hogere kans heeft om de komende jaren dementie te ontwikkelen.',
            en: 'The test results show that Alzheimer proteins are definitely present in your brain. That means that you are at increased risk of developing dementia in the next few years.',
            se: 'Resultatet visar att det förekommer Alzheimerproteiner i hjärnan. Det innebär en större sannolikhet för att du utvecklar demens under de kommande åren.',
            de: 'Daraus schließen wir, dass Alzheimer-Eiweiße im Gehirn vorhanden sind. Das bedeutet, dass bei Ihnen eine erhöhte Wahrscheinlichkeit besteht, in den kommenden Jahren an Demenz zu erkranken.'
          },
        },
      ],
    },
    mri: {
      title: {
        nl: 'MRI',
        en: 'MRI',
        se: 'MR',
        de: 'MRT'
      },
      image: {
        alt: {
          nl: 'Gezonde hersenen',
          en: 'A healthy brain',
          se: 'Frisk hjärna',
          de: 'Gesunden Gehirn'
        },
        src: images['gezondeHersenen'],
      },
      content: {
        nl: <p>Een MRI is een foto van uw hersenen. Hier ziet u een voorbeeld van een MRI van gezonde hersenen. Op een MRI kunnen we zien of er sprake is van hersenkrimp (atrofie).</p>,
        en: <p>An MRI is an image of your brain. The picture below is an MRI of a healthy brain. An MRI shows whether your brain has 'shrunk'. (Doctors call brain shrinkage 'atrophy'.)</p>,
        se: <p>En MR-bild är ett foto av din hjärna. Här är ett exempel på en MR-bild av en frisk hjärna. På en MR-bild kan man se om hjärnan har minskat i storlek (atrofi).</p>,
        de: <p>Ein MRT-Bild ist ein Abbild Ihres Gehirns. Hier sehen Sie als Beispiel ein MRT von einem gesunden Gehirn. Auf einem MRT-Bild kann man sehen, ob eine Gehirnschrumpfung (Atrophie) vorliegt.</p>
      },
      items: [
        {
          title: {
            nl: 'Duidelijk geen krimp',
            en: 'Clearly no atrophy',
            se: 'Det syns tydligt att ingen minskning i storlek förekommer',
            de: 'Eindeutig keine Gehirnschrumpfung.'
          },
          description: {
            nl: 'In uw geval zijn er geen aanwijzingen voor hersenkrimp. Hierdoor heeft u een iets lagere kans om de komende jaren dementie te ontwikkelen.',
            en: 'In your case, there is no sign that your brain has shrunk. That means that your risk of developing dementia in the next few years is a little lower.',
            se: 'I ditt fall finns inga tecken på att hjärnan har minskat i storlek. Det innebär att sannolikheten är lite lägre för att du ska utveckla demens under de kommande åren.',
            de: 'In Ihrem Fall gibt es keine Anzeichen für eine Gehirnschrumpfung. Dadurch ist bei Ihnen die Wahrscheinlichkeit etwas geringer, dass Sie in den kommenden Jahren an Demenz erkranken.'
          },
        },
        {
          title: {
            nl: 'Niet duidelijk of er krimp is',
            en: 'Unclear whether atrophy has occurred',
            se: 'Inte tydligt om det förekommer storleksförändringar.',
            de: 'Nicht eindeutig feststellbar, ob eine Gehirnschrumpfung vorliegt.'
          },
          description: {
            nl: 'In uw geval is er mogelijk sprake van hersenkrimp. De kans om de komende jaren dementie te ontwikkelen verandert door deze informatie nauwelijks.',
            en: 'In your case, there is a possibility that your brain may have shrunk. That observation has no significant implications for your risk of developing dementia in the next few years.',
            se: 'I ditt fall förekommer det möjligen en minskning i storlek av hjärnan. Sannolikheten för att utveckla demens under de kommande åren förändras knappt av denna information.',
            de: 'Bei Ihnen besteht die Möglichkeit, dass eine Gehirnschrumpfung vorliegt. Diese Informationen haben kaum Einfluss auf die Frage, mit welcher Wahrscheinlichkeit sich in den kommenden Jahren eine Demenz entwickelt.'
          },
        },
        {
          title: {
            nl: 'Duidelijk wel krimp (atrofie)',
            en: 'Clear atrophy',
            se: 'Tydlig krympning (atrofi)',
            de: 'Eine Gehirnschrumpfung (Atrophie) ist eindeutig feststellbar.'
          },
          description: {
            nl: 'In uw geval is er sprake van hersenkrimp. Hierdoor heeft u een iets hogere kans om de komende jaren dementie te ontwikkelen.',
            en: 'In your case, it’s clear that your brain has indeed shrunk. That means that you are at increased risk of developing dementia in the next few years.',
            se: 'I ditt fall förekommer det en minskning i storlek av hjärnan. Det innebär att sannolikheten är lite högre för att du ska utveckla demens under de kommande åren.',
            de: 'In Ihrem Fall ist eine Gehirnschrumpfung feststellbar. Dadurch ist bei Ihnen die Wahrscheinlichkeit etwas größer, dass Sie in den kommenden Jahren an Demenz erkranken.'
          },
        },
      ],
    },
    pet: {
      title: {
        nl: 'Amyloid PET',
        en: 'Amyloid PET',
        se: 'Amyloid-PET',
        de: 'Amyloid-PET'
      },
      content: {
        nl: <p>Met de amyloid PET tonen we aan of er aanwijzingen zijn voor de aanwezigheid van het Alzheimer eiwit amyloid in de hersenen.</p>,
        en: <p>An amyloid PET scan visualizes whether the Alzheimer protein amyloid is present in the brain.</p>,
        se: <p>Med amyloid-PET kan vi se om det finns tecken på förekomst av Alzheimerproteinet amyloid i hjärnan.</p>,
        de: <p>Mit Hilfe eines Amyloid-PET stellen wir fest, ob es Anzeichen dafür gibt, dass das Alzheimer-Eiweiß Amyloid im Gehirn vorhanden ist.'</p>
      },
      items: [
        {
          title: {
            nl: 'Amyloid niet aanwezig',
            en: 'No amyloid present',
            se: 'Ingen förekomst av amyloid',
            de: 'Amyloid nicht vorhanden'
          },
          description: {
            nl: 'Hieruit maken we op dat het Alzheimer eiwit amyloid niet aanwezig is in de hersenen. Dit betekent dat u een lage kans heeft om de komende jaren dementie te ontwikkelen.',
            en: 'The Alzheimer protein amyloid is not present in your brain. That means that you are at low risk of developing dementia in the next few years.',
            se: 'Resultatet visar att Alzheimerproteinet amyloid inte förekommer i hjärnan. Det innebär att sannolikheten är liten att du utvecklar demens under de kommande åren.',
            de: 'Daraus schließen wir, dass das Alzheimer-Eiweiß Amyloid nicht im Gehirn vorhanden ist. Das bedeutet, dass bei Ihnen die Wahrscheinlichkeit gering ist, in den kommenden Jahren an Demenz zu erkranken.'
          },
        },
        {
          title: {
            nl: 'Amyloid aanwezig',
            en: 'Amyloid present',
            se: 'Förekomst av amyloid',
            de: 'Amyloid vorhanden'
          },
          description: {
            nl: 'Hieruit maken we op dat het Alzheimer eiwit amyloid aanwezig is in de hersenen. Dit betekent dat u een hogere kans heeft om de komende jaren dementie te ontwikkelen.',
            en: 'The Alzheimer protein amyloid is present in your brain. That means that you are at increased risk of developing dementia in the next few years.',
            se: 'Resultatet visar att Alzheimerproteinet amyloid förekommer i hjärnan. Det innebär en större sannolikhet för att du utvecklar demens under de kommande åren.',
            de: 'Daraus schließen wir, dass das Alzheimer-Eiweiß Amyloid im Gehirn vorhanden ist. Das bedeutet, dass bei Ihnen die Wahrscheinlichkeit größer ist, in den kommenden Jahren an Demenz zu erkranken.'
          },
        },
      ],
    },
    contentEditable: {
      title: {
        nl: 'Wat betekent dit voor u de komende tijd?',
        en: 'What does this mean for you in the near future?',
        se: 'Vad innebär detta för dig den närmaste tiden?',
        de: 'Was bedeutet dies für Sie in der kommenden Zeit?'
      },
      text: {
        nl: (gender, amount1Year, amount3Years, amount5Years) =>
          `Om een uitspraak te kunnen doen over de kans dat uw klachten zich ontwikkelen tot dementie, vergelijken we uw situatie met andere ${gender === 'male' ? 'mannelijke' : 'vrouwelijke'} patiënten in onze kliniek, met dezelfde leeftijd en resultaten op diagnostische tests als u. \n
Van die ${gender === 'male' ? 'mannen' : 'vrouwen'} in dezelfde situatie als u, ontwikkelen er ${amount1Year} van de 100 binnen nu en 1 jaar dementie. \n
Als we een langere periode bekijken, dan ontwikkelen ${amount3Years} van de 100 ${gender === 'male' ? 'mannen' : 'vrouwen'} in dezelfde situatie als u binnen nu en 3 jaar  dementie. \n
${amount5Years ? `Op nog langere termijn, dan ontwikkelen ${amount5Years} van de 100 ${gender === 'male' ? 'mannen' : 'vrouwen'} in dezelfde situatie als u binnen nu en 5 jaar dementie.` : ''}`,
        en: (gender, amount1Year, amount3Years, amount5Years) =>
          `In order to estimate the risk of your symptoms developing into dementia, we compare you with our other ${gender === 'male' ? 'male' : 'female'} patients of your age with similar diagnostic test results.\n
Out of a hundred ${gender === 'male' ? 'men' : 'women'} whose situation is similar to yours, ${amount1Year} will develop dementia within a year.\n
Within three years, ${amount3Years} out of a hundred ${gender === 'male' ? 'men' : 'women'} in the same situation as you will develop dementia.\n
${amount5Years ? `Within five years, ${amount5Years} out of a hundred ${gender === 'male' ? 'men' : 'women'} in the same situation as you will develop dementia.` : ''}`,
        se: (gender, amount1Year, amount3Years, amount5Years) =>
          `För att kunna säga något om sannolikheten för att dina besvär kommer att utvecklas till demens jämför vi din situation med andra ${gender === 'male' ? 'manliga' : 'kvinnliga'} patienter på vår mottagning som är i samma ålder och har samma resultat från de diagnostiska testen som du. \n
Av ${gender === 'male' ? 'männen' : 'kvinnorna'} som har samma resultat på de diagnostiska testen som du har, har ${amount1Year} av 100 utvecklat demens inom 1 år. \n
Om vi tittar på längre sikt har ${amount3Years} av 100 ${gender === 'male' ? 'män' : 'kvinnor'} som har samma resultat på de diagnostiska testen som du har, utvecklat demens inom 3 år. \n
${amount5Years ? `På ännu längre sikt har ${amount5Years} av 100 ${gender === 'male' ? 'män' : 'kvinnor'} som har samma resultat på de diagnostiska testen som du har, utvecklat demens inom 5 år.` : ''}`,
        de: (gender, amount1Year, amount3Years, amount5Years) =>
          `Um eine Aussage zu der Risikowahrscheinlichkeit treffen zu können, dass sich bei Ihnen eine Demenz entwickelt, vergleichen wir Ihre Situation mit der von anderen gleichaltrigen ${gender === 'male' ? 'männlichen Patienten' : 'weiblichen Patientinnen'}, die bei den diagnostischen Tests vergleichbare Ergebnisse hatten.\n
Von 100 ${gender === 'male' ? 'Männern' : 'Frauen'}, die sich in der gleichen Situation befinden wie Sie, tritt bei ${amount1Year} Personen innerhalb eines Jahres eine Demenz auf.\n
Betrachtet man einen längeren Zeitraum von 3 Jahren, so entwickelt sich bei ${amount3Years} von 100 ${gender === 'male' ? 'Männern' : 'Frauen'}, die in der gleichen Situation sind wie Sie, eine Demenz.\n
${amount5Years ? `Auf noch längere Sicht innerhalb der nächsten fünf Jahre, entwickelt sich bei ${amount5Years} von 100 ${gender === 'male' ? 'Männern' : 'Frauen'}, die in der gleichen Situation sind wie Sie, eine Demenz.` : ''}`,
      },
      buttonText: {
        nl: 'Aanvullen of aanpassen',
        en: 'Complete or adjust',
        se: 'Komplettera eller anpassa',
        de: 'Ergänzen oder anpassen'
      },
    },
    situationVisualization: {
      intro: {
        nl: (gender, has5YearPrognosis) =>
          `Hieronder ziet u een visuele weergave van de kans op het ontwikkelen van dementie voor 100 ${gender === 'male' ? 'mannen' : 'vrouwen'} van uw leeftijd, met dezelfde resultaten op de diagnostische tests, binnen ${has5YearPrognosis ? `nu en 1 jaar, 3 jaar en 5 jaar` : `1 jaar en 3 jaar`}.`,
        en: (gender, has5YearPrognosis) =>
          `Below you find a visual representation of the risk of developing dementia for 100 ${gender === 'male' ? 'men' : 'women'} of your age, with similar test results, within ${has5YearPrognosis ? `1 year, 3 years or 5 years` : `1 year or 3 years`}.`,
        se: (gender, has5YearPrognosis) =>
          `Nedan ser du en visuell presentation av sannolikheten för att utveckla demens för 100 ${gender === 'male' ? 'män' : 'kvinnor'} i din ålder, med samma resultat på diagnostiska test, inom ${has5YearPrognosis ? `1 år, 3 år och 5 år` : `1 år och 3 år`}.`,
        de: (gender, has5YearPrognosis) =>
          `Nachstehend wird visuell dargestellt, wie hoch die Wahrscheinlichkeit ist, dass bei 100 gleichaltrigen ${gender === 'male' ? 'Männern' : 'Frauen'}, die bei den diagnostischen Tests  vergleichbare Ergebnisse hatten, innerhalb eines Zeitraums von ${has5YearPrognosis ? `1 Jahr, von 3 Jahren und von 5 Jahren` : `1 Jahr und von 3 Jahren`} eine Demenz auftritt.`,
      },
      tabs: [
        {
          id: 'icons',
          title: {
            nl: 'Icoontjes',
            en: 'Icons',
            se: 'Ikoner',
            de: 'Piktogramme'
          },
          IconComponent: <Icon icon={humanIcon} />
        },
        {
          id: 'bars',
          title: {
            nl: 'Staafdiagrammen',
            en: 'Bars',
            se: 'Stapeldiagram',
            de: 'Balkendiagramme'
          },
          IconComponent: <Icon icon={chartIcon} />
        },
      ],
      visualization: {
        year: {
          nl: (number) => (`${number} jaar`),
          en: (number) => (`${number} ${number > 1 ? 'years' : 'year'}`),
          se: (number) => (`${number} år`),
          de: (number) => (`${number} ${number > 1 ? 'Jahre' : 'Jahr'}`),
        },
        legend: {
          nl: (number, amountOfYears, develops) => `${number} mensen ontwikkelen binnen nu en ${amountOfYears} ${develops ? '' : 'geen'} dementie.`,
          en: (number, amountOfYears, develops) => `${number} people will ${develops ? '' : 'not'} develop dementia within ${amountOfYears}.`,
          se: (number, amountOfYears, develops) => `${number} personer utvecklade ${develops ? '' : 'ingen'} demens inom ${amountOfYears}.`,
          de: (number, amountOfYears, develops) => `${number} Menschen erkranken innerhalb von ${amountOfYears} ${develops ? '' : 'nicht'} an Demenz.`
        },
      },
    },
  },
  basisgegevens: {
    title: {
      nl: 'Basisgegevens',
      en: 'Basic information',
      se: 'Bakgrundsuppgifter',
      de: 'Stammdaten'
    },
    form: {
      gender: {
        legend: {
          nl: 'Mijn patiënt is een',
          en: 'My patient is',
          se: 'Min patient är en',
          de: 'Mein Patient ist'
        },
        male: {
          nl: 'Man',
          en: 'Male',
          se: 'Man',
          de: 'ein Mann'
        },
        female: {
          nl: 'Vrouw',
          en: 'Female',
          se: 'Kvinna',
          de: 'eine Frau'
        },
      },
      age: {
        label: {
          nl: 'Leeftijd',
          en: 'Age',
          se: 'Ålder',
          de: 'Alter'
        },
      },
      score: {
        label: {
          nl: 'MMSE score',
          en: 'MMSE score',
          se: 'MMSE-poäng',
          de: 'MMSE-Score'
        },
      },
    },
    firstParagraph: {
      nl: 'Kans op dementie zonder biomarkers:',
      en: 'Risk of developing dementia without biomarkers:',
      se: 'Sannolikhet för demens utan biomarkörer:',
      de: 'Risikowahrscheinlichkeit für Demenz ohne Biomarker:'
    },
    tooltip: {
      text: {
        nl: 'Deze kansen zijn berekend op basis van rekenmodellen die ontwikkeld zijn door het Alzheimercentrum Amsterdam. Deze schatting is gebaseerd op de leeftijd, het geslacht en de MMSE score van uw patiënt. U kunt (optioneel) biomarker resultaten toevoegen. Dit zorgt voor een betere betrouwbaarheid van de schatting van het risico op dementie voor uw patiënt. Meer informatie over de rekenmodellen en de generaliseerbaarheid vindt u hier',
        en: 'These risks are calculated based on models that are developed by the Alzheimer center Amsterdam. This estimate is based on the age, the sex and the MMSE score of your patient. You may choose to (optionally) add biomarker results. This results in a more reliable estimate of your patient’s risk of dementia. More information on the models and their generalizability can be found here',
        se: 'Denna sannolikhet beräknas med beräkningsmodeller som utvecklats av Alzheimercentrum Amsterdam. Denna uppskattning grundas på patientens ålder, kön och MMSE-poäng. Du kan (om du vill) tillföra resultat för biomarkörer. Det ökar tillförlitligheten av uppskattningen av sannolikheten för att patienten utvecklar demens. Mer information om beräkningsmodellerna och generaliserbarheten finns här',
        de: 'Diese Risikowahrscheinlichkeiten wurden anhand von Rechenmodellen ermittelt, die vom Alzheimerzentrum Amsterdam entwickelt worden sind. Diese Schätzung basiert auf dem Alter, dem Geschlecht und dem MMSE-Score Ihres Patienten. Sie können (optional) Biomarker-Ergebnisse hinzufügen. Daraus ergibt sich eine höhere Zuverlässigkeit bei der Einschätzung des Demenzrisikos Ihres Patienten. Weitere Einzelheiten zu den Rechenmodellen und der Generalisierbarkeit finden Sie hier.'
      },
      button: {
        text: {
          nl: 'Meer info',
          en: 'More info',
          se: 'Mer info',
          de: 'Weitere Informationen'
        },
        link: '/informatie/publicaties'
      },
    },
  },
  aanvullendegegevens: {
    title: {
      nl: 'Aanvullende gegevens',
      en: 'Additional test results',
      se: 'Kompletterande uppgifter',
      de: 'Zusätzliche Angaben'
    },
    form: {
      legend: {
        nl: 'Welke biomarkers wilt u toevoegen?',
        en: 'Which biomarkers do you want to add?',
        se: 'Vilka biomarkörer vill du lägga till?',
        de: 'Welche Biomarker sollen hinzugefügt werden?'
      },
      mriType: {
        label: {
          nl: 'MRI',
          en: 'MRI',
          se: 'MR',
          de: 'MRT'
        },
        legend: {
          nl: 'Beoordeling MRI',
          en: 'MRI rating',
          se: 'Bedömning MR',
          de: 'Beurteilung MRT'
        },
        visueel: {
          nl: 'Visueel',
          en: 'Visual',
          se: 'Visuell',
          de: 'Visuell'
        },
        volumetrisch: {
          nl: 'Volumetrisch',
          en: 'Volumetric',
          se: 'Volymetrisk',
          de: 'Volumetrisch'
        },
      },
      eiwitMethode: {
        label: {
          nl: 'Eiwitwaarden',
          en: 'CSF',
          se: 'Proteinvärden',
          de: 'Eiweißwerte'
        },
        legend: {
          nl: 'Eiwitwaarden in de liquor',
          en: 'Protein values in CSF',
          se: 'Proteinvärden i cerebrospinalvätskan',
          de: 'Eiweißwerte im Nervenwasser'
        },
        elecsys: {
          nl: 'Met Elecsys',
          en: 'With Elecsys',
          se: 'Med Elecsys',
          de: 'Mit Elecsys'
        },
        innotest: {
          nl: 'Met Innotest',
          en: 'With Innotest',
          se: 'Med Innotest',
          de: 'Mit Innotest'
        },
        luminex: {
          nl: 'Met Luminex',
          en: 'With Luminex',
          se: 'Med Luminex',
          de: 'Mit Luminex'
        },
      },
      petMethode: {
        label: {
          nl: 'Amyloid-PET',
          en: 'Amyloid-PET',
          se: 'Amyloid-PET',
          de: 'Amyloid-PET'
        },
        legend: {
          nl: 'Amyloid-PET',
          en: 'Amyloid-PET',
          se: 'Amyloid-PET',
          de: 'Amyloid-PET'
        },
        positive: {
          nl: 'Positief',
          en: 'Positive',
          se: 'Positiv',
          de: 'Positiv'
        },
        negative: {
          nl: 'Negatief',
          en: 'Negative',
          se: 'Negativ',
          de: 'Negativ'
        },
        warning: {
          nl: <>
            <p>
              <strong>Let op:</strong> de modellen die rekenen met amyloid PET gegevens behoeven verdere validatie. Wanneer er eiwitwaarden in hersenvocht beschikbaar zijn, adviseren wij u deze te gebruiken.
            </p>
            <p>
              Het amyloid PET model kan gebruikt worden onder de volgende voorwaarden:<br />
              - Leeftijdsrange: 55 - 85 jaar<br />
              - De combinatie met MRI is alleen mogelijk met volumetrische beoordeling
            </p>
          </>,
          en: <>
            <p>
              <strong>Note:</strong> the amyloid PET models deserve further validation. When CSF values are available, we strongly recommend using these models.
            </p>
            <p>
              The amyloid PET model can be used under the following conditions:<br />
              - Age range: 55 - 85 years<br />
              - The combination with MRI is only possible with volumetric rating
            </p>
          </>,
          se: <>
            <p>
              <strong>Obs!</strong> Modellerna som räknar med data från amyloid-PET kräver ytterligare validering. Om det finns tillgängliga värden för proteiner i cerebrospinalvätskan rekommenderar vi att de används.
            </p>
            <p>
              Amyloid-PET-modellen kan användas i följande fall:<br />
              - Åldersintervall: 55–85 år<br />
              - Kombinationen med MR är endast möjlig med volymetrisk bedömning
            </p>
          </>,
          de: <>
            <p>
              <strong>Hinweis:</strong> Die auf Amyloid-PET-Daten basierenden Modelle bedürfen einer weiteren Validierung. Wenn die Liquor-Biomarker verfügbar sind, empfehlen wir Ihnen, diese zu verwenden.
            </p>
            <p>
              Das Amyloid-PET-Modell kann unter den folgenden Bedingungen verwendet werden:<br />
              - Altersgruppe: 55 bis 85 Jahre<br />
              - Die Kombination mit einem MRT ist nur zusammen mit einer volumetrischen Beurteilung möglich.
            </p>
          </>
        },
      },
      volumetrischMethode: {
        legend: {
          nl: 'Volumetrisch methode',
          en: 'Volumetric method',
          se: 'Volymetrisk metod',
          de: 'Volumetrische Methode'
        },
        fslFirst: 'FSL FIRST',
        freesurfer: 'FREESURFER',
      },
      mta: {
        labelLeft: {
          nl: 'MTA links',
          en: 'MTA left',
          se: 'MTA vänster',
          de: 'MTA links'
        },
        labelRight: {
          nl: 'MTA rechts',
          en: 'MTA right',
          se: 'MTA höger',
          de: 'MTA rechts'
        }
      },
      mmse: {
        label: {
          nl: 'MMSE score',
          en: 'MMSE score',
          se: 'MMSE-poäng',
          de: 'MMSE-Score'
        }
      },
      hcv: {
        label: 'HCV',
        labelAddition: {
          nl: 'Vul hier de som van links en rechts in',
          en: 'Enter the sum of left and right here',
          se: 'Ange här summan av vänster och höger',
          de: 'Hier den Gesamtwert von links und rechts ausfüllen'
        },
      },
      abeta: {
        label: 'Abeta',
      },
      pTau: {
        label: 'P-Tau',
      },
    },
    withBiomarkers: {
      text: {
        nl: 'Kans op dementie met biomarkers:',
        en: 'Risk of developing dementia with biomarkers:',
        se: 'Sannolikhet för demens med biomarkörer:',
        de: 'Demenzrisiko mit Biomarkern:'
      },
      tooltip: {
        text: {
          nl: 'Deze kansen zijn berekend op basis van het rekenmodel dat ontwikkeld is door het Alzheimercentrum Amsterdam. Meer informatie over de rekenmodellen en de generaliseerbaarheid vindt u hier',
          en: 'These risks are calculated based on models that are developed by the Alzheimer center Amsterdam. More information on the models and their generalizability can be found here',
          se: 'Denna sannolikhet beräknas med beräkningsmodellen som utvecklats av Alzheimercentrum Amsterdam. Mer information om beräkningsmodellerna och generaliserbarheten finns här',
          de: 'Diese Risikowahrscheinlichkeiten wurden anhand von Rechenmodellen ermittelt, die vom Alzheimerzentrum Amsterdam entwickelt worden sind. Weitere Einzelheiten zu den Rechenmodellen und der Generalisierbarkeit finden Sie hier.'
        },
        button: {
          text: {
            nl: 'Meer info',
            en: 'More info',
            se: 'Mer info',
            de: 'Weitere Informationen'
          },
          link: '/informatie/publicaties'
        },
      },
    },
    withoutBiomarkers: {
      text: {
        nl: 'Kans op dementie zonder biomarkers:',
        en: 'Risk of developing dementia without biomarkers:',
        se: 'Sannolikhet för demens utan biomarkörer:',
        de: 'Demenzrisiko ohne Biomarker:'
      },
      tooltip: {
        text: {
          nl: 'Deze kansen zijn berekend op basis van rekenmodellen die ontwikkeld zijn door het Alzheimercentrum Amsterdam. Deze schatting is gebaseerd op de leeftijd, het geslacht en de MMSE score van uw patiënt. U kunt (optioneel) biomarker resultaten toevoegen. Dit zorgt voor een betere betrouwbaardheid van de schatting van het risico op dementie voor uw patiënt. Meer informatie over de rekenmodellen en de generaliseerbaarheid vindt u hier',
          en: 'These risks are calculated based on models that are developed by the Alzheimer center Amsterdam. This estimate is based on the age, the sex and the MMSE score of your patient. You may choose to (optionally) add biomarker results. This results in a more reliable estimate of your patients’ risk on dementia. More information on the models and their generalizability can be found here',
          se: 'Denna sannolikhet beräknas med beräkningsmodeller som utvecklats av Alzheimercentrum Amsterdam. Denna uppskattning grundas på patientens ålder, kön och MMSE-poäng. Du kan (om du vill) tillföra resultat för biomarkörer. Det ökar tillförlitligheten av uppskattningen av sannolikheten för att patienten utvecklar demens. Mer information om beräkningsmodellerna och generaliserbarheten finns här',
          de: 'Diese Risikowahrscheinlichkeiten wurden anhand von Rechenmodellen ermittelt, die vom Alzheimerzentrum Amsterdam entwickelt worden sind. Diese Schätzung basiert auf dem Alter, dem Geschlecht und dem MMSE-Score Ihres Patienten. Sie können (optional) Biomarker-Ergebnisse hinzufügen. Daraus ergibt sich eine höhere Zuverlässigkeit bei der Einschätzung des Demenzrisikos Ihres Patienten. Weitere Einzelheiten zu den Rechenmodellen und der Generalisierbarkeit finden Sie hier.'
        },
        button: {
          text: {
            nl: 'Meer info',
            en: 'More info',
            se: 'Mer info',
            de: 'Weitere Informationen'
          },
          link: '/informatie/publicaties'
        },
      },
    },
  },
  error: {
    nl: 'Er is iets mis gegaan. Probeer het opnieuw.',
    en: 'Something went wrong. Please try again.',
    se: 'Något gick fel. Försök igen senare.',
    de: 'Es ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.'
  },
  circularCharts: {
    '1year': {
      label: {
        nl: 'Nu - 1 jaar',
        en: 'Now - 1 year',
        se: 'Inom 1 år',
        de: 'Heute - 1 Jahr'
      },
    },
    '3year': {
      label: {
        nl: 'Nu - 3 jaar',
        en: 'Now - 3 year',
        se: 'Inom 3 år',
        de: 'Heute - 3 Jahre'
      },
    },
    '5year': {
      label: {
        nl: 'Nu - 5 jaar',
        en: 'Now - 5 year',
        se: 'Inom 5 år',
        de: 'Heute - 5 Jahre'
      },
    },
  },
  buttons: {
    basis: {
      resultButton: {
        text: {
          nl: 'Naar uitslagpagina patiënt',
          en: 'To patient result page',
          se: 'Till resultatsida patient',
          de: 'Zur Ergebnisseite Patient'
        },
      },
      resetButton: {
        text: {
          nl: 'Begin opnieuw',
          en: 'Start Again',
          se: 'Börja om',
          de: 'Neue Eingabe'
        },
      },
      addBiomarkersButton: {
        text: {
          nl: 'Biomarkers toevoegen',
          en: 'Add biomarkers',
          se: 'Lägga till biomarkörer',
          de: 'Biomarker hinzufügen'
        },
      },
    },
    aanvullend: {
      resetButton: {
        text: {
          nl: 'Begin opnieuw',
          en: 'Start Again',
          se: 'Börja om',
          de: 'Neue Eingabe'
        },
      },
      resultButton: {
        text: {
          nl: 'Naar uitslagpagina patiënt',
          en: 'To patient result page',
          se: 'Till resultatsida patient',
          de: 'Zur Ergebnisseite Patient'
        },
      },
    },
    resultaat: {
      resetButton: {
        text: {
          nl: 'Begin opnieuw',
          en: 'Start Again',
          se: 'Börja om',
          de: 'Neue Eingabe'
        },
      },
      printButton: {
        text: {
          nl: 'Print pagina',
          en: 'Print page',
          se: 'Skriv ut sida',
          de: 'Seite drucken'
        },
      },
    },
  },
}
