import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Content } from '/features/buildingBlocks/Content'

import styles from './OrderedAccordion.css'

export function OrderedAccordion({ items }) {
  return (
    <ol className={styles.component}>
      {items.map((item, i) => (
        <OrderedAccordionItem key={i} open={!i} {...{ item }} />
      ))}
    </ol>
  )
}

function OrderedAccordionItem({ item, open }) {
  const [isExpanded, setIsExpanded] = React.useState(open)
  const { title, content } = item

  return (
    <li className={cx(styles.componentItem, isExpanded && styles.isExpanded)}>
      <OrderedAccordionButton onClick={handleClick} {...{ title }} />
      <TransitionGroup component={React.Fragment}>
        {isExpanded && (
          <CSSTransition
            timeout={parseInt(styles.transitionSpeed, 10)}
            classNames={{
              enter: styles.orderedAccordionContentEnter,
              enterDone: styles.orderedAccordionContentEnterDone,
              exit: styles.orderedAccordionContentExit,
              exitActive: styles.orderedAccordionContentExitActive,
            }}
          >
            <div className={styles.contentContainer}>
              <Content {...{ content }} />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </li>
  )

  function handleClick() {
    setIsExpanded(!isExpanded)
  }
}

function OrderedAccordionButton({ title, onClick }) {
  return (
    <button type='button' className={styles.componentButton} {...{ onClick }}>
      {title}
    </button>
  )
}
