import { Content } from '/features/buildingBlocks/Content'

import styles from './Section.css'

export function Section({ id, content }) {
  return (
    <section className={styles.component} {...{ id }}>
      <Content {...{ content }} />
    </section>
  )
}
