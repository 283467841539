import { MobileMenu } from '/features/pageOnly/MobileMenu'

import styles from './PageHeader.css'

export function PageHeader({ menuItems, formMenuItems = undefined, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <MobileMenu layoutClassName={styles.navigationLayout} {...{ menuItems, formMenuItems }} />
    </div>
  )
}

export function PageHeaderWithImage({ menuItems, image, title, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentWithImage, layoutClassName)}>
      <img src={image} alt='' className={styles.bannerImage} />

      <div className={styles.container}>
        <MobileMenu layoutClassName={styles.navigationLayout} {...{ menuItems }} />
        <h2 className={styles.title}>{title}</h2>
      </div>
    </div>
  )
}
