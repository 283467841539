import styles from './Footer.css'
import { Icon } from '/features/buildingBlocks/Icon'
import { useI18n } from '/machinery/useI18n'

export function Footer() {
  const i18n = useI18n()
  const { text, logos } = i18n('footer')

  return (
    <footer role="contentinfo" className={styles.component}>
      {text}
      {logos.map((logo, i) => <Icon key={i} icon={logo} />)}
    </footer>
  )
}
