import { navigate } from '@reach/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { reportError } from '/machinery/reportError'
import { useFirebaseUser } from '/machinery/firebase'
import { useI18n } from '/machinery/useI18n'
import { useDebouncedValue } from '/machinery/useDebouncedValue'

import { mciFieldInfo, adFieldInfo, validateAd, validateMci } from '/api/medicationTool/fieldInfo'

import { PageLayoutTools } from '/features/pageOnly/PageLayout'
import { Paragraph } from '/features/buildingBlocks/Paragraph'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { Content } from '/features/buildingBlocks/Content'
import { MedicatieToolSections } from '/features/pageOnly/medicationTool/MedicatieToolSections'

import styles from './MedicatieTool.css'

export const models = {
  mci: {
    fieldInfo: mciFieldInfo,
    validations: validateMci
  },
  ad: {
    fieldInfo: adFieldInfo,
    validations: validateAd
  },
  default: {
    fieldInfo: mciFieldInfo,
    validations: validateMci
  },
}

export function MedicatieTool({ loginError }) {
  const queryClient = new QueryClient()
  const user = useFirebaseUser({ reportError })

  const i18n = useI18n()
  const { pages, buttonToOtherTool, requestAccess, signup } = i18n('pageNavigation')
  const content = i18n('content')

  const [currentSection, setCurrentSection] = React.useState('welkom')
  const [patientInformation, setPatientInformation] = React.useState({ model: 'default', mmseOfInterest: '', csfAb42: '', csfPtau: '', age: '', apoeDose: '', medicineEffect: 30, mmseAtBaseline: ''  })

  const debouncedPatientInformation = useDebouncedValue(patientInformation, 300)
  const validationErrors = models[patientInformation.model].validations(debouncedPatientInformation)
  const formIsValid = validationErrors.length === 0

  const currentPageTitle = pages.find(x => x.section === currentSection)?.title
  const loggedIn = user && !user.isAnonymous

  const formMenuItems = pages.map(({ section, title }) => ({
    title,
    onClick() { setCurrentSection(section) },
    isActive: section === currentSection,
    disabled: {
      welkom: false,
      basis: !loggedIn,
      resultaat: !formIsValid || !loggedIn,
    }[section],
  }))

  React.useEffect(
    () => { window.scrollTo(0, 0) },
    [currentSection]
  )

  return (
    <QueryClientProvider client={queryClient}>
      <PageLayoutTools tag={i18n('tag')} {...{ formMenuItems, currentPageTitle, buttonToOtherTool }}>
        {currentSection === 'welkom'
          ? loggedIn
            ? (
              <Content {...{ content }} />
            ) : (
              <div className={styles.contentContainer}>
                <Paragraph text={requestAccess} />
                <ButtonPrimary onClick={() => navigate('tools')} layoutClassName={styles.buttonLayout}>{signup}</ButtonPrimary>
              </div>
            )
          : (
            <MedicatieToolSections
              section={currentSection}
              onSectionChange={handleSectionChange}
              onPatientInformationChange={handlePatientInformationChange}
              {...{ patientInformation, user, validationErrors, loggedIn, loginError }}
            />
          )}
      </PageLayoutTools>
    </QueryClientProvider>
  )

  function handleSectionChange(x) {
    setCurrentSection(x)
  }

  function handlePatientInformationChange(x) {
    setPatientInformation(x)
  }
}
