import { getPointAdjustedForZoom } from '/features/pageOnly/medicationTool/machinery/getPointAdjustedForZoom'

import styles from './Axis.css'

export function AxisJustLabels({ width, height, xLabel, yLabel }) {
  return <AxisBase {...{ width, height, xLabel, yLabel }} />
}

export function AxisXY({ width, height, xMax, xStep, xZoom, xLabel, yMax, yStep, yZoom, yLabel }) {
  return <AxisBase {...{ width, height, xMax, xStep, xZoom, yMax, yStep, yZoom, xLabel, yLabel }} />
}

export function AxisX({ width, height, xMax, xStep, xZoom, xLabel }) {
  return <AxisBase {...{ width, height, xMax, xStep, xZoom, xLabel }} />
}

function AxisBase({
  width,
  height,
  xMax = undefined,
  xStep = undefined,
  xZoom = undefined,
  xLabel = undefined,
  yMax = undefined,
  yStep = undefined,
  yZoom = undefined,
  yLabel = undefined
}) {
  const xStepCount = xMax / xStep + 1
  const yStepCount = yMax / yStep + 1

  const yAxisHasValues = yMax && yStep && yZoom
  const xAxisHasValues = xMax && xStep && xZoom

  return (
    <g>
      <line y1={0} y2={height} className={styles.axisStroke} />
      <line x1={0} x2={width} y1={height} y2={height} className={styles.axisStroke} />

      {yLabel && (
        <text x={0} y={yAxisHasValues ? -2 : -1} textAnchor='end' transform='rotate(-90)' className={styles.axisTitle}>
          {yLabel}
        </text>
      )}

      {xLabel && (
        <text x={width} y={xAxisHasValues ? height + 3 : height + 1} fontSize='16px' textAnchor='end' className={styles.axisTitle}>
          {xLabel}
        </text>
      )}

      {yAxisHasValues && (
        <g data-y-axis='true'>
          {Array.from({ length: yStepCount }).map((_, i) => {
            const step = getPointAdjustedForZoom(yZoom, i * yStep)
            const label = yMax - (i * yStep)

            return (
              <text key={i} x={-0.75} y={step} textAnchor='middle' className={styles.axisLabel}>
                {label}
              </text>
            )
          })}
        </g>
      )}

      {xAxisHasValues && (
        <g data-x-axis='true'>
          {Array.from({ length: xStepCount }).map((_, i) => {
            const step = getPointAdjustedForZoom(xZoom, i * xStep)
            const label = i * xStep

            return (
              <text key={i} x={step} y={height + 1} textAnchor='middle' className={styles.axisLabel}>
                {label}
              </text>
            )
          })}
        </g>
      )}
    </g>
  )
}
