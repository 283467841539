import { Match } from '@reach/router'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useI18n } from '/machinery/useI18n'

import { PageHeader, PageHeaderWithImage } from '/features/pageOnly/PageHeader'
import { DesktopMenu, DesktopMenuTools } from '/features/pageOnly/DesktopMenu'

import mediaStyles from '/cssGlobal/media.css'
import styles from './PageLayout.css'

import { images } from '/images'

export function PageLayoutInformation({ children }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  const i18n = useI18n()
  const menuItems = i18n('pageNavigation').pages

  const pageLookup = i18n('pageNavigation').pages.reduce(
    (result, x) => ({ ...result, [x.link]: x.i18nKey }),
    {}
  )

  return (
    <Match path=':section'>{({ match }) => {
      const currentPage = i18n(pageLookup[(match && match.section) || '']) || { intro: '', title: '', subtitle: '' }
      const { title, subtitle, intro, bannerImage } = currentPage
      const headerImage = bannerImage || images['previewAlzheimercentrum']

      return (
        <PageLayoutContainer>
          {headerImage
            ? <PageHeaderWithImage layoutClassName={styles.headerLayout} image={headerImage} {...{ menuItems, title }} />
            : !isViewportLg && <PageHeader layoutClassName={styles.headerLayout} {...{ menuItems }} />
          }

          {isViewportLg && <DesktopMenu layoutClassName={styles.desktopMenuLayout} {...{ menuItems }} />}

          <PageLayoutContent
            hasHeaderImage={Boolean(headerImage)}
            layoutClassName={styles.contentLayout}
            {...{ title, subtitle, intro, menuItems, children }}
          />
        </PageLayoutContainer>
      )
    }}
    </Match>
  )
}

export function PageLayoutTools({
  currentPageTitle,
  children,
  menuItems = [],
  tag = undefined,
  formMenuItems = undefined,
  buttonToOtherTool = undefined
}) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  return (
    <PageLayoutContainer>
      {isViewportLg
        ? <DesktopMenuTools layoutClassName={styles.desktopMenuLayout} {...{ menuItems, formMenuItems, buttonToOtherTool }} />
        : <PageHeader layoutClassName={styles.headerLayout} {...{ menuItems, formMenuItems }} />
      }

      <PageLayoutContent title={currentPageTitle} layoutClassName={styles.contentLayout} {...{ menuItems, tag, children }} />
    </PageLayoutContainer>
  )
}

function PageLayoutContainer({ children }) {
  return (
    <div className={styles.componentContainer}>
      {children}
    </div>
  )
}

function PageLayoutContent({
  title,
  children,
  tag = undefined,
  intro = undefined,
  subtitle = undefined,
  hasHeaderImage = false,
  layoutClassName = undefined
}) {
  return (
    <article className={cx(styles.componentContent, layoutClassName)}>
      {tag && <p className={styles.tag}>{tag}</p>}
      {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
      {title && !hasHeaderImage && <h1 className={styles.title}>{title}</h1>}
      {intro && <p className={styles.intro} dangerouslySetInnerHTML={{ __html: intro }} />}
      {children}
    </article>
  )
}
