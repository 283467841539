import { Icon } from './Icon'

import styles from './AlertBox.css'

import markIcon from '/images/icons/mark.raw.svg'

export function AlertBox({ children, canBeDismissed = false }) {
  const [isActive, setIsActive] = React.useState(true)

  return (isActive &&
    <div className={cx(styles.component, canBeDismissed && styles.canBeDismissed)}>
      {canBeDismissed && <DismissButton onClick={handleClick} layoutClassName={styles.dismissButtonLayout} />}

      {children}
    </div>
  )

  function handleClick() {
    setIsActive(!isActive)
  }
}

function DismissButton({ onClick, layoutClassName = undefined }) {
  return (
    <button type='button' data-x='click-to-dismiss' className={cx(styles.componentDismissButton, layoutClassName)} {...{ onClick }}>
      <Icon icon={markIcon} layoutClassName={styles.iconLayout} />
    </button>
  )
}
