import { navigate } from '@reach/router'

import { MenuItem, MenuItemTool } from '/features/pageOnly/MenuItem'
import { ButtonSecondaryOutline } from '/features/buildingBlocks/Button'

import styles from './DesktopMenu.css'

export function DesktopMenu({ menuItems, layoutClassName = undefined }) {
  return (
    <nav className={cx(styles.component, layoutClassName)}>
      <div className={styles.list}>
        {menuItems.map(({ link, title }, i) => <MenuItem key={i} {...{ link, title }} />)}
      </div>
    </nav>
  )
}

export function DesktopMenuTools({ menuItems, formMenuItems, buttonToOtherTool, layoutClassName = undefined }) {
  return (
    <nav className={cx(styles.componentTools, layoutClassName)}>
      <div className={styles.list}>
        {menuItems?.map(({ link, title }, i) => <MenuItem key={i} {...{ link, title }} />)}

        {formMenuItems?.map(({ title, onClick, isActive, disabled }, i) => (
          <MenuItemTool key={i} {...{ title, onClick, isActive, disabled }} />
        ))}
      </div>

      {buttonToOtherTool && (
        <ButtonSecondaryOutline onClick={() => navigate(buttonToOtherTool.link)} >
          {buttonToOtherTool?.title}
        </ButtonSecondaryOutline>
      )}
    </nav>
  )
}
