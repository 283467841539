import { Content, contentType } from '/features/buildingBlocks/Content'

import styles from './BulletList.css'

export function BulletList({ content, title = undefined }) {
  if (title) {
    return (
      <div className={styles.component}>
        {title && <div className={styles.title}>{title}</div>}
        <BulletListBase {...{ content }} />
      </div>
    )
  }

  return <BulletListBase {...{ content }} />
}

function BulletListBase({ content }) {
  return (
    <ul className={styles.componentBase}>
      {content.map((contentItem, i) => {
        if (contentItem.type === contentType.BULLETLIST) {
          return <Content key={i} content={[contentItem]} />
        } else return (
          <li key={i} className={styles.listItem}>
            <Content content={[contentItem]} />
          </li>
        )
      })}
    </ul>
  )
}
