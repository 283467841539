import { isNumber } from '/machinery/isNumber'
import { reportError } from '/machinery/reportError'
import { useFirebaseUser } from '/machinery/firebase'
import { useI18n } from '/machinery/useI18n'
import { useIsMountedRef } from '/machinery/useIsMountedRef'

import { ButtonTertiary, ButtonPrimary } from '/features/buildingBlocks/Button'
import { Result } from '/features/pageOnly/predictionTool/Result'
import { Basisgegevens } from '/features/pageOnly/predictionTool/Basisgegevens'
import { AanvullendeGegevens } from '/features/pageOnly/predictionTool/AanvullendeGegevens'

import styles from './Calculator.css'

const defaultBasisGegevens = {
  gender: null,
  age: '',
  mmse: '',
}

const defaultAanvullendeGegevens = {
  hcv: '',
  mtaLeft: '',
  mtaRight: '',
  hcvMethod: '',
  csfMethod: '',
  abeta: '',
  pTau: '',
  mriType: '',
  pet: '',
  testSoort: [],
}

export function Calculator({ fieldInfo: fieldInfoWithModels, section, onSectionChange, basisResults, onBasisResultsChange }) {
  const user = useFirebaseUser({ reportError })
  const i18n = useI18n()
  const buttons = i18n('buttons')
  const nextStepEnabled = section === 'aanvullend' || !!basisResults

  const [aanvullendeGegevens, setAanvullendeGegevens] = React.useState(defaultAanvullendeGegevens)
  const [aanvullendeResults, setAanvullendeResults] = React.useState(null)
  const [basisGegevens, setBasisData] = React.useState(defaultBasisGegevens)
  const [error, setError] = React.useState(null)

  const isMountedRef = useIsMountedRef()

  const body = React.useMemo(
    () => getModelAndTypeAndData(section, basisGegevens, aanvullendeGegevens),
    [section, basisGegevens, aanvullendeGegevens]
  )

  const safeCalculate = React.useCallback(calculate, [user, section])
  const { model, type, data } = body
  const fieldInfo = model && extractModelFieldInfo(model, fieldInfoWithModels)
  const hasResult = !!(section === 'aanvullend' ? aanvullendeResults : basisResults)
  const invalid = !user || !type || !data || !model || hasEmptyValues(data) || !valuesInRange(model, data, fieldInfo)

  const lastCalculationCallRef = React.useRef(null)

  React.useEffect(
    () => {
      safeCalculate({ reset: invalid, body })
    },
    [safeCalculate, body, invalid]
  )

  if (error) return <div>{i18n('error')}</div>

  return (
    <div className={styles.component}>
      {section === 'basis' && (
        <Basisgegevens value={basisGegevens} onChange={handleBasisDataChange} {...{ fieldInfo, basisResults }} />
      )}

      {section === 'aanvullend' && (
        <AanvullendeGegevens
          basisInRange={model && valuesInRange(
            model,
            basisGegevens,
            extractModelFieldInfo(model, fieldInfoWithModels)
          )}
          value={aanvullendeGegevens}
          onChange={x => setAanvullendeGegevens(x)}
          {...{ fieldInfo, basisResults, aanvullendeResults }}
        />
      )}

      {section === 'resultaat' && (
        <Result gender={basisGegevens.gender} results={aanvullendeResults || basisResults} {...{ aanvullendeGegevens }} />
      )}

      <div className={styles.actionsContainer}>
        <ButtonTertiary onClick={_ => resetAndGoToBasisSection()}>
          {buttons[section].resetButton.text}
        </ButtonTertiary>

        {section === 'basis' && (
          <ButtonPrimary onClick={_ => onSectionChange('aanvullend')} disabled={!nextStepEnabled || invalid}>
            {buttons[section].addBiomarkersButton.text}
          </ButtonPrimary>
        )}

        {section === 'aanvullend' && (
          <ButtonPrimary onClick={_ => onSectionChange('resultaat')} disabled={!hasResult || invalid}>
            {buttons[section].resultButton.text}
          </ButtonPrimary>
        )}

        {section === 'resultaat' && (
          <ButtonPrimary onClick={_ => window.print()} >
            {buttons[section].printButton.text}
          </ButtonPrimary>
        )}
      </div>

      {section === 'basis' &&
        <div className={styles.actionsContainer}>
          <ResultsButton
            label={buttons[section].resultButton.text}
            onClick={() => onSectionChange('resultaat')}
            disabled={invalid || !hasResult}
          />
        </div>
      }
    </div>
  )

  function handleBasisDataChange(x) {
    setBasisData(x)
  }

  function resetAndGoToBasisSection() {
    setBasisData(defaultBasisGegevens)
    setAanvullendeGegevens(defaultAanvullendeGegevens)
    onBasisResultsChange(null)
    setAanvullendeResults(null)
    onSectionChange('basis')
  }

  async function calculate({ reset, body }) {
    try {
      if (reset) {
        lastCalculationCallRef.current = null
        if (section === 'basis') onBasisResultsChange(null)
        if (section === 'aanvullend') setAanvullendeResults(null)
      } else {
        const call = callApi(user, body)
        lastCalculationCallRef.current = call

        const results = await call

        if (lastCalculationCallRef.current !== call) return
        if (!isMountedRef.current) return
        if (section === 'basis') onBasisResultsChange(results)
        if (section === 'aanvullend') setAanvullendeResults(results)
      }
    } catch (e) {
      reportError(e)
      setError(e)
    }
  }
}

function ResultsButton({ label, onClick, disabled }) {
  return (
    <button type='button' className={styles.componentResultsButton} {...{ onClick, disabled }}>
      {label}
    </button>
  )
}

function getModelAndTypeAndData(section, basisGegevens, aanvullendeGegevens) {
  const { age, gender, mmse } = basisGegevens
  const {
    testSoort, mriType, mtaLeft, mtaRight, hcv, abeta, pTau, hcvMethod, csfMethod, pet
  } = aanvullendeGegevens

  const [model, type, data] = (
    section === 'basis' ?
      ['defaultModel', 'demographic', { age, gender, mmse }] :

      section === 'aanvullend' ? (

        testSoort.includes('mri') && testSoort.includes('eiwit') ? (
          mriType === 'visueel' ?
            ['defaultModel', 'atn_mta', { csfMethod,  abeta, pTau, mtaLeft, mtaRight, age, mmse }] :

            mriType === 'volumetrisch' ?
              ['defaultModel', 'atn_hcv', { hcvMethod, csfMethod, hcv, abeta, pTau, age, mmse } ] :

              ['defaultModel']
        ) :

          testSoort.includes('mri') && testSoort.includes('pet') ? (
            mriType === 'volumetrisch' ?
              ['petModel', 'pet_hcv', { hcvMethod, hcv, age, mmse, gender, pet }] :

              ['petModel']
          ) :

            testSoort.includes('mri') ? (
              mriType === 'visueel' ?
                ['defaultModel', 'mta', { mtaLeft, mtaRight, gender, mmse }] :

                mriType === 'volumetrisch' ?
                  ['defaultModel', 'hcv', { hcvMethod, hcv, age, mmse }] :

                  ['defaultModel']
            ) :

              testSoort.includes('eiwit') ? (
                ['defaultModel', 'csf', { csfMethod, abeta, pTau, mmse }]
              ) :

                testSoort.includes('pet') ? (
                  ['petModel', 'pet', { pet, mmse }]
                ) :
                  []
      ) :
        []
  )

  return { model, type, data }
}

async function callApi(user, body) {
  const token = await user.getIdToken()

  return fetch('/calculator', {
    method: 'POST',
    headers: {
      'X-Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body)
  }).then(x => x.json())
}

function hasEmptyValues(object) {
  return Object.values(object).some(x => !x && typeof x !== 'number')
}

function valuesInRange(model, data, fieldInfo) {
  const {
    mta, age, mmse,
    hcvMethods: {
      [data.hcvMethod]: { hcv } = { hcv: '' },
    },
    csfMethods: {
      [data.csfMethod]: { abeta, pTau } = { abeta: '', pTau: '' },
    }
  } = fieldInfo

  const checks = {
    // all models
    age: checkRange(age),
    mmse: checkRange(mmse),
    hcv: checkRange(hcv),
    ...(model === 'defaultModel' && {
      mtaLeft: checkRange(mta),
      mtaRight: checkRange(mta),
      abeta: checkRange(abeta),
      pTau: checkRange(pTau),
    }),
  }

  return Object.entries(data).reduce(
    (result, [field, value]) => {
      const check = checks[field]
      return result && (!check || check(value))
    },
    true
  )
}

function checkRange([min, max] = []) {
  return x => isNumber(x) && x >= min && x <= max
}

function extractModelFieldInfo(method, value) {
  return (
    (Array.isArray(value) && value) ||
    value[method] ||
    mapValues(value, x => extractModelFieldInfo(method, x))
  )
}

function mapValues(o, f) {
  return Object.entries(o).reduce(
    (result, [k, v]) => ({ ...result, [k]: f(v) }),
    {}
  )
}
