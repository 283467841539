import styles from './CircularChart.css'

export function CircularChart({ item, percentage, color }) {
  return (
    <div className={styles.component}>
      <svg viewBox='0 0 36 36' className={cx(styles.chart, styles[color])}>
        <path className={styles.circleBg}
          d='M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <path className={styles.circle}
          strokeDasharray={`${percentage === null ? '0 100' : `${percentage} 100`}`}
          d='M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <text x='18' y='20.35' className={cx(styles.percentage, styles[color])}>
          {`${percentage === null ? '?' : `${round(percentage)}`}`}%
        </text>
      </svg>
      <div className={cx(styles.label, styles[color])}>{item}</div>
    </div>
  )
}

function round(x) { return Math.round(x * 10) / 10 }
