import { Tooltip } from '/features/buildingBlocks/Tooltip'

import styles from './Legend.css'

export function Legend({ children }) {
  return (
    <ul className={styles.component}>
      {children}
    </ul>
  )
}

export function LegendItem({ label, path, pathStyle, strokeWidth = 1, strokeDasharray = 0, tooltip = undefined }) {
  return (
    <li className={styles.componentItem}>
      <LegendItemPath {...{ path, strokeWidth, strokeDasharray, pathStyle }} />
      {label}
      {tooltip && <Tooltip tip={tooltip} />}
    </li>
  )
}

function LegendItemPath({ path, strokeWidth, strokeDasharray, pathStyle }) {
  return (
    <svg className={pathStyle} width='18px' height='18px' viewBox={`0 0 6 6`}>
      <path d={path} strokeDashoffset={0} {...{ strokeWidth, strokeDasharray }} />
    </svg>
  )
}
