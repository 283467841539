import { Link as LinkBase, Match, navigate } from '@reach/router'

import { useI18n } from '/machinery/useI18n'

import { TabMenuPrimary } from '/features/buildingBlocks/TabMenu'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Menu.css'

import arrowDownIcon from '/images/icons/arrowDown.raw.svg'

export default function Menu() {
  const i18n = useI18n()
  const language = useI18n().language
  const tabs = React.useMemo(() => i18n('headerMenu').tabs, [i18n])

  return (
    <header role="banner" className={styles.component}>
      <LinkBase to={`/${language}/informatie`} className={styles.logoHomeLink}>ADappt</LinkBase>
      <>
        <Match path=':section/*'>{props =>
          <TabMenuPrimary
            selectedTab={tabs.find(tab => tab.link === (props.match)?.section || tab.subPages?.includes(props.match.section))}
            onSelectedTabChange={x => navigate(`/${language}/${x.link}`)}
            layoutClassName={styles.tabMenuLayout}
            {...{ tabs }}
          />
        }
        </Match>
        <Match path='*'>
          {props => (
            <div>
              <LanguageSelector
                selectedLanguage={language}
                onLanguageChange={e => navigate(`/${e.target.value}/${props.match['*']}`)}
              />
            </div>
          )}
        </Match>
      </>
    </header>
  )
}

function LanguageSelector({ selectedLanguage, onLanguageChange }) {
  return (
    <div className={styles.componentLanguageSelector}>
      <select
        value={selectedLanguage}
        onChange={e => onLanguageChange(e)}
        className={styles.select}
      >
        <option value='nl' className={styles.option}>NL</option>
        <option value='en' className={styles.option}>EN</option>
      </select>

      <div className={styles.iconContainer}>
        <Icon icon={arrowDownIcon} layoutClassName={styles.iconLayout} />
      </div>
    </div>
  )
}
