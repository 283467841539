import { tool } from './i18n_tool'
import { requestAccessElements } from './i18n_requestAccess'

import { contentType } from '/features/buildingBlocks/Content'

import { Icon } from '/features/buildingBlocks/Icon'

import chartIcon from '/images/icons/chart.raw.svg'
import infoIcon from '/images/icons/info.raw.svg'

import diagnostischeTesten1 from '/images/Diagnostische_testen_1.jpg'
import diagnostischeTesten2 from '/images/Diagnostische_testen_2.jpg'
import diagnostischeTesten3 from '/images/Diagnostische_testen_3.jpg'
import diagnostischeTesten4 from '/images/Diagnostische_testen_4.jpg'
import rekentool1 from '/images/rekentool_1.png'
import overAdappt from '/images/over_adappt1.png'
import overAdapptEn from '/images/aboardLogo_en.png'
import rekentool2 from '/images/rekentool_2.png'
import lineGraphNL from '/images/lineGraph_nl.png'
import lineGraphEN from '/images/lineGraph_en.png'
import effectsNL from '/images/effects_nl.png'
import effectsEN from '/images/effects_en.png'

import wiesje from '/images/people/Wiesje.png'
import pieter from '/images/people/Pieter.jpg'
import leonie from '/images/people/Leonie.jpg'
import heleen from '/images/people/Heleen.jpg'

import deEersteAfspraakNL from '/files/de_eerste_afspraak_NED.mp4'
import deEersteAfspraakEN from '/files/de_eerste_afspraak_ENG.mp4'
import uitslagGesprekNL from '/files/Het_uitslaggesprek_NED.mp4'
import uitslagGesprekEN from '/files/Het_uitslaggesprek_ENG.mp4'
import ruggenprikNL from '/files/Ruggenprik_NED.mp4'
import ruggenprikEN from '/files/Ruggenprik_ENG.mp4'
import treatmentNL from '/files/treatment_nl.mp4'
import treatmentEN from '/files/treatment_en.mp4'


import aboardLogo from '/images/icons/aboard_logo.raw.svg'
import alzheimerCentrumLogo from '/images/icons/Alzheimercentrum.raw.svg'

import gespreksWijzerNL from '/files/Gesprekswijzer_NED.pdf'
import gespreksWijzerEN from '/files/Gesprekswijzer_ENG.pdf'

const globalElements = getGlobalElements()

export const i18n = {
  informatie: {
    pageNavigation: {
      buttonText: {
        nl: 'Menu',
        en: 'Menu',
        se: 'Meny',
        de: 'Menü'
      },
      pages: [
        {
          title: {
            nl: 'Introductie',
            en: 'Introduction',
            se: '',
            de: ''
          },
          link: '',
          i18nKey: 'introductie',
        },
        {
          title: {
            nl: 'Diagnose',
            en: 'Diagnosis',
            se: '',
            de: ''
          },
          link: 'topiclijst',
          i18nKey: 'topiclijst',
        },
        {
          title: {
            nl: 'Diagnostische testen',
            en: 'Diagnostic tests',
            se: '',
            de: ''
          },
          link: 'diagnostischetesten',
          i18nKey: 'diagnostischeTesten',
        },
        {
          title: {
            nl: 'Behandeling',
            en: 'Treatment',
            se: '',
            de: ''
          },
          link: 'behandeling',
          i18nKey: 'behandeling',
        },
        {
          title: {
            nl: 'Samen beslissen',
            en: 'Shared decision-making',
            se: '',
            de: ''
          },
          link: 'diagnostiek',
          i18nKey: 'diagnostiek',
        },
        {
          title: {
            nl: 'Rekentools',
            en: 'Calculation tools',
            se: '',
            de: ''
          },
          link: 'kansberekenen',
          i18nKey: 'kansBerekenen',
        },
        {
          title: {
            nl: 'Veelgestelde vragen',
            en: 'Frequently asked questions',
            se: '',
            de: ''
          },
          link: 'veelgesteldevragen',
          i18nKey: 'veelgesteldeVragen',
        },
        {
          title: {
            nl: 'Meedoen aan onderzoek',
            en: 'Participate in research',
            se: '',
            de: ''
          },
          link: 'meedoenaanonderzoek',
          i18nKey: 'meedoenAanOnderzoek',
        },
        {
          title: {
            nl: 'Over ADappt',
            en: 'About ADappt',
            se: 'Om ADappt',
            de: 'Über ADappt'
          },
          link: 'overons',
          i18nKey: 'overOns',
        },
        {
          title: {
            nl: 'Contact',
            en: 'Contact',
            se: '',
            de: ''
          },
          link: 'contact',
          i18nKey: 'contact',
        },
      ],
    },
    contact: {
      title: {
        nl: 'Contact',
        en: 'Contact',
        se: 'Kontakt',
        de: 'Kontakt'
      },
      intro: {
        nl: "De ADappt rekentools zijn momenteel beschikbaar voor inzage voor zorgverleners. <a href='/nl/predictie_tool'>Hier</a> kunt u inloggegevens aanvragen. Heeft u vragen over ADappt? Vult u dan onderstaand contactformulier in.",
        en: "The ADappt risk calculation tools are currently only available for viewing by healthcare providers. Would you like to try out the tools? <a href='/en/predictie_tool'>Here</a> you can request login credentials. Do you have questions concerning the ADappt website? You can reach out to us using the contact form below.",
        se: '',
        de: '',
      },
      formSent: {
        nl: 'Bedankt voor uw bericht, wij zullen dit zo spoedig mogelijk in behandeling nemen.',
        en: 'Thank you for your message, we will handle this as soon as possible.',
        se: 'Tack för ditt meddelande, vi kommer att behandla det så snabbt som möjligt.',
        de: 'Danke für Ihre Nachricht; Sie erhalten so bald wie möglich eine Antwort von uns.'
      },
      form: {
        serverError: {
          nl: 'Er is iets mis gegaan, probeer het later opnieuw',
          en: 'Something went wrong, please try again later',
          se: 'Något gick fel, försök igen senare',
          de: 'Es ist ein Problem aufgetreten; bitte versuchen Sie es später noch einmal.'
        },
        name: {
          label: {
            nl: 'Naam',
            en: 'Name',
            se: 'Namn',
            de: 'Das ADappt-Risikovorhersage-Tool steht derzeit medizinischen Fachkräften und anderen interessierten Kreisen zur Ansicht zur Verfügung. Anmeldedaten können hier angefordert werden. Haben Sie Fragen zu ADappt? Füllen Sie das untenstehende Kontaktformular aus.'
          },
          placeholder: {
            nl: 'Jouw naam hier',
            en: 'Your name',
            se: 'Ditt namn här',
            de: 'Hier den Namen ausfüllen'
          },
        },
        email: {
          label: {
            nl: 'E-mail',
            en: 'Email',
            se: 'E-post',
            de: 'E-Mail'
          },
          placeholder: {
            nl: 'Jouw e-mailadres',
            en: 'Your email adress',
            se: 'Din e-postadress',
            de: 'Ihre E-Mail-Adresse'
          },
          validation: {
            nl: 'Voer alstublieft een geldig e-mailadres in',
            en: 'Please provide a valid email address',
            se: 'Ange en giltig e-postadress',
            de: 'Bitte geben Sie eine gültige E-Mail-Adresse an'
          },
        },
        message: {
          label: {
            nl: 'Vraag of bericht',
            en: 'Question or message',
            se: 'Fråga eller meddelande',
            de: 'Frage oder Nachricht'
          },
          placeholder: {
            nl: 'Je kan hier een bericht schrijven',
            en: 'You can type a message here',
            se: 'Här kan du skriva ett meddelande',
            de: 'Hier können Sie eine Nachricht schreiben'
          },
        },
        button: {
          text: {
            nl: 'Versturen',
            en: 'Send',
            se: 'Skicka',
            de: 'Abschicken'
          },
        },
      },
    },
    introductie: {
      title: {
        nl: 'Introductie',
        en: 'Introduction',
        se: '',
        de: ''
      },
      subtitle: {
        nl: 'Wat is ADappt?',
        en: 'What is ADappt?',
        se: '',
        de: ''
      },
      intro: {
        nl: 'ADappt is een website met informatie voor zorgverleners die werken op een geheugenpoli en voor mensen die een geheugenpoli bezoeken als patiënt of naaste. Het doel van ADappt is om zorgverleners, patiënten en naasten te helpen om betekenisvolle gesprekken te voeren over diagnose en behandeling van de ziekte van Alzheimer. Op ADappt staan ook enkele hulpmiddelen, oftewel \'tools\', om deze gesprekken te ondersteunen.',
        en: 'ADappt is a website for healthcare providers who work in memory clinics and for people who visit memory clinics, whether as a patient or a loved one. The goal of ADappt is to help healthcare providers, patients and their families enter into meaningful discussions about the diagnosis and treatment of Alzheimer\'s disease. ADappt also offers several tools that help support these discussions.',
        se: '',
        de: ''
      }
    },
    topiclijst: {
      title: {
        nl: 'Diagnose',
        en: 'Diagnosis',
        se: '',
        de: ''
      },
      subtitle: {
        nl: 'Wat kunnen patienten en naasten verwachten?',
        en: 'What can patients and their families expect?',
        se: '',
        de: ''
      },
      intro: {
        nl: 'Op de geheugenpoli wisselen zorgverleners, patiënten en naasten veel informatie uit. Twee belangrijke gesprekken rondom de diagnose zijn het eerste gesprek op de poli en het uitslaggesprek waarin testresultaten en de diagnose worden gedeeld. In twee handige video\'s leggen wij uit wat patiënten en naasten kunnen verwachten van hun eerste afspraak op de poli en het uitslaggesprek.',
        en: 'At the memory clinic, healthcare providers, patients and their families exchange a lot of information. Two important discussions dealing with the diagnosis are the first meeting at the clinic and the results meeting, where test results and the diagnosis are shared. We explain what patients and their families can expect at their first appointment at the clinic and at the results meeting in the following videos:',
        se: '',
        de: ''
      },
      content: [
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: 'De eerste afspraak',
            en: 'The first appointment',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.VIDEO,
          src: {
            nl: deEersteAfspraakNL,
            en: deEersteAfspraakEN,
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: 'Het uitslaggesprek',
            en: 'The results meeting',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.VIDEO,
          src: {
            nl: uitslagGesprekNL,
            en: uitslagGesprekEN,
            se: '',
            de: ''
          },
        },
        {
          type: contentType.ACCORDION,
          items: [
            {
              title: {
                nl: 'Hoe kunnen patiënten en naasten zich voorbereiden?',
                en: 'How can patients and their loved ones prepare?',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Voor patiënten en naasten ontwikkelden we een gesprekswijzer. Dat is een lijst met voorbeeldvragen die zij kunnen downloaden en meenemen naar hun afspraken op de poli. Dit helpt om de gesprekken met de arts of andere zorgverlener thuis al voor te bereiden en als geheugensteun. Er is ook ruimte om eigen vragen op te schrijven.',
                    en: 'We have created a conversation guide for patients and their families. This is a list of sample questions which people can download and take with them to their clinic appointments. Information in the guide helps patients to prepare for the discussion with their doctor and other healthcare provider. The guide works as a memory aid and there is space to write down other questions.',
                    se: '',
                    de: ''
                  }
                },
                {
                  type: contentType.BUTTONLINK,
                  targetBlank: true,
                  label: {
                    nl: 'Klik hier om de gesprekswijzer te downloaden',
                    en: 'Click here to download the conversation guide',
                    se: '',
                    de: ''
                  },
                  href: {
                    nl: gespreksWijzerNL,
                    en: gespreksWijzerEN,
                    se: '',
                    de: ''
                  },
                }
              ]
            },
            {
              title: {
                nl: 'Waarop zijn deze hulpmiddelen gebaseerd?',
                en: 'What are these tools based on?',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'De video\'s en de gesprekswijzer voor patiënten en naasten zijn ontwikkeld op basis van wetenschappelijk onderzoek waarin zorgverleners, patiënten en naasten werd gevraagd wat zij belangrijke informatie vinden om te bespreken op de geheugenpoli. In dat onderzoek kwamen 17 onderwerpen naar voren. Informatie over de topiclijst vindt u <a href="/nl/informatie/overons">hier</a>.',
                    en: 'The videos and conversation guide for patients and families are based on a study where healthcare providers, patients and their loved ones were asked what kind of information they find important to discuss at the memory clinic. That study identified 17 topics. Background information about this list of topics can be found <a href="/en/informatie/overons">here</a>.',
                    se: '',
                    de: ''
                  }
                },
              ]
            },
          ]
        },
      ]
    },
    diagnostischeTesten: {
      title: {
        nl: 'Diagnostische testen',
        en: 'Diagnostic tests',
        se: '',
        de: ''
      },
      intro: {
        nl: 'Diagnostische testen bieden meer inzicht in de symptomen en/ of in het onderliggende ziekteproces. Hier vindt u een overzicht van veelvoorkomende testen op de geheugenpoli met mogelijke voor- en nadelen van elke test. We hebben geprobeerd dit zo te verwoorden dat het voor (uw) patiënten en naasten begrijpelijk is.',
        en: 'Diagnostic tests shed light on symptoms and/ or the processes underlying a medical condition. The main tests available at the memory clinic and their pros and cons are described below. The information is intended to be understandable for (your) patients and their loved ones.',
        se: '',
        de: ''
      },
      content: [
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>In het algemeen</h2>',
            en: '<h2>In general</h2>',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<strong>Waarom:</strong> Diagnostische testen bieden meer kennis over symptomen en/of het onderliggende ziekteproces. Diagnostische tests vraag je aan omdat je wilt weten wat er aan de hand is.',
            en: "<strong>Why:</strong> Diagnostic tests provide information about symptoms and/ or the processes underlying a medical condition. Diagnostic tests are arranged when we want to know more about what's wrong.",
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PROSANDCONS,
          pros: {
            title: {
              nl: 'Voordelen',
              en: 'Pros',
              se: '',
              de: ''
            },
            content: [
              {
                type: contentType.BULLETLIST,
                title: {
                  nl: 'De uitslag van een test',
                  en: 'Test results can',
                  se: '',
                  de: ''
                },
                content: [
                  {
                    type: contentType.TEXT,
                    text: {
                      nl: 'kan helpen bij het inrichten van zorg en medicatie',
                      en: 'Help us decide what care and medication are appropriate (e.g. what special care is indicated)',
                      se: '',
                      de: ''
                    }
                  },
                  {
                    type: contentType.TEXT,
                    text: {
                      nl: 'kan gerust stellen',
                      en: 'Provide reassurance',
                      se: '',
                      de: ''
                    }
                  },
                  {
                    type: contentType.TEXT,
                    text: {
                      nl: 'kan een verklaring geven voor symptomen',
                      en: 'Explain symptoms',
                      se: '',
                      de: ''
                    }
                  },
                  {
                    type: contentType.TEXT,
                    text: {
                      nl: 'kan een inschatting van de prognose/het beloop geven',
                      en: 'Tell us more about the prognosis / how the patient\'s symptoms are likely to progress',
                      se: '',
                      de: ''
                    }
                  },
                  {
                    type: contentType.TEXT,
                    text: {
                      nl: 'biedt de kans om deel te nemen aan wetenschappelijk onderzoek.',
                      en: 'Enable participation in scientific research',
                      se: '',
                      de: ''
                    }
                  },
                ]
              }
            ]
          },
          cons: {
            title: {
              nl: 'Nadelen',
              en: 'Cons',
              se: '',
              de: ''
            },
            content: [
              {
                type: contentType.BULLETLIST,
                title: {
                  nl: 'Aanvullend onderzoek kan belastend zijn, bijvoorbeeld door extra ziekenhuisbezoeken. En, ondanks de uitslag van een test:',
                  en: 'Further testing can be a burden, for example, extra hospital visits may be needed. Also, even with the test results:',
                  se: '',
                  de: ''
                },
                content: [
                  {
                    type: contentType.TEXT,
                    text: {
                      nl: 'blijft er sprake van onzekerheid, zeker als er nog geen sprake is van dementie',
                      en: 'the picture may remain unclear, especially if there are no clear signs of dementia at the moment',
                      se: '',
                      de: ''
                    }
                  },
                  {
                    type: contentType.TEXT,
                    text: {
                      nl: 'is er nog geen behandeling die de ziekte stopt of geneest',
                      en: 'there is no cure or treatment for the underlying disease yet',
                      se: '',
                      de: ''
                    }
                  },
                ]
              }
            ]
          }
        },
        {
          type: contentType.ACCORDION,
          items: [
            {
              title: {
                nl: 'Neuropsychologisch onderzoek',
                en: 'Neuropsychological testing',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: '<strong>Waarom:</strong> Bij dementie gaat het denkvermogen achteruit. Met het neuropsychologisch onderzoek kijken we naar hoe het gaat met het denken, bijvoorbeeld het geheugen, de taal, het overzicht houden of het plannen en organiseren. Het neuropsychologisch onderzoek bestaat uit een serie testen met opdrachten of vragen. Sommige opdrachten/vragen zijn makkelijk, andere moeilijk. Er is niemand die alles goed heeft. We vragen altijd meer dan u kunt, zodat we precies weten wat uw niveau is. We vergelijken hierbij uw prestatie met iemand van uw leeftijd, geslacht en hetzelfde opleidingsniveau.',
                    en: "<strong>Why:</strong> In dementia, thinking ability declines. Neuropsychological tests provide information about aspects of thinking ability, such as memory, language, planning and organizing. Neuropsychological testing involves performing a series of tasks and answering questions. Some tasks and questions are easy, others aren't. No one gets everything right. To show what level you're at, the tasks and questions have to include some that are too hard for you. Your performance will be compared with what's normal for someone of your age, sex and educational background.",
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PROSANDCONS,
                  pros: {
                    title: {
                      nl: 'Voordelen',
                      en: 'Pros',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Hiermee weten we precies wat er mis gaat met het denken (\'cognitieve functies\'). Bijvoorbeeld: Is de vergeetachtigheid erger dan verwacht voor iemand van uw leeftijd? U heeft geheugenklachten, komt dit door het geheugen of is het een concentratieprobleem?',
                              en: "We'll get a clear picture of the problems you have with thinking (your 'cognitive functions'). For example: Is your forgetfulness worse than normal for someone of your age? Do you forget things because you have a memory problem, or a concentration problem?",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Het neuropsychologisch onderzoek meet of er sprake is van dementie. En het meet ook de ernst daarvan: milde, matige of ernstige dementie.',
                              en: 'Neuropsychological testing shows whether you have dementia, and, if so, how serious it is.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  },
                  cons: {
                    title: {
                      nl: 'Nadelen',
                      en: 'Cons',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Sommige patiënten ervaren het neuropsychologisch onderzoek als confronterend, want ze merken wat er niet goed gaat.',
                              en: 'Some people find neuropsychological testing unpleasant, because they are confronted with their problems.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Omdat het testonderzoek vrij lang duurt, is het voor sommige mensen vermoeiend.',
                              en: 'The tests take quite a long time, so some people find them tiring.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  }
                },
                {
                  type: contentType.IMAGE,
                  src: diagnostischeTesten1,
                }
              ]
            },
            {
              title: {
                nl: 'Beeldvorming (MRI/CT)',
                en: 'Imaging (MRI/CT)',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: '<strong>Waarom:</strong> Een scan van de hersenen wordt standaard gedaan in de dementiediagnostiek. Met een MRI of CT scan kunnen we de hersenen zien. We kunnen behandelbare aandoeningen uitsluiten. Ook kunnen we kenmerken van dementie zien, zoals hersenkrimp of slijtage van de bloedvaten.',
                    en: '<strong>Why:</strong> A brain scan is a standard part of diagnostic testing for dementia. An MRI or CT scan provides an image of the brain. By studying the image, we can rule out the possibility of treatable conditions. A scan can also show signs of dementia, such as brain shrinkage and deterioration of the blood vessels.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PROSANDCONS,
                  pros: {
                    title: {
                      nl: 'Voordelen',
                      en: 'Pros',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Opsporen van behandelbare aandoeningen, zoals een hersentumor, een herseninfarct of een hersenbloeding.',
                              en: 'Treatable conditions – brain tumor, stroke, bleeding – can be detected.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Kenmerken van dementie opsporen.',
                              en: 'Signs of dementia can be detected.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een MRI laat meer details zien dan een CT.',
                              en: 'An MRI scan gives a more detailed picture than a CT scan.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  },
                  cons: {
                    title: {
                      nl: 'Nadelen',
                      en: 'Cons',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een patiënt moet ± 30 min stil liggen voor een MRI scan.',
                              en: 'Having an MRI scan means lying still for about half an hour.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een MRI scan wordt door sommige patiënten als spannend of belastend ervaren, bijvoorbeeld bij claustrofobie.',
                              en: 'Some patients find MRI scans stressful or difficult, e.g. because they suffer from claustrophobia.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Je kunt niet altijd een MRI doen, bijvoorbeeld bij een pacemaker.',
                              en: "Some people with pacemakers and certain others can't have MRI scans.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een CT is minder belastend, want het onderzoek is korter (10-15min) en het apparaat is ruimer. Ook een pacemaker is geen probleem.',
                              en: "A CT scan is less of a burden, because it doesn't take as long (10 to 15 minutes) and you've got more space. People with pacemakers can have CT scans.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een CT laat minder details zien dan een MRI.',
                              en: 'A CT scan provides a less detailed image than an MRI scan.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  }
                },
                {
                  type: contentType.IMAGE,
                  src: diagnostischeTesten2,
                }
              ]
            },
            {
              title: {
                nl: 'Ruggenprik',
                en: 'Lumbar puncture (spinal tap)',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: '<strong>Waarom:</strong>',
                    en: '<strong>Why:</strong>',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.IMAGEWITHTEXT,
                  text: {
                    nl: 'In de hersenvloeistof kun je Alzheimer eiwitten bepalen, die zie je niet met MRI of CT. De aanwezigheid van Alzheimer eiwitten zegt iets over de kans op het ontwikkelen van Alzheimer dementie. Hersenvloeistof vangen we op met een ruggenprik.',
                    en: "The presence of Alzheimer proteins increases the risk of developing dementia due to Alzheimer\'s disease. It doesn't show up on MRI or CT scans, but can be detected in brain fluid. A sample of brain fluid can be collected by inserting a needle into your spine (a spinal tap).",
                    se: '',
                    de: ''
                  },
                  src: diagnostischeTesten3,
                },
                {
                  type: contentType.VIDEO,
                  src: {
                    nl: ruggenprikNL,
                    en: ruggenprikEN,
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PROSANDCONS,
                  pros: {
                    title: {
                      nl: 'Voordelen',
                      en: 'Pros',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Als de uitslag normaal is, sluit dit de ziekte van Alzheimer uit. Mensen met een normale uitslag kunnen wel een andere vorm van dementie krijgen.',
                              en: "If the result is normal, we can rule out the possibility of (current) Alzheimer's disease. However, people with normal protein levels can still develop other forms of dementia.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'We kunnen het Alzheimer ziekteproces in de hersenen al in een vroeg stadium vaststellen.',
                              en: "We can detect Alzheimer's disease very early.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Juist bij jonge mensen, waar de ziekte van Alzheimer zich anders kan uiten, geven de eiwitbepalingen duidelijkheid.',
                              en: 'Testing for proteins is particularly useful for diagnosing Alzheimer\'s disease in young people, because their symptoms are often different from the symptoms in older people.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  },
                  cons: {
                    title: {
                      nl: 'Nadelen',
                      en: 'Cons',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'De procedure kan pijnlijk zijn en er is een kleine kans op hoofdpijn. Ernstige bijwerkingen komen eigenlijk niet voor.',
                              en: "The procedure can be painful, and there's a small risk of getting a headache, but there's almost no risk of serious side-effects.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een afwijkende uitslag geeft nog geen zekerheid. Mensen met Alzheimer dementie hebben afwijkende eiwitten, maar mensen met afwijkende eiwitten hebben of krijgen niet altijd Alzheimer dementie. Afwijkende eiwitten betekent dat je een hoger risico hebt of het ontwikkelen van Alzheimer dementie, niet dat je zeker Alzheimer dementie krijgt. Dit kan verwarrend zijn.',
                              en: "If abnormal protein levels are detected, that doesn't definitely indicate a problem. People with Alzheimer's dementia always have abnormal proteins, but not everyone with abnormal proteins goes on to develop Alzheimer's dementia. An abnormal result means that you are at more risk of Alzheimer's dementia, not that you are sure to develop the condition. That can lead to confusion.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Maar, mensen zonder Alzheimer eiwitten kunnen wel een andere vorm van dementie krijgen. Hiervoor zijn nog geen goede eiwitbepalingen.',
                              en: "People who don't have Alzheimer proteins can still develop other forms of dementia. Reliable protein tests aren't yet available for other forms of dementia.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Omdat de ziekte van Alzheimer in een vroeg stadium kan worden vastgesteld, is de patiënt zich hier langer bewust van. Niet iedereen wil dat.',
                              en: "Early detection of Alzheimer's disease can mean living with the knowledge of what's ahead for longer. Not everyone wants that.",
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  }
                },
              ]
            },
            {
              title: {
                nl: 'Amyloïd PET',
                en: 'Amyloïd PET',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: '<strong>Waarom:</strong> Amyloid PET maakt de aanwezigheid van het Alzheimer eiwit Amyloid in de hersenen zichtbaar. Amyloid PET hoort niet tot de gebruikelijke diagnostiek. Maar in sommige gevallen kan het extra informatie geven.',
                    en: "<strong>Why:</strong> An amyloid PET scan visualizes whether the Alzheimer protein amyloid is present in the brain. An amyloid PET scan isn't one of the standard diagnostic tests. We do these scans when extra information is wanted.",
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PROSANDCONS,
                  pros: {
                    title: {
                      nl: 'Voordelen',
                      en: 'Pros',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'De ziekte van Alzheimer kan uitgesloten worden, vooral als de uitslag van de amyloid PET normaal is. Patiënten met een normale uitslag kunnen wel een andere vorm van dementie krijgen.',
                              en: "We can rule out the possibility of Alzheimer's disease, certainly if the result is normal. However, people with normal protein levels can still develop other forms of dementia.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'We kunnen het Alzheimer ziekteproces in de hersenen al in een vroeg stadium vaststellen.',
                              en: "We can detect Alzheimer's disease very early.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Je kunt met amyloid PET ook zien waar het Alzheimer eiwit in de hersenen zit.',
                              en: "An amyloid PET scan also lets us see where Alzheimer's protein is present in the brain.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een amyloid PET scan helpt bij het onderscheid tussen Alzheimer en andere vormen van dementie.',
                              en: "An amyloid PET scan helps us tell the difference between Alzheimer's and other forms of dementia.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Juist bij jonge mensen, waar Alzheimer zich anders kan uiten, geeft een amyloid PET duidelijkheid.',
                              en: 'Testing for the presence of amyloid is particularly useful for diagnosing Alzheimer\'s disease in young people, because their symptoms are often different from the symptoms in older people.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  },
                  cons: {
                    title: {
                      nl: 'Nadelen',
                      en: 'Cons',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een afwijkende amyloid PET betekent niet dat je dementie hebt, dit kan verwarrend zijn. Mensen met Alzheimer dementie hebben een afwijkende amyloid PET, maar mensen met een afwijkende amyloid PET hebben of krijgen niet altijd Alzheimer dementie. Een afwijkende amyloid PET betekent dat je een hoger risico hebt op het ontwikkelen van Alzheimer dementie, maar een afwijkende amyloid PET betekent niet dat je zeker Alzheimer dementie krijgt.',
                              en: "An abnormal amyloid PET result doesn't definitely mean that you have dementia. That can lead to confusion. People with Alzheimer's dementia always have abnormal amyloid PET scans, but not everyone with an abnormal scan goes on to develop Alzheimer's dementia. An abnormal result means that you are at more risk of Alzheimer's dementia, not that you are sure to develop the condition.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Mensen met een normale amyloid PET kunnen wel een andere vorm van dementie krijgen.',
                              en: 'People who have normal amyloid PET scans can still develop other forms of dementia.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Omdat Alzheimer in een vroeg stadium kan worden vastgesteld, is de patiënt zich langer bewust van de hersenziekte. Niet iedereen wil dat.',
                              en: "Early detection of Alzheimer's can mean living with the knowledge of what's ahead for longer. Not everyone wants that.",
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  }
                },
                {
                  type: contentType.IMAGE,
                  src: diagnostischeTesten4
                }
              ]
            },
            {
              title: {
                nl: 'Consult andere specialist (neuroloog, geriater, psychiater)',
                en: 'Further specialist consultation (neurologist, geriatrician, psychiatrist)',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: '<strong>Waarom:</strong> Op de geheugenpoli werken we met verschillende specialisten samen. Die hebben ieder hun eigen aandachtsgebied.<br /><br />Neuroloog: specialist in hersenziektes, en dementie is een hersenziekte. Over het algemeen ziet de neuroloog jongere mensen.<br /><br />Geriater: specialist in ziektes op oudere leeftijd, wanneer mensen vaak meerdere ziektes tegelijk hebben.<br /><br />Psychiater: specialist in stemmingsproblemen en/of gedragsveranderingen. Deze kunnen onderdeel zijn van dementie, maar kunnen ook juist wijzen op een andere oorzaak van de klachten.',
                    en: '<strong>Why:</strong> At the memory clinic, we have various several specialists working together. Each specialist focuses on a particular field.<br /><br />Neurologist: a specialist in brain diseases and dementia is a brain disease. Typically sees younger people.<br /><br />Geriatrician: a specialist in age-related conditions. Older people often have multiple problems at the same time.<br /><br />Psychiatrist: a specialist in mood disorders and/or behavioral changes (both dementia-related problems and problems with other causes).',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PROSANDCONS,
                  pros: {
                    title: {
                      nl: 'Voordelen',
                      en: 'Pros',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Maatwerk: afhankelijk van de klachten die bij de individuele patiënt op de voorgrond staan, past de ene specialist beter dan de andere.',
                              en: "Tailoring: the specialist support can be matched to the individual patient's main problems.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Twee paar ogen zien meer dan één: iedere specialist brengt eigen expertise mee. Soms werken twee of meer specialisten standaard samen. Soms vragen specialisten elkaar in consult als er specifieke aanleiding voor is.',
                              en: "A second pair of eyes: another specialist will use different expertise when looking at a case. Sometimes it's the norm for two or more specialists to work together. Other times, one specialist will ask for another specialist's help with a particular problem.",
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  },
                  cons: {
                    title: {
                      nl: 'Nadelen',
                      en: 'Cons',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een extra bezoek kost tijd.',
                              en: 'Seeing more physicians takes more time.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  }
                },
              ]
            },
            {
              title: {
                nl: 'Consult met een klinisch geneticus',
                en: 'Consultation with a clinical geneticist',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: '<strong>Waarom:</strong> Bij een heel klein gedeelte (<3%) van de patiënten wordt dementie veroorzaakt door één erfelijke afwijking. De klinisch geneticus kan hier informatie over geven en onderzoeken of er sprake is van zo\'n zeldzame erfelijke vorm van dementie. Als u in aanmerking komt voor DNA-onderzoek, dan bepaalt u zelf of u dit wilt doen en wanneer. Voor het consult met de klinisch geneticus hoeft u nog niet te weten of u zich wilt laten testen. Met <a href="https://www.keuzehulp-dna-onderzoek.nl/" target="_blank">www.keuzehulp-dna-onderzoek.nl</a> kunt u uw overwegingen op een rij zetten.',
                    en: "<strong>Why:</strong> Very occasionally (in less than 3 per cent of people) dementia is caused by an inherited ('genetic') abnormality. A clinical geneticist can look into that possibility and provide related information. If genetic testing is relevant for you, the decision about whether and when to go ahead will be yours. You can see a clinical geneticist before you've decided whether you want a DNA test. For Dutch people, visiting <a href='https://www.keuzehulp-dna-onderzoek.nl/' target='_blank'>www.keuzehulp-dna-onderzoek.nl</a> can help you decide what you want to do.",
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PROSANDCONS,
                  pros: {
                    title: {
                      nl: 'Voordelen',
                      en: 'Pros',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Geeft meer informatie over de erfelijkheid van de ziekte, medische controles en begeleiding.',
                              en: 'Provides information about dementia with an inherited cause, medical checks and counselling.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een consult met een klinisch geneticus heeft geen gevolgen voor uw verzekering of hypotheek.',
                              en: "Consulting a clinical geneticist won't affect your ability to get insurance or a mortgage in the Netherlands.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Als u besluit tot DNA-onderzoek, geeft dit u duidelijkheid over of het afwijkende gen aanwezig is of niet. Dit kan van belang zijn voor belangrijke besluiten, bijvoorbeeld qua relatie, werk of het krijgen van kinderen.',
                              en: 'If you decide to have a DNA test, you will know whether you have the relevant abnormal gene. That may be useful when making decisions about important things such as relationships, work and having children.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Als u besluit tot DNA-onderzoek, en er geen erfelijke oorzaak of afwijkend gen wordt gevonden is dit vaak een geruststelling.',
                              en: 'If you decide to have a DNA test and no hereditary cause or abnormal gene is found, this is often reassuring.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  },
                  cons: {
                    title: {
                      nl: 'Nadelen',
                      en: 'Cons',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'In het overgrote deel van de gevallen vinden we geen afwijkend gen.',
                              en: 'In the great majority of cases, no abnormality is detected.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Sommige mensen die de aanleg niet blijken te hebben, kunnen zich schuldig voelen tegenover familieleden die de aanleg wel hebben.',
                              en: "Some people who don't have an abnormality feel guilty that they are clear, while loved ones are not.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Als u besluit tot DNA-onderzoek, en er een erfelijke oorzaak of afwijkend gen wordt gevonden kan dit een belasting zijn voor familieleden.',
                              en: 'If you decide to have a DNA test and a hereditary cause or an abnormal gene is found, this can be a burden on family members.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  }
                },
              ]
            },
            {
              title: {
                nl: 'Afwachtend beleid',
                en: 'Watchfull waiting',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: '<strong>Waarom:</strong> De diagnostische testen hebben meestal geen gevolgen voor de behandeling. De ziekte van Alzheimer en dementie zijn namelijk nog niet te genezen. Je kunt er daarom ook voor kiezen om af te wachten hoe de klachten zich ontwikkelen.',
                    en: "<strong>Why:</strong> With dementia, diagnostic test results don't usually influence treatment. Alzheimer's disease and dementia can't yet be cured, so some people prefer to simply wait and see how their symptoms develop.",
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PROSANDCONS,
                  pros: {
                    title: {
                      nl: 'Voordelen',
                      en: 'Pros',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Je weet niet wat de oorzaak van de klachten is. Je hoeft daardoor niet dagelijks met de wetenschap te leven dat je mogelijk Alzheimer dementie gaat ontwikkelen.',
                              en: "You don't know what's causing your problems, so you don't have to live with the knowledge that you're at risk of developing Alzheimer's dementia.",
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Een (vroegtijdige) diagnose kan nadelige praktische gevolgen hebben (bijv. niet meer mogen autorijden) of gevolgen voor hoe anderen met u of met de diagnose omgaan. Denk bijvoorbeeld aan de gevolgen voor het gezin of werk.',
                              en: 'Diagnosis, especially early diagnosis, can have adverse practical consequences. You might be told not to drive, for example. Also, other people (e.g. family and co-workers) might treat you differently, or find the diagnosis hard to deal with.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Zodra er wat verandert kan er altijd nog getest worden. Maar als je eenmaal getest bent en een diagnose hebt, kun je niet meer terug.',
                              en: "If your condition changes, you can always have diagnostic tests later. But, if you undergo testing and you're diagnosed, there's no going back.",
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  },
                  cons: {
                    title: {
                      nl: 'Nadelen',
                      en: 'Cons',
                      se: '',
                      de: ''
                    },
                    content: [
                      {
                        type: contentType.BULLETLIST,
                        content: [
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'De onzekerheid over of er wel of geen sprake is van een hersenziekte, kan soms nog vervelender zijn, dan het slechte nieuws te weten.',
                              en: 'Not knowing whether you have a brain disease can sometimes be worse than knowing that you do.',
                              se: '',
                              de: ''
                            }
                          },
                          {
                            type: contentType.TEXT,
                            text: {
                              nl: 'Als er geen diagnose gesteld is, kan het moeilijker zijn je voor te bereiden op wat je te wachten staat en om (tijdig) hulp in te schakelen.',
                              en: 'Without a diagnosis, it may be harder to prepare for the future or to arrange care in good time.',
                              se: '',
                              de: ''
                            }
                          },
                        ]
                      }
                    ]
                  }
                },
              ]
            },
          ]
        },
      ]
    },
    behandeling: {
      title: {
        nl: 'Behandeling',
        en: 'Treatment',
        se: '',
        de: ''
      },
      subtitle: {
        nl: 'Remmende medicijnen voor Alzheimer',
        en: 'Medicines that slow the disease',
        se: '',
        de: ''
      },
      intro: {
        nl: 'Er zijn nieuwe medicijnen tegen de ziekte van Alzheimer. Deze worden in een aantal landen op de wereld al ingezet. In Nederland nog niet. De medicijnen grijpen in op het ziekteproces waardoor ze de ziekte van Alzheimer enigszins remmen. De ziekte wordt niet gestopt. Deze medicijnen genezen de ziekte dus niet.',
        en: 'There are new medicines against Alzheimer\'s disease. These are available in some countries, but not yet in the Netherlands. These medicines slow progression of Alzheimer\'s disease. However, they do not stop the disease and are not a cure.',
        se: '',
        de: ''
      },
      content: [
        {
          type: contentType.TEXT,
          text: {
            nl: 'Wat zijn de voordelen? Als groep gaan patiënten die deze medicijnen gebruiken minder snel achteruit in hun denkvermogen. Mogelijk kan iemand daardoor iets langer zelfstandig de dingen blijven doen die hij of zij nu kan. Het effect verschilt per persoon en dit kunnen we niet goed voorspellen.',
            en: 'What are the advantages? On group level, patients who take these drugs experience slower loss of thinking skills. The drugs may make it possible for someone to keep doing the things they can do now for a while longer. The benefit is different for each person, and we can\'t yet predict all of the outcomes.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.TEXT,
          text: {
            nl: 'De medicijnen hebben ook nadelen. Tijdens en na de behandeling moet de patiënt, samen met een naaste, vaak naar het ziekenhuis komen, bijvoorbeeld voor een hersenscan. Dit kost tijd en moeite. Er is ook een kans op bijwerkingen, die in enkele gevallen ernstig zijn. Hoe vaak bijwerkingen precies voorkomen, weten we nog niet. We gaan hier ervaring mee opdoen.',
            en: 'These drugs also have disadvantages. During and after treatment, the patient has to come to the hospital with a family member or friend, for example for brain scans. This takes time and effort. There is also a risk of side-effects, which can be serious in some cases. We don\'t know yet exactly how often people will get side-effects. We will gain experience with this.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.TEXT,
          text: {
            nl: 'Maar een (klein) deel van de mensen met Alzheimer kan deze medicijnen krijgen. De arts stelt eerst vast of iemand in aanmerking kan komen op basis van medische omstandigheden. Dan volgt een gesprek. Hierin geeft de arts informatie over het medicijn en vertellen de patiënt en dienst naaste(n) hoe zij aankijken tegen de voor- en nadelen, en wat zij graag willen. Daarna beslissen de arts, patiënt en naaste(n) samen om wel of niet te starten met het medicijn.',
            en: 'These drugs are not for everyone with Alzheimer\'s. The medical doctor will first find out whether a patient is a candidate for treatment based on their medical condition. If they are, there will be a meeting. At this meeting, the doctor will provide information about the medication. With this information the patient and loved ones can think about the pros and cons and decide what they would like to do. Then the doctor, patient and loved ones will decide whether to start treatment.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.VIDEO,
          src: {
            nl: treatmentNL,
            en: treatmentEN,
            se: '',
            de: ''
          },
        },
        {
          type: contentType.ANCHORLINKS,
          title: {
            nl: 'Snel naar',
            en: 'Quickly to',
            se: '',
            de: ''
          },
          items: [
            {
              label: {
                nl: 'Alzheimer',
                en: "Alzheimer's disease",
                se: '',
                de: ''
              },
              anchor: 'alzheimer'
            },
            {
              label: {
                nl: 'Medicijnen',
                en: 'Treatment',
                se: '',
                de: ''
              },
              anchor: 'medicijnen'
            },
            {
              label: {
                nl: 'Voordelen',
                en: 'Advantages',
                se: '',
                de: ''
              },
              anchor: 'voordelen'
            },
            {
              label: {
                nl: 'Nadelen',
                en: 'Disadvantages',
                se: '',
                de: ''
              },
              anchor: 'nadelen'
            },
            {
              label: {
                nl: 'Onzekerheid',
                en: 'Uncertainty',
                se: '',
                de: ''
              },
              anchor: 'onzekerheid'
            },
            {
              label: {
                nl: 'Samen beslissen',
                en: 'Shared decision-making',
                se: '',
                de: ''
              },
              anchor: 'beslissen'
            },
          ]
        },
        {
          type: contentType.SECTION,
          id: 'alzheimer',
          content: [
            {
              type: contentType.TEXT,
              text: {
                nl: '<h2>Ziekte van Alzheimer</h2>',
                en: '<h2>Alzheimer\'s disease </h2>',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.PARAGRAPH,
              text: {
                nl: 'De ziekte van Alzheimer is een hersenziekte. Het is de meest voorkomende oorzaak van dementie. Door de ziekte gaat het denkvermogen steeds verder achteruit. Het denkvermogen noemen we ook wel de cognitieve functies. Dit zijn bijvoorbeeld het geheugen, taal, aandacht en concentratie, en logisch redeneren.',
                en: 'Alzheimer\'s is a disease of the brain and is the most common cause of dementia. The disease causes a decline in thinking and reasoning skills. These skills are the brain\'s \'cognitive functions\', and include remembering, concentrating, problem solving and language skills.',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.ACCORDION,
              items: [
                {
                  title: {
                    nl: 'Hoe gaat het ziekteproces?',
                    en: 'How does the disease progress?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'Bij de ziekte van Alzheimer zijn er eiwitophopingen in de hersenen. Dit gaat om twee soorten eiwitten. Eén eiwit wordt amyloïd genoemd. Het andere eiwit wordt tau genoemd. Als deze eiwitten ophopen, zorgen ze voor schade aan de hersenen. Daardoor gaat het denkvermogen in de loop van de tijd steeds verder achteruit.',
                        en: 'With Alzheimer\'s disease, there is protein buildup in the brain. Two different kinds of proteins are involved. One is called \'amyloid\' protein and the other protein is called \'tau\'. The buildup of these proteins causes damage to the brain which causes a person\'s cognitive functions to decline.',
                        se: '',
                        de: ''
                      },
                    }
                  ]
                },
                {
                  title: {
                    nl: 'Wat is MCI?',
                    en: 'What is MCI?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'De ziekte van Alzheimer ontwikkelt zich langzaam, over een periode van vele jaren. Iemand met de ziekte van Alzheimer ervaart in het begin slechts lichte problemen in het denkvermogen, bijvoorbeeld vergeetachtigheid. Doordat het denkvermogen steeds verder achteruitgaat gaat, krijgen mensen met de ziekte van Alzheimer op den duur ook moeite met dagelijkse activiteiten. In de fase waarin iemand nog veel dingen zelfstandig kan, spreken we over milde cognitieve stoornissen, of MCI, door de ziekte van Alzheimer.',
                        en: 'Alzheimer\'s disease develops slowly over a period of many years. Someone with Alzheimer\'s experiences at first only very minor problems with cognitive functioning, like becoming forgetful.  Thinking skills continue to decline, and eventually people with Alzheimer\'s disease have trouble successfully managing day-to-day activities. The stage when someone can still do many things independently we call mild cognitive impairment or MCI caused by Alzheimer\'s disease.',
                        se: '',
                        de: ''
                      },
                    }
                  ]
                },
                {
                  title: {
                    nl: 'Wat is dementie?',
                    en: 'What is dementia?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'Uiteindelijk kan iemand met de ziekte van Alzheimer veel van zijn of haar normale dagelijkse activiteiten niet meer zelfstandig. In die fase spreken we over dementie door de ziekte van Alzheimer. Over de loop van de tijd nemen de problemen steeds meer toe; van milde dementie tot ernstige dementie.',
                        en: 'Eventually, someone with Alzheimer\'s disease can no longer manage many of their normal daily activities independently. This stage we call dementia caused by Alzheimer\'s disease. At the point when the problems become more and more difficult to manage; we say that someone has progressed from mild dementia to severe dementia.',
                        se: '',
                        de: ''
                      },
                    }
                  ]
                },
              ]
            }
          ]
        },
        {
          type: contentType.SECTION,
          id: 'medicijnen',
          content: [
            {
              type: contentType.TEXT,
              text: {
                nl: '<h2>Ziekte-remmende medicijnen</h2>',
                en: '<h2>Medicines that slow the disease</h2>',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.PARAGRAPH,
              text: {
                nl: 'Bestaande behandelingen bij de ziekte van Alzheimer, zoals de zogenaamde \'cholinesteraseremmers\', verlichten mogelijk de klachten. Zij hebben geen effect op het ziekteverloop. We verwachten nieuwe medicijnen tegen de ziekte van Alzheimer die de ziekte remmen. Deze ziekte-remmende medicijnen worden in een aantal landen op de wereld al gebruikt. In Nederland nog niet, omdat deze medicijnen in Europa nog niet goedgekeurd zijn',
                en: 'Existing treatments for Alzheimer\'s disease, such as cholinesterase inhibitors, may help ease symptoms. However, they do not stop the progression of the disease. We are expecting new drugs for Alzheimer\'s that do slow the disease. These disease-modifying medicines are already being used in other countries but they are not yet available in the Netherlands.',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.ACCORDION,
              items: [
                {
                  title: {
                    nl: 'Welke Alzheimer-remmende medicijnen zijn er?',
                    en: 'What drugs are there that slow Alzheimer\'s disease?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'Er zijn nu twee medicijnen die mogelijk worden goedgekeurd om te gebruiken in Europa: Lecanemab (Leqembi) en Donanemab. Hun werking is ongeveer hetzelfde: ze hebben effect op het Alzheimer ziekteproces. Ze ruimen het amyloïd-eiwit in de hersenen op. De verwachting is dat hierdoor de schadelijke effecten op de hersenen verminderen. De achteruitgang in het denkvermogen gaat langzamer. De medicijnen genezen de ziekte van Alzheimer niet.',
                        en: 'There are two drugs that might be approved for use in Europe: Lecanemab (Leqembi) and Donanemab. They slow the progression of Alzheimer\'s disease by clearing amyloid protein from the brain. Researchers expect this will reduce the damaging effects of protein buildup in the brain. The drugs slow down cognitive decline but do not cure Alzheimer\'s disease.',
                        se: '',
                        de: ''
                      },
                    }
                  ]
                },
              ]
            }
          ]
        },
        {
          type: contentType.SECTION,
          id: 'voordelen',
          content: [
            {
              type: contentType.TEXT,
              text: {
                nl: '<h2>Voordelen</h2>',
                en: '<h2>Advantages</h2>',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.PARAGRAPH,
              text: {
                nl: 'De nieuwe medicijnen genezen de ziekte van Alzheimer niet. Wel is aangetoond in wetenschappelijk onderzoek dat de medicijnen het Alzheimer-eiwit amyloïd kunnen opruimen. Ook zien we in wetenschappelijke onderzoeken dat de achteruitgang in het denkvermogen langzamer gaat bij de groep van mensen die de medicijnen gebruikten.',
                en: 'These new drugs are not a cure for Alzheimer\'s disease, but trials showed that they can reduce the amyloid protein deposits that come with Alzheimer\'s. Research has shown a slower rate of cognitive decline in the people who took these drugs.',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.PARAGRAPH,
              text: {
                nl: 'Mensen die de medicijnen gebruiken gaan dus mogelijk minder snel achteruit in het denkvermogen. Mogelijk kunnen mensen hierdoor langer zelfstandig de dingen blijven doen die zij nu kunnen. Ze gaan nog wel achteruit. Dit betekent dat zij geen verbetering bemerken.',
                en: 'It is therefore possible that those who take the drug will lose their cognitive functions less quickly. This may help people live independently for longer. These people will continue to decline, but usually more slowly. They will thus not notice any improvement.',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.ACCORDION,
              items: [
                {
                  title: {
                    nl: 'Wat zegt wetenschappelijk onderzoek?',
                    en: 'What does medical research say?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'In wetenschappelijk onderzoek zijn mensen met MCI en met milde dementie door de ziekte van Alzheimer gevolgd over een periode van 18 maanden. Een deel van de mensen kreeg het medicijn, en een ander deel niet. Over de tijd zagen de onderzoekers dat de groep van mensen die het medicijn kreeg, minder snel achteruit ging in het denkvermogen, dan de groep van mensen die het medicijn niet kreeg.',
                        en: 'In the drug trials, people with MCI and with mild dementia caused by Alzheimer\'s disease were tracked for a period of 18 months. One group of people took the drug, the other group did not. Over the course of the trial, researchers observed that the thinking skills of the group of people who took the drug deteriorated less quickly than those of the people who did not get any medicine.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'In het volgende voorbeeld wordt dit voordeel verder uitgelegd.',
                        en: 'The benefit for patients is further explained by the following example.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'Voorbeeld:<br>Jan is 65 jaar oud en heeft last van geheugenklachten en concentratieproblemen. Ondanks zijn klachten, is Jan in het dagelijks leven nog zelfstandig. In het ziekenhuis spreekt Jan met de arts en worden er onderzoeken gedaan. Er worden afwijkingen gevonden in de twee eiwitten die wijzen op de ziekte van Alzheimer. Ook zijn er testen gedaan voor het denkvermogen. Er wordt vastgesteld dat Jan milde cognitieve stoornissen (MCI) heeft, door de ziekte van Alzheimer.',
                        en: 'Example:<br>Jan is 65 years old and has problems with memory and concentration. Despite his symptoms, Jan still takes care of his daily needs independently. At the hospital, Jan talks to the doctor who conducts some tests. They find abnormal levels of the two proteins that indicate Alzheimer\'s disease is present. They also test Jan\'s cognitive functions. Jan is diagnosed as having mild cognitive impairment (MCI) caused by Alzheimer\'s disease.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'Het denkvermogen van Jan werd gemeten met de MMSE test. Op de MMSE test kun je een score krijgen tussen 0 en 30. Hoe hoger de score, hoe beter het denkvermogen. De score van Jan is 28. Met een score van 28 spreken we nog niet van dementie. Jan heeft MCI.',
                        en: 'They measure Jan\'s mental ability using the MMSE test. The MMSE test gives a score between 0 and 30. The higher the score, the better your cognitive skills/thinking abilities. Jan scores 28 on the test. A score of 28 means Jan does not have dementia. Jan has MCI.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'In dit figuur zien we hoe we verwachten dat het denkvermogen achteruit zal gaan in de komende 5 jaar, voor patiënten zoals Jan. Dit is de donkerblauwe, doorgetrokken lijn. We kunnen dit niet heel precies voorspellen. Voor de meeste mensen zoals Jan loopt de deze lijn ergens in het lichtblauwe gebied. Het kan meevallen en het kan tegenvallen.',
                        en: 'This figure shows how we expect thinking skills to decline in the next 5 years for patients like Jan. That\'s shown by the dark blue solid line. We cannot predict this with great precision. For most people like Jan this line goes into the light blue area. It might be better than expected or it might be worse.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'Met het medicijn kan de achteruitgang in het denkvermogen worden afgeremd (met ongeveer 30%). Dat laat de lichtblauwe (stippel)lijn zien. Het denkvermogen wordt nog steeds slechter, maar met het medicijn gaat dit wat minder snel. We weten niet wat er gebeurt na de periode van 18 maanden waarop de medicijnen tot nu toe zijn getest. Daarom loopt de lichtblauwe lijn verder als stippellijn. Ook weten we niet wat het effect precies zal zijn voor een individuele persoon, zoals Jan. Het kan effect kan groter of kleiner zijn.',
                        en: 'By taking the medication, the decline in cognitive function is slowed down (by about 30%). This is shown by the light blue (dotted) line. A person\'s thinking skills keep declining, but with the medication this progresses less quickly. We don\'t know what happens after 18 months because that\'s how long the drug was tested. That\'s why the light blue line keeps going as a dotted line. We also don\'t know the exact effect the drug will have on an individual person like Jan. It may be more or less effective.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.IMAGE,
                      src: {
                        nl: lineGraphNL,
                        en: lineGraphEN
                      }
                    },
                  ]
                },
              ]
            }
          ]
        },
        {
          type: contentType.SECTION,
          id: 'nadelen',
          content: [
            {
              type: contentType.TEXT,
              text: {
                nl: '<h2>Nadelen</h2>',
                en: '<h2>Disadvantages</h2>',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.PARAGRAPH,
              text: {
                nl: 'Er zijn twee soorten van mogelijke nadelen van de nieuwe medicijnen, namelijk de belasting voor de patiënt en naaste(n), en eventuele bijwerkingen.',
                en: 'There are two types of potential risks or disadvantages associated with the new medicines, namely the demand on patients and their loved ones, and potential side effects.',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.ACCORDION,
              items: [
                {
                  title: {
                    nl: 'Wat is de belasting voor de patiënt en diens naaste(n)?',
                    en: 'What is the demand on patients and their loved ones?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: '<b>Belangrijk:</b> De nieuwe medicijnen zijn nog niet beschikbaar in Nederland. Als de medicijnen in Nederland gebruikt gaan worden, is het nog niet zeker hoe de medicijnen worden gegeven en waar dit gaat plaatsvinden. De tekst hieronder geeft een idee.',
                        en: '<b>Important to note:</b> The new drugs are not yet available in the Netherlands. If the drugs are approved for use, it is not yet clear how they will be given and where. The text below gives an idea.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'Bij het medicijn komt veel kijken. Om te weten of iemand het medicijn mag krijgen, heeft een arts veel informatie nodig. Ook patiënten en naasten hebben informatie nodig om mee te kunnen beslissen. De behandeling vindt plaats in het ziekenhuis en er zijn veel controles nodig, waarbij er bijvoorbeeld hersenscans worden gemaakt. Dit vergt wat van de patiënt, en van de naaste(n). Het kost bijvoorbeeld tijd en energie. Je bent ook veel bezig met de ziekte en de behandeling.',
                        en: 'There is a lot involved in taking the medication. A doctor needs a lot of information before deciding whether someone can take the drug. Patients and their loved ones also need information to be able to take part in the decision about whether the drug should be taken. Treatment with the drug takes place at a hospital, and many check-ups are needed which involve procedures including brain scans. This demands a lot from patients and from their families and takes time, commitment and energy. A person\'s life can also become a little dominated by the disease and the treatment.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.BULLETLIST,
                      title: {
                        nl: 'Stappen die gezet moeten worden.',
                        en: 'What are the steps leading to treatment?',
                        se: '',
                        de: ''
                      },
                      content: [
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<u>Diagnose stellen.</u> Er moet sprake zijn van MCI of milde dementie, door de ziekte van Alzheimer. Hier is een gesprek met de arts voor nodig en verschillende onderzoeken en testen.',
                            en: '<u>Making a diagnosis.<u> The drug is only for people who have MCI or mild dementia caused by Alzheimer\'s disease. A doctor needs to examine a patient and run several tests before making a diagnosis.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<u>Vaststellen of iemand het medicijn mag krijgen.</u> Er vinden ook onderzoeken plaats om vast te stellen of een patiënt het medicijn mag krijgen. Bij een deel van de mensen is het niet voldoende veilig, bijvoorbeeld bij gebruik van sommige andere medicijnen en bij sommige bevindingen op de hersenscan of genetisch onderzoek. De arts kan hier meer over vertellen.',
                            en: '<u>Deciding whether someone can receive the medication.</u> A patient will have to be examined before deciding whether they can receive the medication. It will not be sufficiently safe for some people, for example because they are using other medicines, because of findings on the brain scan or because they carry a certain gene. The doctor can tell you more about this.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<u>Gesprek over medicijnen.</u> Patiënt en naaste worden geïnformeerd door de arts (en mogelijk andere zorgverleners, zoals een verpleegkundige). Patiënt en naaste delen op hun beurt persoonlijke (medische) informatie, bijvoorbeeld over hun situatie, het steunsysteem en hun behoeften, wensen en voorkeuren. Zij kunnen ook vragen stellen.',
                            en: '<u>Discussion about medicines.</u> At a meeting, the doctor and possibly other healthcare providers like a nurse will explain the new drug to the patient and their family. The patient and family will share information with the doctor about their personal and medical conditions, like their living situation and support system, as well as their needs, wishes and preferences. There will be the opportunity to ask questions.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<u>Besluitvorming.</u> Als de patiënt het medicijn mag krijgen, dan beslissen arts, patiënt, en naaste(n) samen of starten met medicijnen gewenst is.',
                            en: '<u>Making a decision.</u> If the patient is a good candidate for the drug, then the doctor, patient and family will together decide whether it is a good idea to start the treatment.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<u>Behandeling en controle</u>.',
                            en: '<u>Treatment and regular check-ups</u>.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.BULLETLIST,
                          content: [
                            {
                              type: contentType.TEXT,
                              text: {
                                nl: 'Toediening via infuus in het ziekenhuis (een of twee keer per maand).',
                                en: 'The drug is administered intravenously in the hospital once or twice a month.',
                                se: '',
                                de: ''
                              },
                            },
                            {
                              type: contentType.TEXT,
                              text: {
                                nl: 'Regelmatige controle in het ziekenhuis, waarbij hersenscans worden gemaakt.',
                                en: 'Patients also have attend hospital for regular check-ups that include brain scans.',
                                se: '',
                                de: ''
                              },
                            },
                          ]
                        },
                      ]
                    }
                  ]
                },
                {
                  title: {
                    nl: 'Wat zijn de bijwerkingen?',
                    en: 'What are the side effects?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'Bijwerkingen verschillen tussen personen. Ook zijn misschien niet alle bijwerkingen bekend en weten we niet wat bijwerkingen op de langere termijn kunnen zijn. De arts maakt voor elke patiënt een inschatting van de kans op bijwerkingen.',
                        en: 'People will experience different side effects and not all the possible side effects are yet known to us. We don\'t know what the long-term side effects might be. The doctor will assess the risks of side effects for each patient individually.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.PARAGRAPH,
                      text: {
                        nl: 'In de figuur zie je hoe vaak bijwerkingen ongeveer voorkomen op groepsniveau. Dit is een schatting. Je ziet hier 100 iconen. Elk icoon staat voor 1 mens die het medicijn krijgt. Samen staan deze iconen dus voor 100 mensen die met het medicijn gestart zijn.',
                        en: 'The figure shows how often certain side effects occur at the group level. This is an estimate. Here you see 100 icons. Each icon represents 1 person who receives the medicine. Together these icons represent 100 people who have started receiving the medicine.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.IMAGE,
                      src: {
                        nl: effectsNL,
                        en: effectsEN
                      }
                    },
                    {
                      type: contentType.BULLETLIST,
                      title: {
                        nl: 'Bijwerkingen die voor kunnen komen:',
                        en: 'Possible side effects include:',
                        se: '',
                        de: ''
                      },
                      content: [
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Ongewenste veranderingen in de hersenen die te zien zijn op hersenscans. Deze veranderingen noemen we ook wel amyloïd-gerelateerde beeldvorming afwijkingen (ARIA\'s). Deze veranderingen hoeven niet erg te zijn. Ze worden door een deel van de mensen niet opgemerkt. Er zijn twee typen:',
                            en: 'Undesirable changes to the brain that are visible on brain scans. These changes are also called amyloid-related imaging abnormalities (ARIAs). Such changes are not necessarily serious and some people don\'t even notice them. There are two types:',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.BULLETLIST,
                          content: [
                            {
                              type: contentType.TEXT,
                              text: {
                                nl: 'Zwelling  van de hersenen',
                                en: 'Brain swelling',
                                se: '',
                                de: ''
                              },
                            },
                            {
                              type: contentType.TEXT,
                              text: {
                                nl: 'Kleine bloedingen in de hersenen;',
                                en: 'Bleeding in the brain',
                                se: '',
                                de: ''
                              },
                            },
                          ]
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Uitslag, jeuk en roodheid rondom het infuus;',
                            en: 'Rash, itching and redness around the infusion;',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Hoofdpijn;',
                            en: 'Headache;',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Griep, waaronder rillingen en spierpijn;',
                            en: 'Flu-like symptoms, including chills and muscle pain;',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Misselijkheid en braken;',
                            en: 'Nausea and vomiting;',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Verhoogde bloeddruk; ',
                            en: 'Increased blood pressure; ',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Rugpijn;',
                            en: 'Back pain;',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Duizeligheid.',
                            en: 'Dizziness.',
                            se: '',
                            de: ''
                          },
                        },
                      ]
                    },
                  ]
                },
              ]
            }
          ]
        },
        {
          type: contentType.SECTION,
          id: 'onzekerheid',
          content: [
            {
              type: contentType.TEXT,
              text: {
                nl: '<h2>Onzekerheid</h2>',
                en: '<h2>Uncertainty</h2>',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.PARAGRAPH,
              text: {
                nl: 'De medicijnen zijn nieuw. Ook mogen ze nog niet gegeven worden in Europa, en dus ook niet in Nederland. We moeten nog ervaring gaan opdoen met deze medicijnen in de praktijk. Er is daarom nog veel dat we niet weten.',
                en: 'These drugs are new. They are not yet approved for use in Europe, and therefore cannot yet be given to patients in the Netherlands. We need more practical experience of these medicines and there is still a lot that we don\'t know.',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.ACCORDION,
              items: [
                {
                  title: {
                    nl: 'Wat weten we nog niet?',
                    en: 'What don\'t we know yet?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.ORDEREDLIST,
                      content: [
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<b>Artsen en andere zorgverleners hebben weinig ervaring:</b> De medicijnen zijn nieuw. Nieuw voor patiënten, maar ook voor artsen. Artsen hebben nog weinig ervaring met deze medicijnen. Na goedkeuring in Nederland, zullen zorgverleners/artsen extra tijd besteden aan de eerste patiënten die mogelijk met de medicijnen gaan starten. Zo doen ze stap voor stap ervaring op met deze nieuwe medicijnen.',
                            en: '<b>Doctors and other healthcare providers have little experience:</b> These drugs are new. New for patients, but also new for doctors. Doctors only have limited experience of working with these drugs. Following approval for the Netherlands, healthcare providers/doctors will reserve extra time for the treatment of the first patients who could be starting on the medicines. This will help them gain experience with the new medicines step by step.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<b>Voordelen:</b> Onderzoek heeft laten zien dat de medicijnen werken, maar er is nog meer onderzoek nodig om belangrijke vragen te beantwoorden. Bijvoorbeeld over het effect op lange termijn en over de vraag bij wie de medicijnen het meeste effect hebben.',
                            en: '<b>Benefits:</b> Research has shown that the drugs work, but a lot more research is needed to answer further important questions. For example, the long-term effects and about who will benefit the most from these drugs. ',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<b>Veiligheid:</b> Zoals bij alle medicijnen zijn er mogelijke bijwerkingen. We weten niet zeker wie bijwerkingen krijgt en wie niet. Het is ook onduidelijk wat de bijwerkingen en andere nadelen op de lange termijn zijn.',
                            en: '<b>Safety:</b> As with all medication, there are possible side effects. We don\'t know yet who will get side effects and who won\'t. It is also unclear what kind of side effects or other risks exist in the long term.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<b>Behandelingsduur:</b> Het is nog onduidelijk hoe lang het medicijn gebruikt moet worden. Bijvoorbeeld, of je kunt stoppen na een bepaalde periode, of dat een onderhoudsdosis dan beter is.',
                            en: '<b>Period of treatment:</b> It is still unclear how long the medicine should be used. For example, whether patients should stop after a certain period, or whether further doses are needed.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<b>Goedkeuring, regelgeving en kosten:</b> De medicijnen zijn nog niet goedgekeurd in Europa en in Nederland. Als de medicijnen gegeven mogen worden, is het nog onduidelijk welke regels zullen gelden. Ook weten we nog niet of de medicijnen worden vergoed, voor wie, en wat de kosten zullen zijn.',
                            en: '<b>Approval, rules and costs:</b> The medicines have not yet been approved for use in Europe or the Netherlands. When the medicines are released for use, it is still unclear what kind of rules will apply. We also don\'t yet know what the costs of the drugs will be, whether they will be covered by insurance, and if so for whom.',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: '<b>Andere aandoeningen:</b> Er is nog onduidelijkheid over gebruik van deze medicijnen bij mensen die naast de ziekte van Alzheimer ook andere medische aandoeningen hebben.',
                            en: '<b>Other health problems:</b> It is also still unclear whether people with other health problems in addition to Alzheimer\'s disease will be able to take these drugs.',
                            se: '',
                            de: ''
                          },
                        },
                      ]
                    },
                  ]
                },
              ]
            }
          ]
        },
        {
          type: contentType.SECTION,
          id: 'beslissen',
          content: [
            {
              type: contentType.TEXT,
              text: {
                nl: '<h2>Samen beslissen</h2>',
                en: '<h2>Shared decision-making</h2>',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.PARAGRAPH,
              text: {
                nl: 'De nieuwe medicijnen hebben voordelen, maar ze hebben ook nadelen. Daarom zijn de behoeften van patiënten en hun naaste(n) en hun persoonlijke omstandigheden belangrijk om te beslissen over wel of niet starten met deze nieuwe medicijnen.',
                en: 'The new drugs have advantages (the pros), but they also have disadvantages or risks (the cons). That\'s why the needs of patients and their loved ones, and their personal circumstances, are essential to making the decision about whether to start on these new drugs. ',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.PARAGRAPH,
              text: {
                nl: 'We vatten de mogelijke voor- en nadelen van de medicijnen voor de ziekte van Alzheimer voor u samen:',
                en: 'We summarise the procs and cons of taking these drugs for people with Alzheimer\'s disease here:',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.BULLETLIST,
              title: {
                nl: 'Voordelen van starten met medicijnen:',
                en: 'Pros of starting on these drugs:',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.TEXT,
                  text: {
                    nl: 'Remmen ziekteproces; kans op minder snelle achteruitgang in het denken',
                    en: 'Slows the progression of the disease; possibly slows cognitive decline',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              type: contentType.BULLETLIST,
              title: {
                nl: 'Nadelen van starten met medicijnen:',
                en: 'Cons of starting on these drugs:',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.TEXT,
                  text: {
                    nl: 'Belasting voor patiënt en naaste(n), want er zijn (veel) bezoeken aan het ziekenhuis nodig, waarbij extra hersenscans gemaakt worden.',
                    en: 'Makes demands on patients and their loved ones because many hospital visits are needed, for example for extra brain scans.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.TEXT,
                  text: {
                    nl: 'Kans op bijwerkingen',
                    en: 'Risk of side effects',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              type: contentType.BULLETLIST,
              title: {
                nl: 'Voordelen van niet starten met medicijnen:',
                en: 'Pros of not starting on these drugs:',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.TEXT,
                  text: {
                    nl: 'Geen extra onderzoeken en bezoeken aan ziekenhuis nodig',
                    en: 'No extra medical examinations or visits to the hospital needed',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              type: contentType.BULLETLIST,
              title: {
                nl: 'Nadelen van niet starten met medicijnen:',
                en: 'Cons of not starting on these drugs:',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.TEXT,
                  text: {
                    nl: 'De ziekte wordt niet geremd',
                    en: 'No potential slowing of the disease',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              type: contentType.ACCORDION,
              items: [
                {
                  title: {
                    nl: 'Wie bepaalt wat?',
                    en: 'Who decides what?',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.TEXT,
                      text: {
                        nl: 'De arts bepaalt eerst of iemand het medicijn mag krijgen. Als dat zo is, gaan arts, patiënt en diens naaste(n) in gesprek. Dit proces noemen we \'samen beslissen\'. Zie ook de pagina <a href="/nl/informatie/diagnostiek">Samen beslissen</a>.',
                        en: 'First the doctor decides whether someone is a good candidate for the drug. If so, the doctor, patient and loved ones will have a meeting. We call this process \'shared decision-making\'. See also the page <a href="/en/informatie/diagnostiek">Shared decision-making</a>.',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.BULLETLIST,
                      title: {
                        nl: 'In een gesprek:',
                        en: 'At the meeting:',
                        se: '',
                        de: ''
                      },
                      content: [
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Legt de arts uit dat er een keuze moet worden gemaakt, waarin de wensen, behoeften en omstandigheden van de patiënt en diens naaste(n) belangrijk zijn;',
                            en: 'The doctor will explain how the preferences, needs and circumstances of the patient and their loved ones are essential to the decision whether to start on the medicine;',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Geeft de arts informatie over de medicatie, over wat er anders mogelijk is, en over de voor- en nadelen van starten met het medicijn en van niet starten met het medicijn;',
                            en: 'The doctor will provide information about the medicine, about what else is available, and about the benefits and risks of starting or not starting on the medicine;',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Laten de patiënt en naaste(n) weten hoe zij over de voordelen en nadelen denken, wat zij willen, en of er nog vragen zijn;',
                            en: 'The patient and loved ones will explain how they feel about the pros and cons, what they would like, and have a chance to ask questions;',
                            se: '',
                            de: ''
                          },
                        },
                        {
                          type: contentType.TEXT,
                          text: {
                            nl: 'Wordt een keuze gemaakt over wel of niet starten met medicijnen. Dat kan eventueel ook in een tweede gesprek. Patiënt en naaste kunnen een duidelijke voorkeur hebben en zelf de beslissing willen nemen. Patiënt en naaste kunnen ook met de arts bespreken wat zij ervan vinden, maar de keuze vervolgens aan de arts over laten. Uiteindelijk blijft de arts altijd eindverantwoordelijk voor het genomen besluit. Ook kan de arts (mogelijk samen met de patiënt) beslissen te stoppen met de medicijnen. Bijvoorbeeld als de belasting te groot blijkt of er bijwerkingen zijn.',
                            en: 'A decision will be made about whether to start on the medicine. This may also take place at a second meeting. Patients and their loved ones may have their own clear preference and want to make the decision themselves. They can also discuss their feelings with the doctor and leave the decision to the doctor. The doctor remains responsible for the decision. The doctor can also decide to stop treatment, and may do so in consultation with the patient. For example, if the demands are too great or if the side effects too severe.',
                            se: '',
                            de: ''
                          },
                        },
                      ]
                    },
                  ]
                },
              ]
            }
          ]
        },
      ]
    },
    diagnostiek: {
      title: {
        nl: 'Samen beslissen',
        en: 'Shared decision-making',
        se: '',
        de: ''
      },
      intro: {
        nl: 'Op de geheugenpoli worden er keuzes gemaakt. Er zijn bijvoorbeeld verschillende diagnostische testen, waarover beslissingen moeten worden genomen.',
        en: 'At the memory clinic decisions will have to be made. For example, there are a number of different diagnostic tests that require decisions.',
        se: '',
        de: ''
      },
      content: [
        {
          type: contentType.BULLETLIST,
          title: {
            nl: 'Dit gaat om:',
            en: 'We are thinking here of:',
            se: '',
            de: ''
          },
          content: [
            {
              type: contentType.TEXT,
              text: {
                nl: 'Het besluit om wel of om niet te testen.',
                en: 'The decision whether to have tests.',
                se: '',
                de: ''
              },
            },
            {
              type: contentType.TEXT,
              text: {
                nl: 'Welke diagnostische tests worden ingezet.',
                en: 'The decision about which diagnostic tests should be administered.',
                se: '',
                de: ''
              },
            },
          ]
        },
        {
          type: contentType.TEXT,
          text: {
            nl: 'De keuze kan ook gaan over wel of niet behandelen. Zo moeten er in de toekomst mogelijk keuzes worden gemaakt over het wel of niet starten met ziekte-remmende medicatie bij de ziekte van Alzheimer.',
            en: 'The decision whether to undergo treatment may also arise, for example about whether to start on treatment that slows the progression of Alzheimer\'s disease.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.TEXT,
          text: {
            nl: 'Het uitgangspunt voor zorgverleners is om zoveel mogelijk aan te sluiten bij de dementie richtlijn. Het is ook belangrijk om patiënten te informeren over de relevante mogelijkheden en te betrekken in de besluitvorming. Samen beslissen is vooral belangrijk bij voorkeursgevoelige beslissingen, waarbij het medisch oogpunt niet duidelijk is welke opties te beste is, bijvoorbeeld omdat er meerdere (min of meer) gelijkwaardige opties zijn.',
            en: 'The fundamental concern for healthcare providers is acting as much as possible in line with the clinical guidelines for dementia. It is also important to inform patients about the relevant options and to involve them in decision-making. Shared decision-making is especially important when decisions deal with preferences and where from a medical standpoint it is unclear what the best option might be, i.e., there are multiple similar options.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.TEXT,
          text: {
            nl: 'Hieronder vindt u enkele handvatten voor samen beslissen opgedeeld in vier stappen.',
            en: 'A tool for shared-decision making testing with four steps is provided below.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.ORDEREDACCORDION,
          items: [
            {
              title: {
                nl: 'Keuzebewustzijn creëren',
                en: 'Creating choice awareness',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Agenderen dat een beslissing moet worden genomen (bijv. over diagnostisch testen) en dat er meerdere opties zijn, waarbij de keuze in meer of mindere mate afhankelijk is van de wensen van de patiënt.',
                    en: 'Let the patient know that decisions need to be made (e.g., about diagnostic testing). Explain that there are various options, and that the choices made will depend partly on the patient\'s situation and preferences.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Voorbeeld: \'Het doel is dat we (samen) besluiten welk diagnostische testen we het beste kunnen doen.\'',
                    en: 'Example: \'The idea is that we decide (together) which diagnostic tests are best to do in your situation.\'',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              title: {
                nl: 'Informeren',
                en: 'Informing',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: "Allereerst, het uitvragen van de informatievoorkeur van de patiënt. Voorbeeld: 'Over de verschillende testen valt veel te vertellen. Sommige patiënten willen daar graag zoveel mogelijk over horen, en anderen willen liever niet te veel weten. Hoe is dat voor u?'",
                    en: 'First, find out what type and how much information the patient wants. Example: \'There\'s a lot I could tell you about the various tests. Some patients want the full picture, while others want to keep it to the basics. What about you?\'',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.BULLETLIST,
                  title: {
                    nl: 'Vervolgens, informeren over:',
                    en: 'Next, inform the patient about:',
                    se: '',
                    de: ''
                  },
                  content: [
                    {
                      type: contentType.TEXT,
                      text: {
                        nl: 'De (diagnostische) mogelijkheden/opties: \'Wat zou u willen weten over de mogelijkheden voor verder diagnostisch testen?\' of \'Er zijn drie mogelijkheden, namelijk...\'',
                        en: 'The (diagnostic test) options: \'What would you like to know about the additional diagnostic tests?\' or \'There are three options. They are...\'',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.TEXT,
                      text: {
                        nl: 'De voor- en nadelen op algemeen niveau of per optie: \'Wilt u meer weten over de voor- en nadelen van die mogelijkheden?\'',
                        en: 'Set out the pros and cons, either generally or for each option: \'Shall I explain the pros and cons of those options?\'',
                        se: '',
                        de: ''
                      },
                    },
                    {
                      type: contentType.TEXT,
                      text: {
                        nl: 'Het resultaat dat verwacht kan worden: \'Zal ik u iets vertellen over het soort uitslag dat u kunt verwachten met deze testen?\'',
                        en: 'The possible results: \'Shall I explain what the tests can tell us?\'',
                        se: '',
                        de: ''
                      },
                    },
                  ]
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Meer lezen? <a href="/nl/informatie/diagnostischetesten">Hier</a> vindt u een overzicht met mogelijkheden voor verschillende diagnostisch testen en voor iedere mogelijkheid de voor- en nadelen.',
                    en: 'Want more detail? An overview of the various diagnostic tests and their pros and cons is available <a href="/en/informatie/diagnostischetesten">here</a>.',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              title: {
                nl: 'Wensen verkennen',
                en: 'Exploring preferences and needs',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Allereerst, verkennen van de wensen en overwegingen van de patiënt.',
                    en: 'First, talk about the patient\'s preferences, needs and considerations.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: "Voorbeeld: 'Nu ik u heb verteld over de mogelijkheden, hoe kijkt u hier tegenaan?",
                    en: "Example: \'How do you feel about the options I've described?\'",
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Vervolgens, uitvragen van de beslisvoorkeur van de patiënt. Wil de patiënt samen beslissen of volgt de patiënt graag het advies van de zorgverlener?',
                    en: 'Next, ask the patient how involved in decision-making they want to be.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Voorbeeld: \'Sommige mensen willen graag mee beslissen over diagnostisch testen, anderen laten dat liever aan de zorgverlener over. Hoe staat u daarin?\'',
                    en: 'Example: \'Some people like to be involved in deciding what diagnostic tests to have, but others prefer to leave it to their doctor. What about you?\'',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              title: {
                nl: 'Besluiten',
                en: 'Decision-making',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Samen beslissen, dat wil zeggen, een weloverwogen beslissing nemen waar beide partijen achter staan, en dit ook communiceren.',
                    en: 'Arrive at a shared decision that\'s been thought through and that both of you are happy with. Make sure that the patient knows what\'s been decided.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Voorbeeld: \'Dus we besluiten om [X], omdat u aangeeft dat [overwegingen patiënt].\'',
                    en: 'Example: \'So, we agree that the best thing to do is [X], because you feel that [patient\'s considerations].\'',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Als het de wens van de patiënt is om een besluit te nemen op advies van de zorgverlener, is het zaak een advies te formuleren waarin u de persoonlijke omstandigheden en behoeften van de patiënt meeweegt, en dit ook zo te communiceren.',
                    en: "If it's the patient's wish to follow the clinician's advice, it's important to take the patient's preferences and needs in concideration when formulating the advice, and do so when communicating with the patient.",
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Voorbeeld: \'Dan stel ik [X] voor, op basis van [omstandigheden/behoeften patiënt, richtlijnen, ervaring of voorkeur van de zorgverlener]. Kunt u zich daarin vinden? … Dan gaan we dat in gang zetten.\'',
                    en: 'Example: \'In that case, I propose [X], because [patient\'s preferences, guidelines, doctor\'s experience/preference]. Are you okay with that? … Then I\'ll set things in motion.\'',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
          ]
        }
      ]
    },
    kansBerekenen: {
      title: {
        nl: 'Rekentools',
        en: 'Calculation tools',
        se: '',
        de: ''
      },
      intro: {
        nl: 'Voor artsen en andere zorgverleners ontwikkelden we twee rekentools, beiden met een uitlegpagina die kan worden gebruikt ter ondersteuning van het gesprek met de patiënt. Deze tools zijn momenteel beschikbaar ter inzage. Toegang aanvragen voor deze tools doet u <a href="/nl/tools">hier</a>.',
        en: 'We have developed two calculation tools for medical doctors and other healthcare providers, with an explanation page that can be used to support patient discussions. At the moment, these tools are only available for viewing and you can request access to these tools <a href="/en/tools">here</a>.',
        se: '',
        de: ''
      },
      content: [
        {
          type: contentType.ACCORDION,
          items: [
            {
              title: {
                nl: 'Rekentool \'Dementierisico bij MCI\'',
                en: '\'Dementia risk with MCI\' calculation tool',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Met behulp van de rekentool \'Dementierisico bij MCI\' berekent u wat de testuitslagen betekenen voor het dementierisico van een individuele MCI patiënt. Deze tool geeft een schatting van de kans dat een patiënt die zich op de geheugenpoli presenteert met milde cognitieve stoornissen (MCI) binnen 1, 3 en 5 jaar dementie ontwikkelt.',
                    en: 'With the aid of the calculation tool \'Dementia risk with MCI\' you can calculate what the test results mean in terms of the risk of dementia for an individual MCI patient. The risk calculation tool can be used to estimate the risk that a patient who presents at the memory clinic with mild cognitive impairment (MCI) will develop dementia within one, three and five years.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Deze schatting wordt gemaakt op basis van leeftijd, geslacht en MMSE-score (de kans zonder aanvullende diagnostische tests). Met gegevens over de MRI en eiwitmarkers in hersenvocht en/of amyloid PET volgt een aangepaste schatting van de kans op dementie.',
                    en: 'The estimate is based on age, sex and MMSE score (risk as estimated without data from additional diagnostic tests). By additionally entering MRI findings, CSF biomarker data, and/or amyloid PET, an adjusted estimate of the individual\'s dementia risk can be obtained.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.IMAGE,
                  src: rekentool1
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Binnen de tool wordt ook een uitslagpagina voor de patiënt gecreëerd, met een toelichting op de uitslag van elke test en het totaal van de testen. De uitslagpagina is samen met patiënten en artsen ontwikkeld. Afhankelijk van de informatievoorkeuren van de patiënt kunt u kiezen voor verschillende visuele weergaven van de geschatte kans op dementie.',
                    en: "Within the tool, a result page is also created for the patient, detailing the findings of each test and the overall conclusion. The result page has been developed in consultation with patients and professionals. Various graphical display options can be enabled, in line with the patient's information preferences.",
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.IMAGE,
                  src: rekentool2
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Achtergrondinformatie over het predictiemodel vindt u hier: <a href="/nl/informatie/overons">Publicaties</a>.',
                    en: 'Background information about the prediction model is available here: <a href="/en/informatie/overons">Publications</a>.',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              title: {
                nl: 'Rekentool \'Ziekteverloop en behandeleffect\'',
                en: '\'Disease progression and treatment effect\' calculation tool',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Met deze tool berekent u de verwachte cognitieve achteruitgang van uw patiënt met MCI of milde dementie door de ziekte van Alzheimer.',
                    en: 'With this tool you can calculate the expected cognitive decline of your patient with MCI or mild dementia caused by Alzheimer\'s disease.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Twee verschillende visuele weergaven maken de persoonlijke schatting van het ziekteverloop inzichtelijk. De voorspelling wordt gemaakt op basis van leeftijd, geslacht, syndroom diagnose en eiwitmarkers in het hersenvocht (ruggenprik). Een lijngrafiek toont u de verwachte achteruitgang in cognitie (MMSE) over een periode van 5 jaar. Ook kunt u de tijd berekenen tot een door u gekozen MMSE eindpunt (Tijd-tot-grafiek).',
                    en: 'Two different visualisations show the estimated disease progression and can help explain it to your patient. The prediction is based on age, sex, MMSE, syndrome diagnosis and the presence of disease in the brain (lumbar puncture/CSF). A line chart shows you the expected cognitive decline (MMSE) over a period of 5 years. You can also calculate the time to your own chosen MMSE endpoint (Time-to chart).',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'In beide visuele weergaven plot u vervolgens eenvoudig het mogelijke effect van een behandeling die het ziekteverloop zou vertragen (zoals ziekte-remmende medicatie). Binnen de tool wordt ook een uitlegpagina gecreëerd, die gebruikt kan worden ter ondersteuning van het gesprek met de patiënt.',
                    en: 'For both visualisations you can then easily plot the potential effect of a treatment that aims to slow disease progression (e.g. disease-modifying drugs). The tool will also create an explanation page, which can be used to support your discussions with the patient.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Achtergrondinformatie over het predictiemodel vindt u hier: <a href="/nl/informatie/overons">Publicaties</a>.',
                    en: 'Background information about the prediction model can be found here: <a href="/en/informatie/overons">Publications</a>.',
                    se: '',
                    de: ''
                  },
                },
              ]
            }
          ]
        }
      ]
    },
    veelgesteldeVragen: {
      title: {
        nl: 'Veelgestelde vragen',
        en: 'Frequently asked questions',
        se: '',
        de: ''
      },
      content: [
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Wat is de ziekte van Alzheimer?</h2>Veel van de informatie op ADappt gaat over de ziekte van Alzheimer. De ziekte van Alzheimer is een hersenziekte. Het is de meest voorkomende oorzaak van dementie. De alzheimer hersenschade kunnen we aantonen met specifieke diagnostische tests. Door de ziekte van Alzheimer gaat het denkvermogen langzaam achteruit. Dit gaat bijvoorbeeld om het geheugen, aandacht en concentratie, en logisch redeneren. U leest <a href="https://www.alzheimer-nederland.nl/dementie/soorten-vormen/ziekte-van-alzheimer" rel=”nofollow” target="_blank">hier</a> meer over de ziekte van Alzheimer.',
            en: '<h2>What is Alzheimer\'s disease?</h2>Much of the information on ADappt deals with Alzheimer\'s disease. Alzheimer\'s is a disease of the brain and it is the most common cause of dementia. Damage to the brain caused by Alzheimer\'s is identified with diagnostic tests. People with Alzheimer\'s experience slow decline in their thinking abilities. Their ability to remember, concentrate and reason logically deteriorates over time. You can read <a href="https://www.alzheimers.org.uk/about-dementia/types-dementia/alzheimers-disease" rel=”nofollow” target="_blank">here</a> more about Alzheimer\'s disease.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Welke informatie en tools zijn op ADappt te vinden?</h2>Op de geheugenpoli wisselen zorgverleners, patiënten en naasten veel informatie uit. Om patiënten en naasten te helpen om zich voor te bereiden op hun bezoek aan de poli, ontwikkelden we verschillende hulpmiddelen. Op de pagina <a href="/nl/informatie/topiclijst">diagnose</a> vindt u twee video\'s over wat er gebeurt op de poli en een lijst met voorbeeldvragen om te stellen aan de zorgverlener.',
            en: '<h2>What information and tools can be found on ADappt?</h2>At the memory clinic, healthcare providers, patients and their families exchange a lot of information. To help patients and families prepare for their visit to the clinic, we have developed a range of tools. On the <a href="/en/informatie/topiclijst">diagnosis</a> page you can find two videos about what happens at the clinic and a list with sample questions you can ask the doctor.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: 'Op de geheugenpoli zijn verschillende keuzes te maken. Bijvoorbeeld wel of geen diagnostiek, en zo ja – welke diagnostische testen, of over behandeling. Om keuzes te kunnen maken, is goede informatie nodig. Op de pagina <a href="/nl/informatie/diagnostischetesten">diagnostische testen</a> vindt u uitleg over verschillende diagnostische testen met voor- en nadelen. Op de pagina <a href="/nl/informatie/behandeling">behandeling</a> vindt u uitleg over nieuwe medicijnen tegen de ziekte van Alzheimer, die in de toekomst mogelijk ook in Europa beschikbaar komen. De pagina <a href="/nl/informatie/diagnostiek">samen beslissen</a> geeft tips voor de gesprekken tussen arts en patiënt over diagnose en behandeling.',
            en: 'People who come to the memory clinic have to make certain decisions. For example, about whether to pursue diagnosis, which diagnostic tests to have, and whether to undergo treatment. Good information is essential to making the right decisions. On the <a href="/en/informatie/diagnostischetesten">diagnostic tests</a> page you can find explanations of the different diagnostic tests with their benefits and risks. On the <a href="/en/informatie/behandeling">treatment</a> page you can find an explanation of the new medicines for Alzheimer\'s disease that may also soon become available in Europe. The Shared <a href="/en/informatie/diagnostiek">decision-making</a> page has tips for the discussions between doctors and patients about diagnosis and treatment.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: 'Voor artsen en andere zorgverleners zijn er daarnaast twee rekentools beschikbaar. Met behulp van de tool <a href="/nl/predictie_tool">Dementierisico bij MCI</a> kan een zorgverlener eenvoudig berekenen wat diverse testuitslagen betekenen voor het dementierisico van MCI patiënten. De rekentool <a href="/nl/medicatie_tool">Ziekteverloop en behandeleffect</a> biedt ondersteuning bij het voorspellen van cognitieve achteruitgang bij patiënten met MCI en milde dementie door de ziekte van Alzheimer, en het uitleggen van het mogelijke behandeleffect.',
            en: 'For doctors and other healthcare providers there are also two calculation tools available. With the aid of the <a href="/en/predictie_tool">Dementia risk with MCI tool</a>, a healthcare provider can easily calculate how different test results affect the dementia risk of MCI patients. The  <a href="/en/predictie_tool">Disease progression and treatment effect tool</a> supports prediction of cognitive decline for patients with MCI and mild dementia caused by Alzheimer\'s disease, and explains the possible effects of treatment.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Hoe kan ik inloggegevens aanvragen voor de rekentools?</h2>De ADappt rekentools zijn momenteel beschikbaar voor inzage voor zorgverleners. <a href="/nl/tools">Hier</a> kunt u inloggegevens aanvragen.',
            en: '<h2>How can I request log-in details for the calculation tools?</h2>Currently the ADappt calculation tools are only available for viewing by healthcare providers. You can request log-in details <a href="/en/tools">here</a>.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Door wie is ADappt ontwikkeld?</h2>De ADappt website en rekentools zijn ontwikkeld op basis van resultaten uit de wetenschappelijk onderzoeksprojecten ABIDE en ABOARD. Onderzoekers van het Amsterdam UMC werkten voor het ontwikkelen van ADappt samen met andere partners, zoals Vilans. ABIDE en ABOARD zijn publiek-private projecten gefinancierd door ZonMW, Health Holland en Alzheimer Nederland in de context van de Nationale Dementie Strategie. Klik <a href="/nl/informatie/overons">hier</a> voor meer informatie over het team.',
            en: '<h2>Who is behind ADappt?</h2>The ADappt website and calculation tools were developed based on the results of scientific research projects called ABIDE and ABOARD. Researchers from Amsterdam UMC worked together with other partners like Vilans to develop ADappt. ABIDE and ABOARD are public-private projects funded by ZonMw, Health~Holland and Alzheimer Netherlands in the context of the National Dementia Strategy. Click <a href="/en/informatie/overons">here</a>  for more information about the team.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Waar lees ik meer over het onderzoek waarop ADappt is gebaseerd?</h2>De ADappt website en rekentools zijn ontwikkeld op basis van resultaten uit de wetenschappelijk onderzoeksprojecten ABIDE en ABOARD. Meer informatie kunt u vinden in de wetenschappelijke publicaties die we <a href="/nl/informatie/overons">hier</a> voor u op een rijtje zetten.',
            en: '<h2>Where can I read more about the studies on which ADappt is based?</h2>The ADappt website and calculation tools were developed based on the results of scientific research projects called ABIDE and ABOARD. You can find more information in the scholarly publications that we have collected for you <a href="/en/informatie/overons">here</a>.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Hoe kom ik in contact met het ADappt team?</h2>Heeft u opmerkingen of vragen over ADappt? Vult u dan het contactformulier in. Dat vindt u <a href="/nl/informatie/contact">hier</a>.',
            en: '<h2>How can I contact the ADappt team?</h2>Any comments or questions about ADappt? Please fill in the contact form. You can find the form <a href="/en/informatie/contact">here</a>.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Hoe kan ik bijdragen aan wetenschappelijk onderzoek?</h2>We weten steeds meer over alzheimer, maar er zijn ook nog veel vragen onbeantwoord. Zo kunnen we de ziekte nog niet stoppen of genezen, of beter nog - voorkómen. Daarvoor is wetenschappelijk onderzoek nodig. Zonder deelnemers geen vooruitgang in de wetenschap. Interesse om mee te doen aan wetenschappelijk onderzoek? <a href="/nl/informatie/meedoenaanonderzoek">Hier</a> leest u meer.',
            en: '<h2>How can I contribute to scientific research?</h2>We continue to learn about Alzheimer\'s, but many questions still remain unanswered. We cannot yet stop or cure the disease, or prevent it. We need to do more research, but without participants, science cannot move forward. Are you interested in participating in a scientific study? You can read more <a href="/en/informatie/meedoenaanonderzoek">here</a>.',
            se: '',
            de: ''
          },
        },
      ]
    },
    meedoenAanOnderzoek: {
      title: {
        nl: 'Meedoen aan onderzoek',
        en: 'Taking part in scientific research',
        se: '',
        de: ''
      },
      content: [
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Wilt u helpen?</h2>We weten steeds meer over alzheimer, maar er zijn ook nog veel vragen onbeantwoord. Zo kunnen we de ziekte nog niet stoppen of genezen, of beter nog – voorkómen. Daarvoor is wetenschappelijk onderzoek nodig. Zonder deelnemers geen vooruitgang in de wetenschap. Interesse om mee te doen aan wetenschappelijk onderzoek? Dit zijn enkele mogelijkheden.',
            en: '<h2>Do you want to help?</h2>We continue to learn about Alzheimer\'s, but many questions remain unanswered. We cannot yet stop or cure the disease, or prevent it. We need to do more research but without participants, science cannot progress. Are you interested in participating in a scientific study? Here are a few possibilities.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.ACCORDION,
          items: [
            {
              title: {
                nl: 'ABOARD-cohort',
                en: 'ABOARD Cohort',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Het ABOARD Cohort is een landelijk onderzoek met als doel om meer inzicht te krijgen in het verloop van gezonde veroudering tot en met gevorderde alzheimer. Dit doen wij door het verzamelen van medische gegevens en vragenlijsten over de ervaringen van mensen met en zonder geheugenklachten en hun naasten. Iedereen vanaf 45 jaar kan meedoen.',
                    en: 'The ABOARD Cohort is a nationwide study aimed at generating greater insight into the progression from healthy aging through to advanced Alzheimer\'s. We achieve this by collecting medical data and using questionnaires to find out about the experiences of people with and without memory problems and their families. Everyone aged 45 or over can participate.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: '<a href="https://www.alzheimer-nederland.nl/onderzoek/projecten/aboard/uurtje-voor-alzheimer" rel="nofollow" target="_blank">Hier</a> lees je meer over het onderzoek.',
                    en: 'You can read more about our study <a href="https://www.alzheimer-nederland.nl/onderzoek/projecten/aboard/uurtje-voor-alzheimer" rel="nofollow" target="_blank">here</a>.',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
            {
              title: {
                nl: 'Hersenonderzoek.nl',
                en: 'Brain research website Hersenonderzoek.nl',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Niet alleen voor alzheimer, ook voor andere hersenziektes is onderzoek hard nodig. Goed onderzoek kan niet zonder voldoende deelnemers. Help ook mee! Iedereen van 18 jaar en ouder kan zich inschrijven via <a href="https://hersenonderzoek.nl/" ref="nofollow" target="_blank">www.hersenonderzoek.nl</a>.',
                    en: 'Research is badly needed, not just for Alzheimer\'s, but also for other brain disorders. Good research is impossible without sufficient participants. Will you help us? Everyone aged 18 and over can register via <a href="https://hersenonderzoek.nl/" ref="nofollow" target="_blank">www.hersenonderzoek.nl</a>.',
                    se: '',
                    de: ''
                  },
                },
                {
                  type: contentType.PARAGRAPH,
                  text: {
                    nl: 'Dit kan als u kerngezond bent én als u een diagnose hebt. Help mee met het vinden van oplossingen voor dementie, depressie en andere hersenziekten!',
                    en: 'We need both people who are healthy and those who have already been diagnosed. Will you help us find solutions for people with dementia, depression and other brain disorders?',
                    se: '',
                    de: ''
                  },
                },
              ]
            },
          ]
        }
      ]
    },
    overOns: {
      title: {
        nl: 'Over ADappt',
        en: 'About ADappt',
        se: '',
        de: ''
      },
      content: [
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Over ADappt</h2>De ADappt website en rekentools zijn ontwikkeld op basis van resultaten uit de wetenschappelijk onderzoeksprojecten ABIDE en ABOARD. ABOARD is een grootschalig onderzoeksproject dat wordt uitgevoerd door een landelijk, multidisciplinair consortium. ABOARD, voluit A Personalized Medicine Approach for Alzheimer\'s Disease, werkt aan een toekomst met gepersonaliseerde diagnostiek, predictie en preventie van Alzheimer. ABOARD is gestart per 1 april 2021 en heeft een looptijd van 5 jaar. Prof. dr. Wiesje van der Flier is projectleider van ABOARD. U leest <a href="http://www.aboard-project.nl" rel="nofollow" target="_blank">hier</a> meer over ABOARD.',
            en: "<h2>About ADappt</h2>The ADappt website, including the risk calculation tools, was developed in the Netherlands, based on results of the ABIDE and ABOARD research projects. ABOARD is a large-scale research project carried out by a national, multidisciplinary consortium. ABOARD, in full A Personalized Medicine Approach for Alzheimer's Disease, is working on a future with personalized diagnosis, prediction and prevention of Alzheimer's. ABOARD started on April 1, 2021 and has a term of 5 years. Prof. dr. Wiesje van der Flier is project leader of ABOARD. More information on ABOARD can be found <a href='http://www.aboard-project.nl' rel='nofollow' target='_blank'>here</a>.",
            se: '',
            de: ''
          },
        },
        {
          type: contentType.IMAGE,
          src: {
            nl: overAdappt,
            en: overAdapptEn
          },
          width: 200,
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: 'Onderzoekers van het Amsterdam UMC werkten voor het ontwikkelen van ADappt samen met andere partners, zoals Vilans. ABIDE en ABOARD zijn publiek-private projecten gefinancierd door ZonMW, Health Holland en Alzheimer Nederland in de context van de Nationale Dementie Strategie.',
            en: 'Researchers from Amsterdam UMC worked together with other partners like Vilans to develop ADappt. ABIDE and ABOARD are public-private projects funded by ZonMw, Health~Holland and Alzheimer Netherlands in the context of the Dutch National Dementia Strategy.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.TEAM
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: '<h2>Publicaties</h2>',
            en: '<h2>Publications</h2>',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.PARAGRAPH,
          text: {
            nl: 'Meer informatie kunt u vinden in onderstaande publicaties.',
            en: 'You can find more information in the scholarly publications below.',
            se: '',
            de: ''
          },
        },
        {
          type: contentType.ORDEREDACCORDION,
          items: [
            {
              title: {
                nl: 'Predictiemodellen',
                en: 'Prediction models',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: 'van Maurik IS , Vos SJ , Bos I , Bouwman FH , Teunissen CE , Scheltens P , et al. Biomarker-based prognosis for people with mild cognitive impairment (ABIDE): a modelling study. Lancet Neurol. 2019; 18: 1034–44. <a href="https://doi.org/10.1016/S1474-4422(19)30283-2" rel="nofollow" target="_blank">https://doi.org/10.1016/S1474-4422(19)30283-2</a>'
                },
                {
                  type: contentType.PARAGRAPH,
                  text: 'van Maurik IS, van der Kall LM, de Wilde A, et al. Added value of amyloid PET in individualized risk predictions for MCI patients. Alzheimers Dement (Amst). 2019;11:529‐537. <a href="https://doi.org/10.1016/j.dadm.2019.04.011" rel="nofollow" target="_blank">https://doi.org/10.1016/j.dadm.2019.04.011</a>'
                },
                {
                  type: contentType.PARAGRAPH,
                  text: 'van der Veere, P. J., Hoogland, J., Visser, L. N., Van Harten, A. C., Rhodius-Meester, H. F., Sikkes, S. A., ... & Van Der Flier, W. M. (2024). Predicting Cognitive Decline in Amyloid-Positive Patients With Mild Cognitive Impairment or Mild Dementia. Neurology, 103(3), e209605. <a href="https://doi.org/10.1212/WNL.0000000000209605" rel="nofollow" target="_blank">https://doi.org/10.1212/WNL.0000000000209605</a>'
                },
              ]
            },
            {
              title: {
                nl: 'Arts-patiënt communicatie',
                en: 'Clinician-patient communication',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: "van der Flier WM, Kunneman M, Bouwman FH, Petersen RC, Smets EMA. Diagnostic dilemmas in Alzheimer's disease: Room for shared decision making. Alzheimers Dement (N Y). 2017;3:301-4. <a href='https://doi.org/10.1016/j.trci.2017.03.008' rel='nofollow' target='_blank'>https://doi.org/10.1016/j.trci.2017.03.008</a>"
                },
                {
                  type: contentType.PARAGRAPH,
                  text: "Visser LNC, Kunneman M, Murugesu L, van Maurik I, Zwan M, Bouwman FH, et al. Clinician-patient communication during the diagnostic workup: The ABIDE project. Alzheimer's & Dementia: Diagnosis, Assessment & Disease Monitoring. 2019;11:520-8. <a href='https://doi.org/10.1016/j.dadm.2019.06.001' rel='nofollow' target='_blank'>https://doi.org/10.1016/j.dadm.2019.06.001</a>"
                },
                {
                  type: contentType.PARAGRAPH,
                  text: "Fruijtier AD, Visser LNC, van Maurik IS, Zwan MD, Bouwman FH, van der Flier WM, et al. ABIDE Delphi study: topics to discuss in diagnostic consultations in memory clinics. Alzheimers Res Ther. 2019;11:77. <a href='https://doi.org/10.1186/s13195-019-0531-y' rel='nofollow' target='_blank'>https://doi.org/10.1186/s13195-019-0531-y</a>"
                },
                {
                  type: contentType.PARAGRAPH,
                  text: "Visser LN, van Maurik IS, Bouwman FH, et al. Clinicians\' communication with patients receiving a MCI diagnosis: the ABIDE project. PloS One. 2020; 15(1): e0227282. <a href='https://doi.org/10.1371/journal.pone.0227282' rel='nofollow' target='_blank'>https://doi.org/10.1371/journal.pone.0227282</a>"
                },
                {
                  type: contentType.PARAGRAPH,
                  text: "Hendriksen, H.M.A., van Gils, A.M., van Harten, A.C. et al. Communication about diagnosis, prognosis, and prevention in the memory clinic: perspectives of European memory clinic professionals. Alz Res Therapy 15, 131 (2023). <a href='https://doi.org/10.1186/s13195-023-01276-9' rel='nofollow' target='_blank'>https://doi.org/10.1186/s13195-023-01276-9</a>"
                },
              ]
            },
            {
              title: {
                nl: 'Andere relevante publicaties',
                en: 'Other relevant publications',
                se: '',
                de: ''
              },
              content: [
                {
                  type: contentType.PARAGRAPH,
                  text: "Dreves MAE, van Harten AC, Visser LNC, Rhodius-Meester H, Köhler S, Kooistra M, Papma JM, Honey MIJ, Blom MM, Smets EMA, de Vugt ME, Teunissen CE, van der Flier WM; ABOARD Consortium. Rationale and design of the ABOARD project (A Personalized Medicine Approach for Alzheimer's Disease). Alzheimers Dement (N Y). 2023 Jun 5;9(2):e12401. <a href='https://doi.org/10.1002/trc2.12401' rel='nofollow' target='_blank'>https://doi.org/10.1002/trc2.12401</a>"
                },
                {
                  type: contentType.PARAGRAPH,
                  text: "Babapour Mofrad R, Bouwman FH, Slot RER, Timmers T, van der Flier WM, Scheltens P, Teunissen CE. Lumbar puncture in patients with neurologic conditions. Alzheimers Dement (Amst). 2017;8:108-110. <a href='https://doi.org/10.1016/j.dadm.2017.04.008' rel='nofollow' target='_blank'>https://doi.org/10.1016/j.dadm.2017.04.008</a>"
                },
                {
                  type: contentType.PARAGRAPH,
                  text: "Babapour Mofrad R, Visser LNC, Fruijtier AD, Scheltens P, Smets EEMA, van der Flier WM, Teunissen CE. Cerebrospinal fluid collection: An informative animation  video for patients and caregivers. Alzheimers Dement (Amst). 2019;11:435-438. <a href='https://doi.org/10.1016/j.dadm.2019.04.005' rel='nofollow' target='_blank'>https://doi.org/10.1016/j.dadm.2019.04.005</a>"
                },
                {
                  type: contentType.PARAGRAPH,
                  text: "de Wilde A, van Maurik IS, Kunneman M, Bouwman F, Zwan M, Willemse EA, et al. Alzheimer's biomarkers in daily practice (ABIDE) project: Rationale and design. Alzheimers Dement (Amst). 2017;6:143-151. <a href='https://doi.org/10.1016/j.dadm.2017.01.003' rel='nofollow' target='_blank'>https://doi.org/10.1016/j.dadm.2017.01.003</a>"
                },
                {
                  type: contentType.PARAGRAPH,
                  text: "van Maurik IS, Zwan MD, Tijms BM, Bouwman FH, Teunissen CE, Scheltens P, et al. Interpreting Biomarker Results in Individual Patients With Mild Cognitive Impairment in the Alzheimer's Biomarkers in Daily Practice (ABIDE) Project. JAMA Neurol. 2017;74:1481-91. <a href='https://doi.org/10.1001/jamaneurol.2017.2712' rel='nofollow' target='_blank'>https://doi.org/10.1001/jamaneurol.2017.2712003</a>"
                },
              ]
            },
          ]
        }
      ]
    },
    ...globalElements
  },
  medicatie_tool: {
    graphsContent: {
      effectsToggle: {
        nl: 'Toon behandel effect in grafieken',
        en: 'Show treatment effects in graphs'
      },
      lineGraph: {
        title: {
          nl: 'Cognitieve achteruitgang',
          en: 'Cognitive decline'
        },
        xLabel: {
          nl: 'Tijd in jaren',
          en: 'Time in years'
        },
        yLabel: {
          nl: 'MMSE score',
          en: 'MMSE score'
        },
        legend: {
          average: {
            nl: 'Gemiddelde achteruitgang in cognitie (MMSE)',
            en: 'Average cognitive decline (MMSE)'
          },
          uncertainty: {
            nl: 'Onzekerheid rondom voorspelling',
            en: 'Uncertainty regarding predictions'
          },
          uncertaintyTooltip: {
            text: {
              nl: 'Voor 50% van de patiënten, met de door u ingevulde gegevens, verwachten we een cognitieve achteruitgang binnen dit gebied.',
              en: 'For 50% of patients, with the data you have entered, we expect cognitive decline within this area.'
            }
          },
          treatment: {
            nl: 'Achteruitgang met behandeling',
            en: 'Treatment effect'
          }
        },
        description: {
          nl: 'Deze lijngrafiek toont u de voorspelde gemiddelde achteruitgang in MMSE scores over een periode van 5 jaar voor een patiënt met de door u ingevoerde gegevens. Het gebied rondom de lijn geeft de onzekerheid in deze voorspelling weer.',
          en: 'This line chart shows you the expected average decline in MMSE scores over a period of 5 years for a patient with the data you entered. The area around the line represents the uncertainty of this prognosis.'
        },
        treatmentPathDescription: {
          nl: 'De lichtblauwe stippellijn geeft de gemiddelde cognitieve achteruitgang weer mét behandeling, met een door u gekozen remmende effect van {PERCENTAGE}%.',
          en: 'The light blue path shows how much longer the patient will take on average to reach this MMSE endpoint using treatment with the entered modifying effect of {PERCENTAGE}%.',
        }
      },
      stackedBarGraph: {
        title: {
          nl: 'Tijd tot MMSE eindpunt',
          en: 'Time to MMSE endpoint',
        },
        xLabel: {
          nl: 'Tijd in jaren',
          en: 'Time in years'
        },
        legend: {
          withoutTreatment: {
            nl: 'Tijd zonder behandeling',
            en: 'Time without treatment'
          },
          withTreatment: {
            nl: 'Tijd met behandeling',
            en: 'Time with treatment'
          }
        },
        description: {
          nl: 'In deze grafiek ziet u hoelang het gemiddeld duurt voordat een patiënt met de door u ingevoerde gegevens een MMSE score van {MMSESCORE} bereikt.',
          en: 'In this line chart, you can see how long it takes on average for a patient with the data you entered to reach an MMSE score of {MMSESCORE}.'
        },
        treatmentPathDescription: {
          nl: 'Het lichtblauwe blok geeft aan hoeveel langer de patiënt er gemiddeld over zou doen om dit MMSE eindpunt te bereiken mét behandeling, met het door u gekozen remmende effect van {PERCENTAGE}%.',
          en: 'The light blue block shows how much longer the patient will take on average to reach this MMSE endpoint using treatment with an modifying effect of {PERCENTAGE}% chosen by you.',
        }
      }
    },
    animatedGraph: {
      xLabel: {
        nl: 'Tijd in jaren',
        en: 'Time in years'
      },
      yLabel: {
        nl: 'Uw huidige denkvermogen',
        en: 'Your current thinking ability'
      },
      tooltips: {
        step1: {
          nl: 'Uw huidige MMSE score',
          en: 'Your current MMSE score'
        },
        step2: {
          nl: 'Uw verwachte achteruitgang in 5 jaar',
          en: 'Your expected decline in 5 years'
        },
        step3: {
          nl: 'Het kan meevallen',
          en: 'This might be better than expected'
        },
        step3_2: {
          nl: 'of tegenvallen',
          en: 'or worse'
        },
        step4: {
          nl: 'We gaan voor nu uit van de gemiddelde verwachte situatie',
          en: 'For now, we start with the average situation as expected'
        },
        step5: {
          nl: 'Behandeling kan de achteruitgang remmen',
          en: 'Treatments may slow decline of thinking ability'
        },
        step6: {
          nl: 'Waardoor u minder snel achteruit kán gaan',
          en: 'As a result, this may slow disease progression'
        },
        step7: {
          nl: 'We weten niet precies hoe het bij u zal gaan',
          en: 'We don’t know exactly how it will be in your case'
        }
      }
    },
    results: {
      situation: {
        title: {
          type: contentType.HEADING,
          h: 2,
          text: {
            nl: 'Uw situatie',
            en: 'Your situation'
          }
        },
        content: [
          {
            type: contentType.PARAGRAPH,
            text: {
              nl: 'Bij u is er sprake van milde dementie door de ziekte van Alzheimer.',
              en: 'You have been diagnosed with mild dementia caused by Alzheimer\'s disease.'
            }
          },
          {
            type: contentType.PARAGRAPH,
            text: {
              nl: 'Bij u is er sprake van Milde cognitieve stoornissen (MCI) door de ziekte van Alzheimer.',
              en: 'You have been diagnosed with mild cognitive impairment (MCI) caused by Alzheimer\'s disease.'
            }
          },
        ],
        tabs: [
          {
            id: 'line-graph',
            title: {
              nl: 'Lijn grafiek',
              en: 'Line graph',
            },
          },
          {
            id: 'time-to-time-graph',
            title: {
              nl: 'Tijd-tot grafiek',
              en: 'Time-to graph',
            },
          },
        ],
      },
      score: {
        title: {
          type: contentType.HEADING,
          h: 2,
          text: {
            nl: 'MMSE score',
            en: 'MMSE score'
          }
        },
        content: [
          {
            type: contentType.PARAGRAPH,
            text: {
              nl: 'We hebben uw denkvermogen getest met de MMSE test. Op de MMSE test kun je een score krijgen tussen 0 en 30. Hoe hoger uw score, hoe beter het denken nog gaat. Uw MMSE score is: {SCORE}',
              en: 'We tested your thinking ability using the MMSE test. The MMSE test gives a score between 0 and 30. The higher the score, the better your thinking ability. Your MMSE score is: {SCORE}'
            }
          },
        ]
      },
      progression: {
        title: {
          type: contentType.HEADING,
          h: 2,
          text: {
            nl: 'Ziekteverloop',
            en: 'Disease progression'
          }
        },
        content: [
          {
            type: contentType.PARAGRAPH,
            text: {
              nl: 'Om uw ziekteverloop te voorspellen, hebben we gegevens gebruikt van andere {GENDER} zoals u, met {MODEL} door de ziekte van Alzheimer.',
              en: 'In order to predict how the disease will progress in your case, we used data from other {GENDER} like you, also with {MODEL} caused by Alzheimer\'s.',
            }
          },
          {
            type: contentType.PARAGRAPH,
            text: {
              nl: 'De grafiek geeft een schatting aan van hoe uw denkvermogen achteruit zal gaan. We weten niet precies hoe het bij u zal gaan.',
              en: 'The chart gives an approximation of how your thinking ability will decline. We don\'t know exactly how it will be in your case.',
            }
          },
        ]
      },
      treatmentEffect: {
        title: {
          type: contentType.HEADING,
          h: 2,
          text: {
            nl: 'Behandeleffect',
            en: 'Effect of treatment'
          }
        },
        content: [
          {
            type: contentType.PARAGRAPH,
            text: {
              nl: 'De grafiek laat ook het behandeleffect zien. We verwachten dat de achteruitgang in het denkvermogen gemiddeld met {PERCENTAGE}% wordt geremd. Dit betekent dat we verwachten dat u {PERCENTAGE}% minder snel achteruit zal gaan door de behandeling. Het denkvermogen wordt niet beter.',
              en: 'The chart also shows the effect of treatment. We expect that decline in thinking ability will slow down on average by {PERCENTAGE}%. This means that we expect you will decline {PERCENTAGE}% less quickly because of the treatment. Your thinking ability will not improve.'
            }
          },
          {
            type: contentType.PARAGRAPH,
            text: {
              nl: 'Ook het behandeleffect is een schatting. We weten niet precies hoe het bij u zal gaan.',
              en: 'The effect of treatment is also an approximation. We don\'t know exactly how it will be in your case.'
            }
          }
        ]
      },
      animatedGraph: {
        title: {
          nl: 'Uitleg bij lijngrafiek',
          en: 'Explainer line chart'
        }
      },
      predictionGraphs: {
        title: {
          nl: 'Verwachte achteruitgang in uw denkvermogen',
          en: 'Expected decline of your thinking ability'
        },
        lineGraph: {
          description: {
            nl: 'Hier ziet u de gemiddelde achteruitgang in denkvermogen (MMSE scores) over een periode van 5 jaar, voor iemand zoals u. Het gekleurde gebied rondom de donkerblauwe lijn geeft aan dat we niet precies weten hoe de achteruitgang bij u zal gaan: het kan meevallen en het kan tegenvallen.',
            en: 'Here you can see the average decline of thinking ability over a period of 5 years for someone like you. The coloured area around the dark blue line shows that we don\'t know exactly how it will be in your case: it might be better than expected, or worse.'
          },
          treatmentPathDescription: {
            nl: 'De lichtblauwe stippellijn geeft aan hoe we verwachten dat iemand zoals u achteruitgaat in denkvermogen met behandeling, bijvoorbeeld medicatie. We verwachten dat u iets minder snel achteruit zal gaan.',
            en: 'The light blue dotted line shows how someone like you declines in thinking ability with treatment, such as medication. We expect that you will decline less quickly.'
          }
        },
        stackedBarGraph: {
          description: {
            nl: 'Een andere manier om zicht te krijgen op het ziekteverloop, is om de tijd te berekenen tot een vastgesteld denkniveau, zoals een bepaalde MMSE score. Deze grafiek laat dit zien. In de donkerblauwe balk ziet u hoelang het gemiddeld duurt voor iemand zoals u om een MMSE score van {SCORE} te bereiken. We weten niet precies hoe dit bij u zal gaan.',
            en: 'Another way to understand the progression of the disease is to calculate the time it takes to reach a predetermined cognitive level, such as a particular MMSE score. This is what this chart represents. The dark blue bar shows you how long it takes on average for someone like you to reach the MMSE endpoint of {SCORE}.'
          },
          treatmentPathDescription: {
            nl: 'De lichtblauwe balk geeft aan hoeveel langer iemand zoals u er gemiddeld over doet om een MMSE score van {SCORE} te bereiken met behandeling, zoals medicatie, die de achteruitgang met {PERCENTAGE}% vertraagd.',
            en: 'The light blue plane shows how long it takes for someone like you to reach a MMSE score of {SCORE} with treatment, such as medication, which slows the decline with {PERCENTAGE}%.'
          }
        }
      },
      buttons: {
        resultButton: {
          text: {
            nl: 'Naar uitslagpagina patiënt',
            en: 'To patient result page',
            se: 'Till resultatsida patient',
            de: 'Zur Ergebnisseite Patient'
          },
        },
        print: {
          nl: 'Deze pagina printen',
          en: 'Print this page',
        },
        reset: {
          nl: 'Opnieuw beginnen',
          en: 'Start over'
        },
      },
      genders: {
        male: {
          nl: 'mannen',
          en: 'men'
        },
        female: {
          nl: 'vrouwen',
          en: 'women'
        },
      },
      models: {
        dementia: {
          nl: 'milde dementie',
          en: 'mild dementia'
        },
        mci: {
          nl: 'MCI',
          en: 'MCI'
        }
      }
    },
    tag: {
      nl: 'Rekentool Ziekteverloop en behandeleffect',
      en: '\'Disease progression and treatment effect\' calculation tool',
    },
    content: [
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: 'Met deze tool berekent u de verwachte cognitieve achteruitgang van uw patiënt met MCI of milde dementie door de ziekte van Alzheimer.',
          en: 'With this tool you can calculate the expected cognitive decline of your patient with MCI or mild dementia caused by Alzheimer\'s disease.',
        },
      },
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: 'Twee verschillende visuele weergaven maken de persoonlijke schatting van het ziekteverloop inzichtelijk. De voorspelling wordt gemaakt op basis van leeftijd, geslacht, syndroom diagnose en eiwitmarkers in het hersenvocht (ruggenprik). Een lijngrafiek toont u de verwachte achteruitgang in cognitie (MMSE) over een periode van 5 jaar. Ook kunt u de tijd berekenen tot een door u gekozen MMSE eindpunt (Tijd-tot grafiek).',
          en: 'Two different visualisations show the estimated disease progression and can help explain it to your patient. The prediction is based on age, sex, MMSE, syndrome diagnosis and the presence of disease in the brain (lumbar puncture/CSF). A line chart shows you the expected cognitive decline (MMSE) over a period of 5 years. You can also calculate the time to your own chosen MMSE endpoint (Time-to chart).',
        },
      },
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: 'In beide visuele weergaven plot u vervolgens eenvoudig het mogelijke effect van een behandeling die het ziekteverloop zou vertragen (zoals ziekte-remmende medicatie). In de tool wordt ook een uitlegpagina gecreëerd, die gebruikt kan worden ter ondersteuning van het gesprek met de patiënt.',
          en: 'For both visualisations you can then easily plot the potential effect of a treatment that aims to slow disease progression (e.g. disease-modifying drugs). The tool will also create an explanation page, which can be used to support your discussions with the patient.',
        },
      },
    ],
    form: {
      validationTitle: {
        nl: 'Niet alle velden zijn (correct) ingevuld. Controleer een van de volgende velden:',
        en: 'Not all fields have been entered (correctly). Please check one of the following fields:'
      },
      sex: {
        legend: {
          nl: 'Mijn patiënt is een',
          en: 'My patient is',
        },
        label: {
          nl: 'Geslacht',
          en: 'Sex'
        },
        male: {
          nl: 'Man',
          en: 'Male',
        },
        female: {
          nl: 'Vrouw',
          en: 'Female',
        },
      },
      age: {
        label: {
          nl: 'Leeftijd',
          en: 'Age',
        },
      },
      model: {
        label: {
          nl: 'Syndroomdiagnose',
          en: 'Syndrome Diagnosis',
        },
        placeholder: {
          nl: 'Selecteer',
          en: 'Select',
        },
        options: {
          mci: {
            en: 'Mild cognitive impairment',
            nl: 'Milde cognitieve stoornissen',
          },
          ad: {
            en: 'Dementia',
            nl: 'Dementie',
          },
        }
      },
      mmseAtBaseline: {
        label: {
          nl: 'Huidige MMSE score',
          en: 'Current MMSE score',
        },
      },
      csfPtau: {
        label: {
          nl: 'Elecsys CSF pTAU, ng/ml',
          en: 'Elecsys CSF pTAU, ng/ml ',
        }
      },
      csfAb42: {
        label: {
          nl: 'Elecsys CSF AB1-42, ng/ml',
          en: 'Elecsys CSF AB1-42, ng/ml',
        }
      },
      apoeDose: {
        label: {
          nl: 'Aantal APOE ε4 allelen',
          en: 'Number of APOE ε4 alleles'
        }
      },
      prediction: {
        nl: 'Voorspelling ziekteverloop',
        en: 'Prediction disease progression'
      },
      predictionTooltip: {
        text: {
          nl: 'Deze rekentool resulteert in twee verschillende visuele weergaven van het voorspelde ziekteverloop van uw patiënt. De lijngrafiek toont u de verwachte achteruitgang in cognitie (MMSE) over een periode van 5 jaar na diagnose. Ook kunt u de tijd berekenen tot een door u gekozen MMSE eindpunt (Tijd-tot-grafiek). In beide visuele weergaven plot u eenvoudig het mogelijke effect van behandeling (zoals ziekte-remmende medicatie). Dit behandeleffect staat standaard op 30% remmende werking, maar u kunt dit percentage wijzigen.',
          en: 'With this tool you can calculate the expected cognitive decline in people with MCI or mild dementia caused by Alzheimer\'s disease. The line chart shows you the expected decline in cognitive ability (MMSE) over a period of 5 years. You can also calculate the time to your own chosen MMSE endpoint (Time-to chart). For both visualisations you can then easily plot the potential effect of a treatment that aims to slow disease progression (e.g. disease-modifying treatment).'
        }
      },
      medicineEffect: {
        label: {
          nl: 'Behandeleffect',
          en: 'Treatment effect'
        },
        tooltip: {
          text: {
            nl: 'Dit percentage staat voor het verwachte (remmende) effect van een behandeling. Een behandeleffect van 30% betekent dat de cognitieve achteruitgang 30% minder snel zal gaan. U kunt dit percentage aanpassen.',
            en: 'The percentage represents the predicted treatment effect. A treatment effect of 30% means that the cognitive decline slows down by 30%. This percentage can be changed.'
          }
        }
      },
      mmseOfInterest: {
        label: {
          nl: 'MMSE eindpunt',
          en: 'MMSE end point',
        }
      },
    },
    pageNavigation: {
      buttonText: {
        nl: 'Menu',
        en: 'Menu',
        se: 'Meny',
        de: 'Menü'
      },
      buttonToOtherTool: {
        title: {
          nl: 'Ga naar het tools overzicht',
          en: 'Go to tools overview',
        },
        link: 'tools',
      },
      buttonToExplanationPage: {
        nl: 'Naar uitlegpagina',
        en: 'Go to Explanation page'
      },
      requestAccess: {
        nl: 'Vraag toegang aan voordat je verder kunt gaan',
        en: 'Request access before you can proceed',
        se: '',
        de: ''
      },
      signup: {
        nl: 'Meld aan',
        en: 'Sign up',
      },
      pages: [
        {
          title: {
            nl: 'Over de tool',
            en: 'About the tool',
          },
          section: 'welkom',
        },
        {
          title: {
            nl: 'Gegevens invoeren',
            en: 'Entering patient data',
          },
          section: 'basis',
        },
        {
          title: {
            nl: 'Uitlegpagina',
            en: 'Explanation page',
          },
          section: 'resultaat',
        }
      ],
    },
    tabs: [],
    ...globalElements,
    ...requestAccessElements,
  },
  predictie_tool: {
    tag: {
      nl: 'Rekentool Dementierisico bij MCI',
      en: '\'Dementia risk with MCI\' calculation tool',
      se: '',
      de: ''
    },
    content: [
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: 'Met behulp van de rekentool \'Dementierisico bij MCI\' berekent u wat de testuitslagen betekenen voor het dementierisico van een individuele patiënt met milde cognitieve stoornissen (MCI).',
          en: 'With the aid of the calculation tool \'Dementia risk with MCI\' you can calculate what the test results mean in terms of the risk of dementia for an individual MCI patient.',
          se: '',
          de: ''
        },
      },
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: 'Deze tool geeft een schatting van de kans dat een MCI patiënt die zich op de geheugenpoli presenteert dementie ontwikkelt binnen 1, 3 en 5 jaar.',
          en: 'The risk calculation tool can be used to estimate the risk that a patient who presents at the memory clinic with mild cognitive impairment (MCI) will develop dementia within one, three and five years.',
          se: '',
          de: ''
        },
      },
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: 'Deze schatting wordt gemaakt op basis van leeftijd, geslacht en MMSE-score (de kans zonder aanvullende diagnostische tests). Met gegevens over de MRI en eiwitmarkers in hersenvocht en/of amyloid PET volgt een aangepaste schatting van de kans op dementie (de kans met biomarkers).',
          en: 'The estimate is based on age, sex and MMSE score (risk as estimated without data from additional diagnostic tests). By additionally entering MRI findings, CSF biomarker data, and/or amyloid PET, an adjusted estimate of the individual\'s dementia risk can be obtained.',
          se: '',
          de: ''
        },
      },
      {
        type: contentType.PARAGRAPH,
        text: {
          nl: 'Binnen de tool wordt ook een uitslagpagina voor de patiënt gecreëerd.',
          en: 'Within the tool, a result page is also created for the patient.',
          se: '',
          de: ''
        },
      },
    ],
    ...tool,
    ...globalElements,
    ...requestAccessElements
  },
  tools: {
    ...globalElements,
    ...requestAccessElements,
    tabs: [
      {
        id: 'medicatie_tool',
        title: {
          nl: 'Medicatie tool',
          en: 'Medicatie tool',
        },
        link: 'medicatie_tool',
      },
      {
        id: 'tool',
        title: {
          nl: 'Predictie tool',
          en: 'Prediction tool',
          se: 'Prognosverktyg',
          de: 'Risikovorhersage-Tool'
        },
        link: 'predictie_tool',
      },
    ],
    content: {
      title: {
        nl: 'Welkom op ADappt',
        en: 'Welcome to ADappt'
      },
      introduction: {
        nl: 'Voor artsen en andere zorgverleners werkzaam op een geheugenpoli ontwikkelden we twee rekentools, beiden met een patiënt uitlegpagina.',
        en: 'For doctors and other healthcare providers who work at a memory clinic we have developed two calculation tools, both with a patient explanation page.'
      },
    },
    pageNavigation: {
      buttonText: {
        nl: 'Menu',
        en: 'Menu',
        se: 'Meny',
        de: 'Menü'
      },
      pages: [{
        title: {
          nl: 'Dementierisico bij MCI',
          en: 'Dementia risk with MCI',
          se: '',
          de: ''
        },
        link: '../predictie_tool',
      },
      {
        title: {
          nl: 'Ziekteverloop en behandeleffect',
          en: 'Disease progression and treatment',
          se: '',
          de: ''
        },
        link: '../medicatie_tool',
      }]
    },
  },
  gegevens: {
    title: {
      nl: 'Basisgegevens',
      en: 'Basic information',
      se: 'Bakgrundsuppgifter',
      de: 'Stammdaten'
    },
  }
}

function getGlobalElements() {
  return {
    team: {
      title: {
        nl: 'Team',
        en: 'Team',
        se: '',
        de: ''
      },
      contactTitle: {
        nl: 'Contactpersoon',
        en: 'Contact',
        se: '',
        de: ''
      },
      team: [
        {
          name: {
            nl: 'Prof. Wiesje van der Flier (PhD)',
            en: 'Prof. Wiesje van der Flier (PhD)',
            se: '',
            de: ''
          },
          profession: {
            nl: 'Directeur onderzoek Alzheimercentrum Amsterdam, Amsterdam UMC',
            en: 'Research director Alzheimercentrum Amsterdam, Amsterdam UMC',
            se: '',
            de: ''
          },
          src: wiesje
        },
        {
          name: {
            nl: 'Leonie Visser (PhD)',
            en: 'Leonie Visser (PhD)',
            se: '',
            de: ''
          },
          profession: {
            nl: 'Senior onderzoeker medische communicatie, Amsterdam UMC',
            en: 'Senior researcer medical communication, Amsterdam UMC',
            se: '',
            de: ''
          },
          src: leonie
        },
        {
          name: {
            nl: 'Heleen Hendriksen (MSc)',
            en: 'Heleen Hendriksen (MSc)',
            se: '',
            de: ''
          },
          profession: {
            nl: 'Project- en implementatiemanager, Amsterdam UMC,',
            en: 'Project- en implementationmanager, Amsterdam UMC,',
            se: '',
            de: ''
          },
          src: heleen
        },
        {
          name: {
            nl: 'Pieter van der Veere (MSc)',
            en: 'Pieter van der Veere (MSc)',
            se: '',
            de: ''
          },
          profession: {
            nl: 'PhD student modellering, Amsterdam UMC',
            en: 'PhD student modelling, Amsterdam UMC',
            se: '',
            de: ''
          },
          src: pieter
        },
      ]
    },
    footer: {
      text: {
        nl: 'ADappt is een initiatief van ',
        en: 'ADappt is an initiative of ',
        se: 'ADappt är ett initiativ av ',
        de: 'ADappt ist eine Initiative von'
      },
      logos: [
        aboardLogo,
        alzheimerCentrumLogo
      ],
    },
    headerMenu: getHeaderMenu(),
    notFound: {
      content: {
        nl: 'Deze pagina is helaas niet gevonden.',
        en: 'Unfortunately, this page was not found.',
        se: 'Sidan hittades inte.',
        de: 'Diese Seite existiert leider nicht.'
      }
    },
    privacyStatement: {
      buttonText: {
        nl: 'Privacy Statement',
        en: 'Privacy Statement',
        se: 'Integritetspolicy',
        de: 'Datenschutzerklärung'
      },
      statement: {
        nl: (
          <>
            <strong>Doel formulieren 'Contact' en 'Toegang aanvragen':</strong>
            <p>
              ADappt is ontwikkeld op basis van resultaten uit de wetenschappelijke onderzoeksprojecten ABIDE en ABOARD binnen Amsterdam UMC.
            </p>
            <br />
            <p>Het doel van de formulieren 'Contact' en 'Toegang aanvragen' is tweeledig:</p>
            <br />
            <ol>
              <li>Professionals, patiënten, naasten en andere belangstellenden kunnen vragen stellen aan het ADappt team (formulier Contact).</li>
              <li>Professionals kunnen via het formulier inloggegevens aanvragen om de rekentools in te zien (Formulier Toegang aanvragen).</li>
            </ol>
            <strong>Beheer formulier</strong>
            <p>
              Persoonsgegevens ingevuld op de formulieren 'Contact' en'Toegang aanvragen' worden beheerd door medewerkers van Alzheimercentrum Amsterdam (Amsterdam UMC).
            </p>
            <br />
            <strong>Persoonsgegevens, bewerking & doel</strong>
            <p>
              Persoonsgegevens (titel, naam, positie, organisatie en e-mailadres) ingevuld in het formulier worden verzameld in een beveiligd bestand (met een wachtwoord) en bewaard in een beveiligde omgeving (digitale data opslag Alzheimercentrum Amsterdam). Persoonsgegevens worden verzameld en bewaard om antwoorden op gestelde vragen, persoonlijke inloggegevens en eventuele updates betreffende die inloggegevens te e-mailen. Persoonsgegevens worden bewaard tot ADappt offline gaat, of tot een nieuwe versie van ADappt de huidige versie zal vervangen. Indien u uw persoonsgegevens wilt inzien, wijzigen of verwijderen, kunt u een e-mail sturen aan: ADappt@vumc.nl
            </p>
          </>
        ),
        en: (
          <>
            <strong>Purpose of the forms 'Contact' and 'Request access'</strong>
            <p>
              The ADappt website, including the risk calculation tool, was developed in the Netherlands, based on results of the ABIDE and ABOARD research projects.
            </p>
            <br />
            <p>The purpose of our forms 'Contact' and 'Request access' is two-fold:</p>
            <br />
            <ol>
              <li>Healthcare professionals, patients, loved ones and other interested parties can ask questions about the ADappt website and/or the calculation tools by reaching out to us using the contact form.</li>
              <li>Health care professionals can request login credentials to view the calculation tools.</li>
            </ol>
            <br />
            <strong>Information Collection, Storage, Access And Use</strong>
            <p>
              Members of the ABOARD research team from Alzheimer center Amsterdam (Amsterdam UMC) have access to, and will handle, these data.
              <br /><br />
              The information requested in the forms (title, name, position, affiliation, e-mail address) are collected and stored in a password protected file, on a secure digital environment (digital storage Alzheimer center Amsterdam, Amsterdam UMC, Amsterdam, The Netherlands). These data will be used and stored to be able to send (via e-mail) answers to questions asked, login credentials and potential version updates regarding the ADappt website. Personal information will be stored until the current website goes offline, or when a new version of ADappt will replace the current version. If you would like to view, update or request the removal of personal data collected, please send an e-mail to ADappt@vumc.nl.
            </p>
          </>
        ),
        se: (
          <>
            <strong>Syftet med formulären ”Kontakt” och ”Begär åtkomst”.</strong>
            <p>
              ADappt har utvecklats utifrån resultat från den kliniska vetenskapliga studien ABIDE hos Alzheimercentrum Amsterdam i VUMC och avdelningen Medicinsk psykologi på AMC i Amsterdam,
              Nederländerna (VUMC och AMC bildar nu tillsammans Amsterdam UMC).
            </p>
            <p>
              ADappt-prognosverktyget ger en uppskattning av sannolikheten för att en patient som kommer till minnesmottagningen med en mild kognitiv svikt (MCI) utvecklar demens inom 1, 3 och 5 år.
            </p>
            <strong>Det finns två syften med formulären ”Kontakt” och ”Begär åtkomst”:</strong>
            <ol>
              <li>Vårdpersonal och andra intresserade kan ställa frågor till ABIDE-teamet på Alzheimercentrum Amsterdam.</li>
              <li>Vårdpersonal kan via formuläret begära inloggningsuppgifter för att titta på prognosverktyget.</li>
            </ol>
            <strong>Behandling av formulär</strong>
            <p>Personuppgifter som anges i formulären ”Kontakt” och ”Begär åtkomst” behandlas av medarbetare på Alzheimercentrum Amsterdam (Amsterdam UMC), i synnerhet medarbetare i ABIDE-projektteamet.</p>
            <strong>Personuppgifter, behandling och ändamål</strong>
            <p>
              Personuppgifter (titel, namn, befattning, organisation och e-postadress) som anges i formuläret samlas in i en skyddad fil (med lösenord) och sparas i en skyddad miljö (digital datalagring
              på Alzheimercentrum Amsterdam). Personuppgifter samlas in och sparas för att kunna skicka svar på ställda frågor, personliga inloggningsuppgifter och eventuella uppdateringar av
              inloggningsuppgifterna via e-post. Personuppgifter sparas tills ADappt går offline, eller tills en ny version av ADappt ersätter den befintliga versionen. Om du vill se, ändra eller ta bort
              dina personuppgifter kan du skicka ett meddelande till: ADappt@vumc.nl
            </p>
          </>
        ),
        de: (
          <>
            <strong>Zweck der Formulare "Kontakt" und "Zugang beantragen":</strong>
            <p>
              Grundlage für die Entwicklung von ADappt waren Ergebnisse aus dem wissenschaftlichen Forschungsprojekt ABIDE des von der Universitätsklinik VU Medisch Centrum (VUMC) geleiteten Alzheimerzentrums
              Amsterdam sowie der Abteilung für Medizinische Psychologie der Universitätsklinik Academisch Medisch Centrum (AMC) in Amsterdam (aus VUMC und AMC ist jetzt das Amsterdam UMC hervorgegangen).
            </p>
            <p>
              Das ADappt Risikovorhersage-Tool berechnet die Wahrscheinlichkeit, mit der sich innerhalb eines Zeitraums von 1, 3 und 5 Jahren bei einem Patienten mit einer leichten kognitiven Störungen (MCI)
              eine Demenz entwickelt.
            </p>
            <strong>Die Formulare "Kontakt" und "Zugang beantragen" dienen zweierlei Zwecken:</strong>
            <ol>
              <li>Medizinische Fachkräfte und andere interessierte Personen können Fragen an das ABIDE-Team des Alzheimerzentrums Amsterdam stellen.</li>
              <li>Medizinische Fachkräfte können über das Formular Anmeldedaten anfordern, um sich das Risikovorhersage-Tool anzusehen.</li>
            </ol>
            <strong>Verwaltung der Formulare</strong>
            <p>
              Die Verwaltung personenbezogener Daten, die in den Formularen "Kontakt" und "Zugang beantragen" ausgefüllt werden, erfolgt durch Mitarbeiter des Alzheimerzentrums Amsterdam (Amsterdam UMC),
              insbesondere durch Mitarbeiter des ABIDE-Projektteams.
            </p>
            <strong>Personenbezogene Daten, Bearbeitung und Zweck</strong>
            <p>
              Die in dem Formular eingetragenen personenbezogenen Daten (Titel, Name, Stellung, Organisation und E-Mail-Adresse) werden in einer (mit einem Passwort) gesicherten Datei zusammengefasst und in
              einer geschützten Umgebung gespeichert (digitaler Datenspeicher Alzheimerzentrum Amsterdam). Personenbezogene Daten werden erfasst und gespeichert, um Antworten auf gestellte Fragen, persönliche
              Anmeldedaten und eventuelle Updates in Bezug auf diese Anmeldedaten per E-Mail zu verschicken. Personenbezogene Daten bleiben gespeichert, bis ADappt offline geht oder bis die heutige Version
              von ADappt durch eine neue Version ersetzt wird. Wenn Sie Ihre personenbezogenen Daten einsehen, ändern oder löschen wollen, können Sie eine entsprechende E-Mail-Nachricht an die folgende
              Adresse schicken: ADappt@vumc.nl
            </p>
          </>
        ),
      }
    }
  }
}

function getHeaderMenu() {
  return {
    tabs: [
      {
        id: 'informatie',
        title: {
          nl: 'Informatie',
          en: 'Information',
          se: 'Information',
          de: 'Informationen'
        },
        IconComponent: <Icon icon={infoIcon} />,
        link: 'informatie',
      },
      {
        id: 'tools',
        title: {
          nl: 'Rekentools',
          en: 'Calculation tools',
          se: 'Prognosverktyg',
          de: 'Risikovorhersage-Tool'
        },
        IconComponent: <Icon icon={chartIcon} />,
        link: 'tools',
        subPages: ['predictie_tool', 'medicatie_tool']
      },
    ],
  }
}
