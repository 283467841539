import { useI18n } from '/machinery/useI18n'

import { TabMenuSecondary } from '/features/buildingBlocks/TabMenu'
import { HumanIcons, BarChart } from '/features/buildingBlocks/VisualizationItem'

import styles from './SituationVisualization.css'

export function SituationVisualization({ gender, currentPage, amount1Year, amount3Years, amount5Years }) {
  const i18n = useI18n()
  const { situationVisualization } = i18n(currentPage)

  const tabs = React.useMemo(() => i18n(currentPage).situationVisualization.tabs, [i18n, currentPage])
  const [selectedTab, setSelectedTab] = React.useState('icons')

  const allDisabled = ![amount1Year, amount3Years, amount5Years].some(Boolean)

  return (
    <section className={styles.component}>
      <p>{situationVisualization.intro(gender, !!amount5Years)}</p>

      <TabMenuSecondary
        onSelectedTabChange={x => setSelectedTab(x.id)}
        selectedTab={tabs.find(x => x.id === selectedTab)}
        {...{ tabs }}
      />

      <div className={styles.results}>
        {selectedTab === 'icons' &&
          <>
            {(allDisabled || amount1Year) &&
              <HumanIcons amountThatDevelopsAlzheimer={amount1Year} title={situationVisualization.visualization.year(1)} />
            }
            {(allDisabled || amount3Years) &&
              <HumanIcons amountThatDevelopsAlzheimer={amount3Years} title={situationVisualization.visualization.year(3)} />
            }
            {(allDisabled || amount5Years) &&
              <HumanIcons amountThatDevelopsAlzheimer={amount5Years} title={situationVisualization.visualization.year(5)} />
            }
          </>
        }
        {selectedTab === 'bars' &&
          <>
            {(allDisabled || amount1Year) &&
              <BarChart amountThatDevelopsAlzheimer={amount1Year} title={situationVisualization.visualization.year(1)} />
            }
            {(allDisabled || amount3Years) &&
              <BarChart amountThatDevelopsAlzheimer={amount3Years} title={situationVisualization.visualization.year(3)} />
            }
            {(allDisabled || amount5Years) &&
              <BarChart amountThatDevelopsAlzheimer={amount5Years} title={situationVisualization.visualization.year(5)} />
            }
          </>
        }
      </div>
    </section>
  )
}
