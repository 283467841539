import { Content } from '/features/buildingBlocks/Content'

import styles from './ProsAndCons.css'

export function ProsAndCons({ pros, cons }) {
  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <span className={cx(styles.title, styles.pros)}>{pros.title}</span>
        <div>
          <Content content={pros.content} />
        </div>
      </div>

      <div className={styles.content}>
        <span className={cx(styles.title, styles.cons)}>{cons.title}</span>
        <div>
          <Content content={cons.content} />
        </div>
      </div>
    </div>
  )
}
