import { Content } from '/features/buildingBlocks/Content'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Accordion.css'

import chevronIcon from '/images/icons/chevron.raw.svg'

export function Accordion({ items }) {
  return (
    <div className={styles.component}>
      {items.map((item, i) =>
        <AccordionItem key={i} {...{ item }} />
      )}
    </div>
  )
}

function AccordionItem({ item }) {
  const { title, content } = item
  const [open, setOpen] = React.useState(false)

  return (
    <details className={styles.componentItem} {...{ open }}>
      <summary className={styles.summary} onClick={handleClick}>
        {title}
        <div className={cx(styles.icon, open && styles.isOpen)}>
          <Icon layoutClassName={styles.iconLayout} icon={chevronIcon} />
        </div>
      </summary>

      <div className={styles.contentContainer}>
        <Content {...{ content }} />
      </div>
    </details>
  )

  function handleClick(e) {
    e.preventDefault()
    setOpen(!open)
  }
}
