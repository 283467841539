import styles from './TabMenu.css'

export function TabMenuPrimary({ tabs, selectedTab, onSelectedTabChange, layoutClassName = undefined }) {
  return <TabMenuBase tabComponent={TabPrimary} className={cx(styles.componentPrimary, layoutClassName)} {...{ tabs, selectedTab, onSelectedTabChange }} />
}

export function TabMenuSecondary({ tabs, selectedTab, onSelectedTabChange }) {
  return <TabMenuBase tabComponent={TabSecondary} className={styles.componentSecondary} {...{ tabs, selectedTab, onSelectedTabChange }} />
}

function TabMenuBase({ tabs, className, tabComponent: Tab, selectedTab, onSelectedTabChange }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      {tabs.map(item => (
        <Tab
          key={item.id}
          selected={selectedTab === item}
          onClick={() => onSelectedTabChange(item)}
          IconComponent={item.IconComponent}
          title={item.title}
        />
      ))}
    </div>
  )
}

function TabPrimary({ IconComponent, title, selected, onClick }) {
  return <TabBase className={styles.componentTabPrimary} {...{ IconComponent, title, selected, onClick }} />
}

function TabSecondary({ IconComponent, title, selected, onClick }) {
  return <TabBase className={styles.componentTabSecondary} {...{ IconComponent, title, selected, onClick }} />
}

function TabBase({ IconComponent, title, selected, onClick, className }) {
  return (
    <button type='button' className={cx(styles.componentTabBase, className, { [`${styles.selected}`]: selected })} {...{ onClick }}>
      {IconComponent && <span className={styles.icon}>{IconComponent}</span>}
      {title}
    </button>
  )
}
