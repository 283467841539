import { useI18n } from '/machinery/useI18n'

import { InfographicContainer } from '/features/pageOnly/predictionTool/InfographicContainer'
import { FieldGroup, FieldRadioGroup, FieldRadio, FieldNumber } from '/features/pageOnly/predictionTool/Field'
import { Tooltip } from '/features/buildingBlocks/Tooltip'

import styles from './Basisgegevens.css'

const currentPage = 'basisgegevens'

export function Basisgegevens({ value, onChange, fieldInfo, basisResults }) {
  const i18n = useI18n()
  const { form, tooltip, firstParagraph } = i18n(currentPage)
  const { gender, age, mmse } = value

  return (
    <section className={styles.component}>
      <div className={styles.patientInput}>
        <FieldRadioGroup label={form.gender.legend}>
          <FieldRadio
            name='gender'
            id='male'
            label={form.gender.male}
            onChange={x => onChange(y => ({ ...y, gender: x ? 'male' : y.gender }))}
            checked={gender === 'male'}
          />
          <FieldRadio
            name='gender'
            id='female'
            label={form.gender.female}
            onChange={x => onChange(y => ({ ...y, gender: x ? 'female' : y.gender }))}
            checked={gender === 'female'}
          />
        </FieldRadioGroup>

        <FieldGroup>
          <FieldNumber
            label={form.age.label}
            id='age'
            name='age'
            value={age}
            onChange={x => onChange({ ...value, age: x })}
            min={fieldInfo.age[0]}
            max={fieldInfo.age[1]}
          />
          <FieldNumber
            label={form.score.label}
            id='mmse'
            name='mmse'
            value={mmse}
            onChange={x => onChange({ ...value, mmse: x  })}
            min={fieldInfo.mmse[0]}
            max={fieldInfo.mmse[1]}
          />
        </FieldGroup>
      </div>

      <div className={styles.labelTooltipContainer}>
        {firstParagraph}
        <Tooltip tip={tooltip} buttonLink={`/${i18n.language}${tooltip.button.link}`} />
      </div>

      <InfographicContainer results={basisResults} />
    </section>
  )
}
