import { getPointAdjustedForZoom } from '/features/pageOnly/medicationTool/machinery/getPointAdjustedForZoom'

import styles from './GraphViewbox.css'

export function GraphViewbox({ width, height, viewBox, yZoom = 1, showGrid = false, children }) {
  return (
    <svg width='auto' height='auto' data-svg='true' {...{ viewBox }}>
      <g>
        <rect x={0} y={0} strokeWidth={0.06} data-svg-grid='true' className={styles.graphRectBox} {...{ width, height }} />
        {showGrid && <Grid {...{ width, height, yZoom }} />}
        {children}
      </g>
    </svg>
  )
}

function Grid({ width, height, yZoom }) {
  const zoomedInLinesCount = Math.floor(1 / (1 / yZoom)) - 1

  return (
    <>
      <rect x={0} y={0} strokeWidth={0.06} data-svg-grid='true' fill='url(#:grid:)' {...{ width, height }} />

      <defs>
        <pattern id=':smallGrid:' width={1} height={1} patternUnits='userSpaceOnUse'>
          <line x1={1} x2={1} y1={0} y2={1} strokeWidth={0.06} className={styles.gridStroke} />
          <line x1={0} x2={1} y1={1} y2={1} strokeWidth={0.06} className={styles.gridStroke} />

          {Array.from({ length: zoomedInLinesCount }).map((_, i) => {
            const y = getPointAdjustedForZoom(yZoom, i + 1)

            return <line key={i} x1={0} x2={1} y1={y} y2={y} strokeWidth={0.06} className={styles.gridStroke} />
          })}
        </pattern>

        <pattern id=':grid:' width={5} height={5} patternUnits='userSpaceOnUse'>
          <rect width={5} height={5} fill='url(#:smallGrid:)' />
          <line x1={5} x2={5} y1={0} y2={5} strokeWidth={0.125} className={styles.gridStroke} />
          <line x1={0} x2={5} y1={5} y2={5} strokeWidth={0.125} className={styles.gridStroke} />
        </pattern>
      </defs>
    </>
  )
}
