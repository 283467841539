import { Location } from '@reach/router'

export function ScrollToTopOnLocationChange() {
  return (
    <Location>{({ location }) => <Implementation {...{ location }} />}</Location>
  )
}

function Implementation({ location }) {
  React.useEffect(
    () => { window.scrollTo(0, 0) },
    [location.pathname]
  )

  return null
}
